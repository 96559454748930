import { type FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { compareNumbers } from 'utils'
import { useAppDispatch } from 'hooks'
import { ChevronRightIcon } from 'assets'
import { updateOrderInfo } from 'store/order/actions'
import type { TOrderUpdateForm } from 'store/order/types'
import type { TSelectDataItem } from 'components/atom/SelectOption/types'
import { getShippingFields, shipping_fields } from 'pages/Ordering/utils'
import { SHIPPING_METHOD_NOVAPOSHTA_INTERNATIONAL } from 'config/constant'
import { Button, Input, OrderedProduct, Select, SelectOption, Text } from 'components'

import type { TOrderDetailsStateProps } from './types'
import { renderShippingInfo } from './utils'
import styles from './OrderDetails.module.scss'

const EditOrder: FC<TOrderDetailsStateProps> = ({ item, setSidebarState }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const filteredShippingFields = shipping_fields.filter(
    element => element._id !== SHIPPING_METHOD_NOVAPOSHTA_INTERNATIONAL
  )

  const [inputShippingFields, setInputShippingFields] = useState<any[]>([])
  const [shippingCode, setShippingCode] = useState<TSelectDataItem>(filteredShippingFields[1])

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
    ...methods
  } = useForm({
    mode: 'onChange',
  })

  const city_ref = watch('city_id')

  const handleShippingFieldChange = (item: TSelectDataItem) => {
    setShippingCode(item)
  }

  const onEditProductClick = useCallback(() => {
    setSidebarState('productEdit')
  }, [setSidebarState])

  const onCancelClick = useCallback(() => {
    setSidebarState('detail')
  }, [setSidebarState])

  const renderInputFields = useMemo(
    () =>
      inputShippingFields?.map((field, indexField) => (
        <Fragment key={`inputColumn_${indexField}_${field.name}`}>
          {field.selectApi ? (
            <SelectOption
              search
              name={field.name}
              setValue={setValue}
              selectApi={field.selectApi}
              label={field.label}
              hint={renderShippingInfo(field.name, item) || ''}
              placeholder={field.placeholder}
              error={errors[field.name]?.message}
              itemToSet={field.selectApi === 'cities' ? 'ref' : '_id'}
              city_ref={field.selectApi === 'warehouse' ? city_ref || '' : ''}
              register={register(field.name)}
            />
          ) : (
            <Input
              register={register(field.name, field.rules)}
              placeholder={field.placeholder}
              error={errors[field.name]?.message}
              label={field.label || null}
            />
          )}

          {field.inputColumn &&
            field?.inputColumn?.map((input: any, index: number) => (
              <Input
                label={input.label}
                placeholder={input.placeholder}
                error={errors[input.name]?.message}
                register={register(input.name, input.rules)}
                key={`inputColumn_${index}_${input.name}_${field.name}`}
              />
            ))}
        </Fragment>
      )),
    [city_ref, errors, inputShippingFields, item, register, setValue]
  )

  const renderOrderedProducts = useMemo(
    () => item?.products.map(element => <OrderedProduct item={element} key={element.product_id} />),
    [item?.products]
  )

  const onSubmit: SubmitHandler<TOrderUpdateForm> = async data => {
    const params = {
      ...data,
      shipping_code: shippingCode._id,
      city_id: data.city_id || item?.novaposhta_city_ref || '',
      warehouse_id: data.warehouse_id || item?.novaposhta_warehouse_ref || '',
      shipping_street: data.shipping_street || item?.shipping_street || '',
    }

    if (!isSubmitting) {
      dispatch(updateOrderInfo({ id: Number(item?.order_id), data: params }))
    }
  }

  useEffect(() => {
    setInputShippingFields(getShippingFields(String(shippingCode?._id)))
  }, [shippingCode?._id])

  useEffect(() => {
    setValue('firstname', item?.first_name)
    setValue('lastname', item?.last_name)
    setValue('telephone', item?.telephone)
    setValue('comment', item?.comment)
    setValue('pre_pay', item?.pre_pay)
    setValue('shipping_house', item?.shipping_house)
    setValue('shipping_flat', item?.shipping_flat)
    setValue('total_pay', item?.total_pay?.toString())

    setShippingCode(
      filteredShippingFields.find(element => element._id === item?.shipping_code) ?? filteredShippingFields[0]
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, setValue])

  return (
    <div className={styles.edit}>
      <div className={styles.wrapper_container}>
        <div className={styles.edit_head}>
          <Text text='order_number_var' variable={{ var: item?.order_id }} className={styles.wrapper_text_bold} />

          <div className={styles.wrapper_item_container}>{renderOrderedProducts}</div>

          <Text
            role='button'
            text='edit_products'
            onClick={onEditProductClick}
            className={styles.edit_text}
            RightIcon={ChevronRightIcon}
          />
        </div>

        <div className={styles.edit_container}>
          <Text text='main_data' className={styles.wrapper_text_bold} />

          <Input
            label='first_name'
            placeholder='first_name'
            error={errors.firstname?.message}
            register={register('firstname', {
              required: 'first_name_required',
            })}
          />

          <Input
            label='last_name'
            placeholder='last_name'
            error={errors.lastname?.message}
            register={register('lastname', {
              required: 'last_name_required',
            })}
          />

          <Select
            methods={methods}
            label='phone_number'
            register={register('telephone', {
              required: 'telephone.required',
              validate: value => (value && !value.includes('_') ? true : 'telephone.format'),
            })}
            isDirty={!!dirtyFields.telephone}
            error={errors.telephone?.message}
          />

          <SelectOption
            name='shipping'
            data={filteredShippingFields}
            label='enter_delivery_method'
            onChange={handleShippingFieldChange}
            defaultValue={filteredShippingFields[0].name}
          />

          {renderInputFields}

          {item?.total_pay ? (
            <Input
              type='number'
              autoCapitalize='none'
              placeholder='total_pay.placeholder'
              label='total_pay.placeholder'
              error={errors['total_pay']?.message}
              register={register('total_pay', {
                required: 'total_pay.required',
                validate: (value: string) =>
                  compareNumbers(value, item?.total, t('total_pay.min', { total: item?.total_format })),
              })}
            />
          ) : null}

          {item?.payment_code === 'bank_transfer_manager_cod' && item?.pre_pay ? (
            <Input
              type='number'
              label='pre_pay.placeholder'
              placeholder='pre_pay.placeholder'
              register={register('pre_pay', {
                required: 'pre_pay.required',
              })}
              error={errors['pre_pay']?.message}
            />
          ) : null}

          <Input register={register('comment')} label='comment.placeholder' placeholder='comment.placeholder' />
        </div>
      </div>

      <div className={styles.wrapper_bottom}>
        <Button onClick={onCancelClick} disabled={isSubmitting} variant='secondary' size='small'>
          cancel
        </Button>

        <Button size='small' disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
          edit
        </Button>
      </div>
    </div>
  )
}

export default EditOrder
