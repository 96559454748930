import { CartOutlineIcon, CellphoneIcon, StarOutlineIcon, TruckDeliveryIcon } from 'assets'

import type { TAdvantageCardProps } from 'components/atom/AdvantageCard/types'

export const advantages: TAdvantageCardProps[] = [
  {
    id: 1,
    title: 'fast_delivery',
    subtitle: 'fast_delivery_description',
    Icon: TruckDeliveryIcon,
  },

  {
    id: 2,
    title: 'wide_assortment',
    subtitle: 'wide_assortment_description',
    Icon: CartOutlineIcon,
  },

  {
    id: 3,
    title: 'easy_shopping',
    subtitle: 'easy_shopping_description',
    Icon: CellphoneIcon,
  },

  {
    id: 4,
    title: 'high_quality',
    subtitle: 'high_quality_description',
    Icon: StarOutlineIcon,
  },
]
