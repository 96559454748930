import { createSlice } from '@reduxjs/toolkit'
import { confirmAuth, registrationUser, signIn, signIn_V2 } from './actions'

import type { TAuth } from './types'

const initialState: TAuth = {
  login: {
    phoneNumber: '',
    isFromConfirm: false,
    data: null,
    error: null,
    loading: false,
  },

  register: {
    data: null,
    error: null,
    loading: false,
  },

  confirm: {
    data: null,
    error: null,
    loading: false,
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    ...initialState,
  },
  reducers: {
    setIsFromConfirm: (state, action) => {
      state.login.isFromConfirm = action.payload
    },
    setConfirmNumber: (state, action) => {
      state.login.phoneNumber = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signIn.pending, state => {
        state.login.loading = true
        state.login.error = null
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.login.loading = false
        state.login.error = null
        state.login.data = action.payload
      })
      .addCase(signIn.rejected, (state, action) => {
        state.login.loading = false
        state.login.error = action.error
        state.login.data = null
      })

      //

      .addCase(signIn_V2.pending, state => {
        state.login.loading = true
        state.login.error = null
      })
      .addCase(signIn_V2.fulfilled, (state, action) => {
        state.login.loading = false
        state.login.error = null
        state.login.data = action.payload
      })
      .addCase(signIn_V2.rejected, (state, action) => {
        state.login.loading = false
        state.login.error = action.error
        state.login.data = null
      })

      //

      .addCase(registrationUser.pending, state => {
        state.register.loading = true
        state.register.error = null
      })
      .addCase(registrationUser.fulfilled, (state, action) => {
        state.register.loading = false
        state.register.data = action.payload

        if (action.payload.data?.token) {
          localStorage.setItem('userData', JSON.stringify(action.payload))
        }
      })
      .addCase(registrationUser.rejected, (state, action) => {
        state.register.loading = false
        state.register.error = action.error
      })

      //

      .addCase(confirmAuth.pending, state => {
        state.confirm.loading = true
        state.confirm.error = null
      })
      .addCase(confirmAuth.fulfilled, (state, action) => {
        state.confirm.loading = false
        state.confirm.data = action.payload
        state.confirm.error = null
      })
      .addCase(confirmAuth.rejected, (state, action) => {
        state.confirm.loading = false
        state.confirm.error = action.error
      })
  },
})

export const { setIsFromConfirm, setConfirmNumber } = authSlice.actions

const authSliceReducer = authSlice.reducer

export default authSliceReducer
