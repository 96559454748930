import { Fragment, type FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import { registerSchema } from 'utils'
import { useAppDispatch } from 'hooks'
import { setConfirmNumber } from 'store/auth'
import { toggleAuthModalOpen } from 'store/global'
import { ERoutePaths } from 'libraries/router/types'
import type { TRegisterForm } from 'store/auth/types'
import { registrationUser } from 'store/auth/actions'
import { Button, Input, Select, Text } from 'components'

import { EModalState, type TAuthComponentsProps } from './types'
import styles from './AuthModal.module.scss'

const Register: FC<TAuthComponentsProps> = ({ setModalState }) => {
  const dispatch = useAppDispatch()

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, dirtyFields },
    ...methods
  } = useForm<TRegisterForm>({
    resolver: yupResolver(registerSchema),
    mode: 'onChange',
  })

  const telephoneValue = watch('telephone')
  const isValidTelephoneValue = telephoneValue || !telephoneValue?.includes('_')

  const openLogin = () => {
    setModalState(EModalState.Login)
  }

  const closeSidebar = () => {
    dispatch(toggleAuthModalOpen(false))
  }

  const onSubmit: SubmitHandler<TRegisterForm> = async data => {
    const result = await dispatch(registrationUser(data))

    if (!result.payload?.data?.errors) {
      localStorage.setItem('tel', data.telephone)
      dispatch(setConfirmNumber(data.telephone))
      setModalState(EModalState.Confirm)
    }
  }

  return (
    <Fragment>
      <div className={styles.wrapper_container}>
        <Text className={styles.wrapper_title} text='login_to_account' />
        <Text className={styles.wrapper_subtitle} text='login_description' />
      </div>

      <form className={styles.wrapper_form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.wrapper_container}>
          <Input
            isDirty={!!dirtyFields.firstname}
            label='first_name'
            type='text'
            name='firstname'
            placeholder='first_name'
            register={register('firstname')}
            error={errors?.firstname?.message}
          />

          <Input
            isDirty={!!dirtyFields.lastname}
            label='last_name'
            type='text'
            name='lastname'
            register={register('lastname')}
            placeholder='last_name'
            error={errors?.lastname?.message}
          />

          <Select
            isDirty={!!dirtyFields.telephone}
            label='phone_number'
            methods={methods}
            name='telephone'
            register={register('telephone')}
            error={errors.telephone?.message}
          />

          <Input
            label='password'
            type='password'
            name='password'
            placeholder='password'
            hint='password_min_length'
            register={register('password')}
            isDirty={!!dirtyFields.password}
            error={errors?.password?.message}
          />
        </div>

        <div className={styles.wrapper_button_container}>
          <Button
            type='submit'
            isLoading={isSubmitting}
            className={styles.wrapper_button}
            disabled={!isValid || isSubmitting || !isValidTelephoneValue}
          >
            register
          </Button>

          <Link to={ERoutePaths.PrivacyPolicy} onClick={closeSidebar} className={styles.wrapper_agree_terms}>
            <Trans
              i18nKey='agree_with_terms'
              components={{
                1: <span className={styles.wrapper_policy} />,
                2: <span className={styles.wrapper_policy} />,
              }}
            />
          </Link>
        </div>
      </form>

      <div className={styles.wrapper_footer}>
        <Text className={styles.wrapper_footer_text} text='already_have_account' />
        <Text role='button' onClick={openLogin} className={styles.wrapper_footer_link} text='login' />
      </div>
    </Fragment>
  )
}

export default Register
