import { type FC, Fragment } from 'react'
import { Skeleton } from 'components/'

import type { TSkeleton } from '../types'
import styles from './CategoryItemSkeleton.module.scss'

const CategoryItemSkeleton: FC<TSkeleton> = ({ skeletonCount = 1 }) => (
  <Fragment>
    {Array.from({ length: skeletonCount }).map((_, index) => (
      <div key={index} className={styles.wrapper}>
        <div className={styles.wrapper_container}>
          <Skeleton width='100%' height='100%' />
        </div>

        <Skeleton width='100%' height='24px' />
      </div>
    ))}
  </Fragment>
)
export default CategoryItemSkeleton
