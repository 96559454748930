import { Fragment, type FC } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from 'hooks'
import { ChevronRightIcon } from 'assets'
import { fullNameFirstLetters } from 'utils'
import { ChangeLang, Text } from 'components'
import { toggleAuthModalOpen } from 'store/global'
import { getUserData, logout } from 'utils/shared'
import { ERoutePaths } from 'libraries/router/types'

import { cabinetRoutes } from './utils'
import styles from './Cabinet.module.scss'

const Cabinet: FC = () => {
  const user = getUserData()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const customerGroupIncludes = (CUSTOMER_GROUPS: any) => CUSTOMER_GROUPS?.includes(user?.customer_group_id ?? 0)

  const handleNavigation = (path: ERoutePaths) => {
    dispatch(toggleAuthModalOpen(false))

    setTimeout(() => {
      navigate(path)
    }, 300)
  }

  const renderItems = cabinetRoutes.map(({ id, text, Icon, line, nav, warning, group }: any) => (
    <Fragment key={id}>
      {customerGroupIncludes(group) || !group ? (
        <Fragment>
          {warning && (
            <Text
              text='warning_dangerous'
              className={classNames(styles.wrapper_item, { [styles.wrapper_item_warning]: warning })}
            />
          )}

          <Text
            text={text}
            role='button'
            LeftIcon={Icon}
            onClick={id === 12 ? logout : nav ? () => handleNavigation(nav) : undefined}
            className={classNames(styles.wrapper_item, { [styles.wrapper_item_last]: line })}
          />

          {line && <div className={styles.wrapper_divider} />}
        </Fragment>
      ) : null}
    </Fragment>
  ))

  return (
    <div className={styles.wrapper}>
      <div role='button' onClick={() => handleNavigation(ERoutePaths.Profile)} className={styles.wrapper_header}>
        <div className={styles.wrapper_header_container}>
          <div className={classNames(styles.wrapper_image_container, { [styles.wrapper_image_empty]: !user?.avatar })}>
            {user?.avatar ? (
              <img
                src={user?.avatar?.original_url}
                alt='avatar'
                width={56}
                height={56}
                className={styles.wrapper_image}
              />
            ) : (
              <Text className={styles.wrapper_image_empty_text} text={fullNameFirstLetters(String(user?.name))} />
            )}
          </div>

          <div className={styles.wrapper_header_title_container}>
            <p className={styles.wrapper_header_title}>{user?.name}</p>
            <p className={styles.wrapper_header_email}>{user?.email}</p>
          </div>
        </div>

        <ChevronRightIcon />
      </div>

      <div className={styles.wrapper_divider} />

      <ChangeLang />

      <div className={styles.wrapper_divider} />

      {renderItems}
    </div>
  )
}

export default Cabinet
