import * as yup from 'yup'

export const registerSchema = yup.object().shape({
  firstname: yup.string().required('first_name_required').min(2, 'first_name_min_length'),
  lastname: yup.string().required('last_name_required').min(2, 'last_name_min_length'),
  telephone: yup
    .string()
    .required('phone_required')
    .test('no-underscore', 'phone_incomplete', value => {
      return value ? !value.includes('_') : true
    }),
  password: yup.string().required('password_required').min(5, 'password_min_length'),
})
