import { useCallback, type FC } from 'react'

import { useResponsive } from 'hooks'
import { Button, SelectOption, Text } from 'components'
import { ChevronLeftIcon, ChevronRightIcon } from 'assets'

import type { TSelectDataItem } from '../SelectOption/types'
import type { TPaginationProps } from './types'
import styles from './Pagination.module.scss'

const Pagination: FC<TPaginationProps> = ({
  loading,
  lastPage,
  currentPage,
  onNextClick,
  onPageSelect,
  onPreviousClick,
}) => {
  const { isTabletSmall } = useResponsive()

  const createPageNumbers = (totalPages: number): TSelectDataItem[] => {
    return Array.from({ length: totalPages }, (_, index) => ({
      _id: index + 1,
      name: `${index + 1}`,
    }))
  }

  const renderPages = createPageNumbers(lastPage)

  const onSelectChange = useCallback(
    (item: TSelectDataItem) => {
      onPageSelect(item._id)
      window.scrollTo(0, 0)
    },
    [onPageSelect]
  )

  const onPageChange = useCallback(
    (isNext: boolean) => {
      if (isNext) {
        onNextClick()
      } else {
        onPreviousClick()
      }

      window.scrollTo(0, 0)
    },
    [onNextClick, onPreviousClick]
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_left}>
        <Text text='page' className={styles.wrapper_text} />

        <SelectOption
          name='page'
          onChange={onSelectChange}
          data={renderPages}
          inputClassName={styles.wrapper_select}
          defaultValue={currentPage ? String(currentPage) : '1'}
        />

        <Text text='of' className={styles.wrapper_text} RText={lastPage ? ` ${lastPage}` : '0'} />
      </div>

      <div className={styles.wrapper_right}>
        <Button
          isMaxContent
          size='small'
          variant='secondary'
          LeftIcon={ChevronLeftIcon}
          disabled={currentPage < 2 || loading}
          onClick={() => onPageChange(false)}
        >
          {!isTabletSmall ? 'previous' : null}
        </Button>

        <Button
          isMaxContent
          size='small'
          variant='secondary'
          RightIcon={ChevronRightIcon}
          disabled={currentPage === lastPage || loading}
          onClick={() => onPageChange(true)}
        >
          {!isTabletSmall ? 'next' : null}
        </Button>
      </div>
    </div>
  )
}

export default Pagination
