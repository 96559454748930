import type { FC } from 'react'
import { motion } from 'motion/react'
import { useNavigate } from 'react-router-dom'

import { Button, Text } from 'components'
import { ArrowRightIcon } from 'assets'
import { ERoutePaths } from 'libraries/router/types'

import styles from './MainBanner.module.scss'

const MainBanner: FC = () => {
  const navigate = useNavigate()

  const handelCategoryNavigation = () => {
    navigate(ERoutePaths.CategoriesMain)
  }

  return (
    <motion.section
      className={styles.wrapper}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6, delay: 0.6 }}
    >
      <div className={styles.wrapper_container}>
        <Text text='everything_you_need' className={styles.wrapper_title} />
        <Text text='buy_online' className={styles.wrapper_subtitle} />

        <Button
          className={styles.wrapper_button}
          onClick={handelCategoryNavigation}
          variant='secondary'
          size='large'
          RightIcon={ArrowRightIcon}
        >
          view_catalog
        </Button>
      </div>
    </motion.section>
  )
}

export default MainBanner
