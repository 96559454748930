import type { FC } from 'react'

import { Text } from 'components'

import type { TProgressLineProps } from './types'
import styles from './ProgressLine.module.scss'

const ProgressLine: FC<TProgressLineProps> = ({ progress, title, tooltip }) => (
  <div className={styles.wrapper}>
    {tooltip && (
      <p className={styles.wrapper_percent} style={{ left: `${progress - 5}%` }}>
        {progress}%
      </p>
    )}

    <div className={styles.wrapper_background}>
      <div className={styles.wrapper_fill} style={{ width: `${progress}%` }} />
    </div>

    {title && <Text text={title} className={styles.wrapper_title} />}
  </div>
)

export default ProgressLine
