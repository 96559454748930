import { RootState } from 'types'

const isCatalogOpen = (state: RootState) => state.global.isCatalogOpen
const isPageLoading = (state: RootState) => state.global.isPageLoading
const isAuthModalOpen = (state: RootState) => state.global.isAuthModalOpen

// Privacy Policy information
const selectInformation = (state: RootState) => state.global.information;

export const GlobalSelectors = {
  isCatalogOpen,
  isPageLoading,
  isAuthModalOpen,

  // Privacy Policy information
  selectInformation,
}
