import { Fragment, type FC } from 'react'
import classNames from 'classnames'

import { Loader, Text } from 'components'

import type { TButtonProps } from './type'
import styles from './Button.module.scss'

const Button: FC<TButtonProps> = ({
  type,
  textVar,
  onClick,
  children,
  LeftIcon,
  RightIcon,
  isLoading,
  className,
  isMaxContent,
  size = 'large',
  isTranslateIgnore,
  disabled = false,
  variant = 'primary',
}) => {
  const buttonClasses = classNames(styles.wrapper, className, styles[variant as string], styles[size as string], {
    [styles.wrapper_max_content]: isMaxContent,
  })

  const renderTextContent = isTranslateIgnore ? children : <Text variable={textVar} emptyTag text={`${children}`} />
  const renderContent = typeof children === 'string' ? renderTextContent : children

  return (
    <button type={type} disabled={disabled} onClick={onClick} className={buttonClasses}>
      {isLoading ? (
        <Fragment>
          <Loader /> <Text emptyTag text='loading' />
        </Fragment>
      ) : (
        <Fragment>
          {LeftIcon ? <LeftIcon /> : null}

          {renderContent}

          {RightIcon ? <RightIcon /> : null}
        </Fragment>
      )}
    </button>
  )
}

export default Button
