import { createAsyncThunk } from '@reduxjs/toolkit'

import api from 'libraries/axios'

import type { TSelectDataParams, TWarehouseParams } from './types'

export const fetchCountries = createAsyncThunk('select/countries', async ({ searchValue, page }: TSelectDataParams) => {
  try {
    const response = await api.get(`/v3/countries?page=${page}&search=${searchValue}`)

    return response
  } catch (error: any) {
    return error
  }
})

export const getPaymentMethods = createAsyncThunk(
  'select/paymentMethods',
  async (
    {
      customTtnShow,
      shipping_code,
    }: {
      shipping_code: string
      customTtnShow?: boolean
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.get(
        `/v2/orders/payment-methods?custom_ttn=${Number(customTtnShow)}&shipping_code=${shipping_code}&`
      )

      return response
    } catch (error: any) {
      console.error('ERROR getting payments select methods  ', error)

      return rejectWithValue(error)
    }
  }
)

export const getCategories = createAsyncThunk('select/categories', async ({ searchValue, page }: TSelectDataParams) => {
  try {
    const response = await api.get(`/v1/categories?page=${page || 1}&search=${searchValue}`)

    return response
  } catch (error: any) {
    return error
  }
})

export const getCustomers = createAsyncThunk('select/customers', async ({ searchValue, page }: TSelectDataParams) => {
  try {
    const response = await api.get(`/v1/customers?page=${page || 1}&search=${searchValue}`)

    return response
  } catch (error: any) {
    return error
  }
})

export const getCities = createAsyncThunk('select/cities', async ({ searchValue, page }: TSelectDataParams) => {
  try {
    const response = await api.get(`/v2/novaposhta/cities?page=${page || 1}&search=${searchValue}`)

    return response
  } catch (error: any) {
    return error
  }
})

export const getLanguages = createAsyncThunk('select/languages', async () => {
  try {
    const response = await api.get(`/v2/languages`)

    return response
  } catch (error: any) {
    return error
  }
})

export const getAddresses = createAsyncThunk('select/address', async ({ searchValue, page }: TSelectDataParams) => {
  try {
    let url = `/v2/novaposhta/streets?page=${page || 1}`

    if (searchValue) {
      url = `/v2/novaposhta/streets?page=${page || 1}&search=${searchValue}`
    }
    const response = await api.get(url)

    return response
  } catch (error: any) {
    return error
  }
})

export const getWarehouses = createAsyncThunk(
  'select/warehouses',
  async ({ searchValue, page = 1, city_ref = '' }: TWarehouseParams) => {
    try {
      const params = new URLSearchParams({ page: page.toString() })
      if (searchValue) params.append('search', searchValue)
      if (city_ref) params.append('city_ref', city_ref)

      const response = await api.get(`/v2/novaposhta/warehouses?${params.toString()}`)

      return response
    } catch (error: any) {
      return error
    }
  }
)
