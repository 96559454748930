import { useState, useEffect } from 'react'

// Generic useDebounce hook that supports both single and multiple values
export default function useDebounce<T>(values: T | T[], delay: number): T | T[] {
  const [debouncedValues, setDebouncedValues] = useState(values)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValues(values)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [values, delay])

  // If only one value is provided, return it directly
  if (!Array.isArray(values)) {
    return debouncedValues as T
  }

  return debouncedValues as T[]
}
