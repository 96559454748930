import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { CheckIcon, CloseIcon } from 'assets'
import { ERoutePaths } from 'libraries/router/types'
import { Button, NotFoundImage, Text } from 'components'

import type { TCartAddedSuccessfullyProps } from './types'
import styles from './CartAddedSuccessfully.module.scss'

const CartAddedSuccessfully: FC<TCartAddedSuccessfullyProps> = ({ image, title, size, onClose }) => {
  const navigate = useNavigate()

  const handleCartNavigation = () => {
    navigate(ERoutePaths.Cart)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_head}>
        <CloseIcon role='button' onClick={onClose} />
      </div>

      <Text LeftIcon={CheckIcon} className={styles.wrapper_head_text} text='added_to_cart_success' />

      <div className={styles.wrapper_container}>
        <div className={styles.wrapper_container_left}>
          {image ? (
            <img src={image} alt='product-image' width='100%' height='100%' className={styles.wrapper_image} />
          ) : (
            <NotFoundImage className={styles.wrapper_image} />
          )}
        </div>

        <div className={styles.wrapper_container_right}>
          <Text text={title} className={styles.wrapper_title} />
          <Text text='size' RText={`: ${size}`} className={styles.wrapper_description} />
        </div>
      </div>

      <div className={styles.wrapper_button_container}>
        <Button onClick={onClose} variant='secondary'>
          continue_shopping
        </Button>

        <Button onClick={handleCartNavigation}>go_to_cart</Button>
      </div>
    </div>
  )
}

export default CartAddedSuccessfully
