import { useRef, type FC } from 'react'
import { noop } from 'lodash'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import { Text } from 'components'
import { ArrowLeftIcon, CloseIcon } from 'assets'
import { useLockBodyScroll, useOnClickOutside } from 'hooks'

import type { TSidebar } from './types'
import styles from './Sidebar.module.scss'

const Sidebar: FC<TSidebar> = ({ children, isTitleCenter, title, onClose, onBack, isOpen, onClear, closeText }) => {
  const ref = useRef<HTMLDivElement>(null)
  const nodeRef = useRef<HTMLDivElement>(null)

  useLockBodyScroll(isOpen)
  useOnClickOutside([ref], onClose ? onClose : noop)

  return (
    <CSSTransition
      in={isOpen}
      timeout={300}
      unmountOnExit
      nodeRef={nodeRef}
      classNames={{
        enterDone: styles.wrapper_open,
      }}
    >
      <div ref={nodeRef} className={styles.wrapper}>
        <div ref={ref} className={styles.wrapper_container}>
          <div
            className={classNames(styles.wrapper_header, {
              [styles.wrapper_header_back]: onBack,
              [styles.wrapper_header_closable]: onClose,
            })}
          >
            {onBack && <ArrowLeftIcon className={styles.wrapper_left_icon} onClick={onBack} />}

            <Text
              text={title}
              className={classNames(styles.wrapper_title, { [styles.wrapper_title_center]: isTitleCenter || onBack })}
            />

            {onClose && (
              <div className={styles.wrapper_close}>
                {closeText && onClear && (
                  <Text className={styles.wrapper_close_text} text={closeText} role='button' onClick={onClear} />
                )}

                <CloseIcon onClick={onClose} />
              </div>
            )}
          </div>

          {children}
        </div>
      </div>
    </CSSTransition>
  )
}

export default Sidebar
