import { RootState } from 'types'

const details = (state: RootState) => state.products.details
const wishlist = (state: RootState) => state.products.wishlist
const qrSearch = (state: RootState) => state.products.qrSearch
const subscribe = (state: RootState) => state.products.subscribe
const statistics = (state: RootState) => state.products.statistics
const newProducts = (state: RootState) => state.products.newProducts
const subscribeList = (state: RootState) => state.products.subscribeList
const specialProducts = (state: RootState) => state.products.specialProducts
const bestsellerProducts = (state: RootState) => state.products.bestsellerProducts

export const ProductsSelectors = {
  details,
  wishlist,
  qrSearch,
  subscribe,
  statistics,
  newProducts,
  subscribeList,
  specialProducts,
  bestsellerProducts,
}
