import type { RootState } from 'types'

const cartList = (state: RootState) => state.cart.cartList
const addToCartState = (state: RootState) => state.cart.addToCart
const updateQuantityState = (state: RootState) => state.cart.updateQuantity
const removeBasketItemState = (state: RootState) => state.cart.removeBasketItem

export const CartSelectors = {
  cartList,
  addToCartState,
  updateQuantityState,
  removeBasketItemState,
}
