import { RootState } from 'types'

const loginState = (state: RootState) => state.auth.login
const confirmState = (state: RootState) => state.auth.confirm
const registerState = (state: RootState) => state.auth.register

export const AuthSelectors = {
  loginState,
  confirmState,
  registerState,
}
