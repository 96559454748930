import { useState, type FC } from 'react'
import classNames from 'classnames'
import { InputMask } from '@react-input/mask'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'motion/react'

import { Text } from 'components'
import { CheckCircleIcon, EyeIcon, EyeOffIcon } from 'assets'

import type { TInputProps } from './types'
import styles from './Input.module.scss'

const Input: FC<TInputProps> = ({
  mask,
  Icon,
  hint,
  error,
  label,
  isDirty,
  register,
  className,
  placeholder,
  LeftComponent,
  styleOverride,
  isFillIgnore,
  telephoneValue,
  containerClassname,
  ...inputProps
}) => {
  const { t } = useTranslation()
  const isPassword = inputProps.type === 'password'
  const isApproved = isDirty && !error && (!telephoneValue || !telephoneValue.includes('_'))
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const allClassNames = classNames(styles.wrapper__input, className, {
    [styles.wrapper__input__icon]: Icon,
    [styles.wrapper__input__error]: error,
    [styles.wrapper__input__approved]: isApproved || error,
    [styles.wrapper__input__password]: isPassword,
    [styles.wrapper__input__disabled]: inputProps.disabled,
  })

  const togglePasswordShow = () => {
    setShowPassword(prev => !prev)
  }

  return (
    <div className={classNames(styles.wrapper, containerClassname)}>
      {label && (
        <Text
          tagName='label'
          text={label}
          className={classNames(styles.wrapper__label, { [styles.wrapper__label__disabled]: inputProps.disabled })}
        />
      )}

      <div className={styles.wrapper__container}>
        {Icon && (
          <div
            className={classNames(styles.wrapper__container__icon, {
              [styles.wrapper__container__icon_filled]: !isFillIgnore,
            })}
          >
            <Icon />
          </div>
        )}

        {LeftComponent && LeftComponent}

        {mask ? (
          <InputMask
            {...register}
            disabled={inputProps.disabled}
            mask={mask}
            showMask={isDirty}
            autoComplete='off'
            replacement={{ _: /\d/ }}
            placeholder={t(placeholder ?? '')}
            style={{ ...styleOverride }}
            onPaste={inputProps.onPaste}
            className={allClassNames}
            defaultValue={inputProps.defaultValue}
          />
        ) : (
          <input
            {...register}
            {...inputProps}
            type={isPassword && showPassword ? 'text' : inputProps.type}
            placeholder={t(placeholder ?? '')}
            style={{ ...styleOverride }}
            className={allClassNames}
          />
        )}

        <AnimatePresence mode='wait'>
          {isApproved || error ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.6 }}
            >
              <CheckCircleIcon
                className={classNames(styles.wrapper__check, {
                  [styles.wrapper__check__error]: error,
                  [styles.wrapper__check__password]: isPassword,
                })}
              />
            </motion.div>
          ) : null}
        </AnimatePresence>

        {isPassword && (
          <div role='button' className={styles.wrapper__toggle} onClick={togglePasswordShow}>
            {showPassword ? <EyeIcon /> : <EyeOffIcon />}
          </div>
        )}
      </div>

      <AnimatePresence>
        {!mask && error && (
          <motion.p
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
            className={styles.wrapper__message}
          >
            <Text text={error} emptyTag />
          </motion.p>
        )}

        {hint && !error && (
          <motion.p
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
            className={classNames(styles.wrapper__message, styles.wrapper__message__hint)}
          >
            <Text text={hint} emptyTag />
          </motion.p>
        )}

        {isDirty && mask && (!telephoneValue || telephoneValue.includes('_')) && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
            className={styles.wrapper__message}
          >
            <Text text={error} emptyTag />
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Input
