import { type FC } from 'react'
import ReactSlider from 'react-slider'

import type { TPriceRangeSlider } from './types'
import styles from './PriceRangeSlider.module.scss'

const PriceRangeSlider: FC<TPriceRangeSlider> = ({ values, setValues, min, max }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_title}>
        ₴{values[0]} – ₴{values[1]}
      </div>

      <ReactSlider
        className={styles.wrapper_slider}
        thumbClassName={styles.wrapper_thumb}
        trackClassName={styles.wrapper_track}
        min={min}
        max={max}
        value={values}
        onChange={setValues}
        pearling
        minDistance={50}
        renderThumb={(props, state) => (
          <div {...props}>
            <div className={styles.wrapper_thumb_text}>₴{state.valueNow}</div>
          </div>
        )}
      />
    </div>
  )
}

export default PriceRangeSlider
