import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'

import type { TToggleSwitchProps } from './types'
import styles from './ToggleSwitch.module.scss'

const ToggleSwitch: FC<TToggleSwitchProps> = ({ checked, onClick, label, labelClassName }) => (
  <div role='button' onClick={onClick} className={styles.wrapper}>
    <div className={classNames(styles.wrapper_container, { [styles.wrapper_checked]: checked })}>
      <div className={styles.wrapper_thumb} />
    </div>

    {label && <Text text={label} className={classNames(styles.wrapper_label, labelClassName)} />}
  </div>
)

export default ToggleSwitch
