import { type FC, useState } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { Text } from 'components'
import { ChevronDownIcon } from 'assets'

import type { TAccordionProps } from './types'
import styles from './Accordion.module.scss'

const Accordion: FC<TAccordionProps> = ({
  items,
  className,
  OpenIcon,
  CloseIcon,
  headerClassname,
  isSingleOpen = false,
}) => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([])

  const toggleItem = (id: number, onToggle?: () => void) => {
    setOpenIndexes(prev => {
      const isCurrentlyOpen = prev.includes(id)

      if (isSingleOpen) {
        return isCurrentlyOpen ? [] : [id]
      }

      return isCurrentlyOpen ? prev.filter(i => i !== id) : [...prev, id]
    })

    if (onToggle && !openIndexes.includes(id)) {
      onToggle()
    }
  }

  return (
    <div className={classNames(styles.wrapper, className)}>
      {items.map((item: any) => (
        <div key={item.id} className={styles.wrapper_container}>
          <div className={styles.wrapper_item}>
            <div
              role='button'
              className={classNames(styles.wrapper_header, headerClassname)}
              onClick={() => toggleItem(item.id, item.onToggle)}
            >
              <Text tagName='span' className={styles.wrapper_title} text={item.title} />

              {OpenIcon && CloseIcon ? (
                openIndexes.includes(item.id) ? (
                  <CloseIcon className={styles.wrapper_icon} />
                ) : (
                  <OpenIcon className={styles.wrapper_icon} />
                )
              ) : (
                <ChevronDownIcon
                  className={classNames(styles.wrapper_icon, {
                    [styles.wrapper_icon_active]: openIndexes.includes(item.id),
                  })}
                />
              )}
            </div>

            <AnimatePresence>
              {openIndexes.includes(item.id) && (
                <motion.div
                  className={styles.wrapper_content}
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {typeof item?.content === 'function' ? item?.content() : item?.content}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Accordion
