import { createSlice } from '@reduxjs/toolkit'

import type { TCategory } from './types'
import { fetchCategoryRoot, fetchCategoryTreeV1 } from './actions'

const initialState: TCategory = {
  tree: {
    data: null,
    error: null,
    loading: false,
  },

  root: {
    data: null,
    error: null,
    loading: false,
  },
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCategoryTreeV1.pending, state => {
        state.tree.loading = true
        state.tree.error = null
      })
      .addCase(fetchCategoryTreeV1.fulfilled, (state, action) => {
        state.tree.loading = false
        state.tree.data = action.payload
      })
      .addCase(fetchCategoryTreeV1.rejected, (state, action) => {
        state.tree.loading = false
        state.tree.error = action.error.message || 'failed to fetch category tree'
      })

      //

      .addCase(fetchCategoryRoot.pending, state => {
        state.root.loading = true
        state.root.error = null
      })
      .addCase(fetchCategoryRoot.fulfilled, (state, action) => {
        state.root.loading = false
        state.root.data = action.payload
      })
      .addCase(fetchCategoryRoot.rejected, (state, action) => {
        state.root.loading = false
        state.root.error = action.error.message || 'failed to fetch category tree'
      })
  },
})

export const {} = categoriesSlice.actions

const categoriesSliceReducer = categoriesSlice.reducer

export default categoriesSliceReducer
