import { normalizeText } from 'utils'

import { createSlice } from '@reduxjs/toolkit'

import type { TGlobal } from './types'
import { fetchInformation } from './actions'

const initialState: TGlobal = {
  isPageLoading: false,
  isCatalogOpen: false,
  isAuthModalOpen: false,

  // Privacy policy information
  information: {
    data: null,
    loading: false,
    error: null,
  },
}

const GlobalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    toggleCatalogOpen: (state, action) => ({
      ...state,
      isCatalogOpen: action.payload,
    }),

    toggleAuthModalOpen: (state, action) => ({
      ...state,
      isAuthModalOpen: action.payload,
    }),

    togglePageLoading: (state, action) => ({
      ...state,
      isPageLoading: action.payload,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInformation.pending, state => {
        state.information.loading = true
        state.information.error = null
      })
      .addCase(fetchInformation.fulfilled, (state, action) => {
        state.information.loading = false

        const description = action.payload?.[0]?.info?.description
        const normalizedText = normalizeText(description)
        state.information.data = normalizedText
      })
      .addCase(fetchInformation.rejected, state => {
        state.isPageLoading = false
        state.information.error = 'Failed to fetch information data'
      })
  },
})

export const { toggleCatalogOpen, toggleAuthModalOpen, togglePageLoading } = GlobalSlice.actions

const globalSliceReducer = GlobalSlice.reducer

export default globalSliceReducer
