import type { FC } from 'react'
import classNames from 'classnames'

import { ImageOutlineIcon } from 'assets'

import type { TNotFoundImage } from './types'
import styles from './NotFoundImage.module.scss'

const NotFoundImage: FC<TNotFoundImage> = ({ className }) => (
  <div className={classNames(styles.wrapper, className)}>
    <ImageOutlineIcon />
  </div>
)

export default NotFoundImage
