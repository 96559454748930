import { type FC, useState } from 'react'
import classNames from 'classnames'
import { isUndefined } from 'lodash'
import { AnimatePresence, motion } from 'framer-motion'

import { Text } from 'components'
import { CheckboxBlankIcon, CheckboxMarkedIcon } from 'assets'

import type { TCheckboxProps } from './types'
import styles from './Checkbox.module.scss'

const Checkbox: FC<TCheckboxProps> = ({ checked, onChange, label, className, RText }) => {
  const [isChecked, setIsChecked] = useState(checked)

  const toggleCheckbox = () => {
    const newChecked = !isChecked
    setIsChecked(newChecked)
    onChange?.(newChecked)
  }

  const isCheckedCondition = isUndefined(checked) ? isChecked : checked

  return (
    <div onClick={toggleCheckbox} className={classNames(styles.wrapper, className)}>
      <AnimatePresence mode='wait'>
        {isCheckedCondition ? (
          <motion.div
            className={styles.wrapper_icon}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <CheckboxMarkedIcon />
          </motion.div>
        ) : (
          <div>
            <CheckboxBlankIcon />
          </div>
        )}
      </AnimatePresence>

      {label && <Text tagName='span' RText={RText} text={label} className={styles.wrapper_label} />}
    </div>
  )
}

export default Checkbox
