import type { FC } from 'react'
import classNames from 'classnames'

import type { TProductOption } from './types'
import styles from './ProductOption.module.scss'

const ProductOption: FC<TProductOption> = ({ element, index, selectedIndex, onClick }) => {
  const { name, quantity, product_option_value_id, warehouse_id } = element

  return (
    <div
      role='button'
      className={classNames(styles.wrapper, {
        [styles.wrapper__disabled]: quantity === 0,
        [styles.wrapper__active]: selectedIndex === index,
      })}
      onClick={() => {
        if (quantity !== 0) {
          onClick(index, product_option_value_id, warehouse_id)
        }
      }}
    >
      <p className={styles.wrapper__name}>{name}</p>
      <p className={styles.wrapper__quantity}>({quantity} шт)</p>
    </div>
  )
}

export default ProductOption
