import type { FC } from 'react'

import { InstagramIcon } from 'assets'
import { Button, Text, Modal } from 'components'

import type { TOrderBlackListModal } from './types'
import styles from './OrderBlackListModal.module.scss'

const OrderBlackListModal: FC<TOrderBlackListModal> = ({ onClose, blackListItem, isOrderResultBlackListModal }) => (
  <Modal onClose={onClose} className={styles.wrapper_modal} show={isOrderResultBlackListModal}>
    <div className={styles.wrapper}>
      <div>
        <Text className={styles.wrapper_title} text='order_create_black_list' />
        <Text className={styles.wrapper_subtitle} text='order_create_black_list_description' />
      </div>

      <Text
        className={styles.wrapper_text}
        text='order_create_black_list_reason'
        variable={{
          reason: blackListItem?.reason,
        }}
      />

      <a href={blackListItem?.link} target='_blank'>
        <Text
          className={styles.wrapper_link}
          text='Instagram'
          RText={': ' + blackListItem?.username || ''}
          LeftIcon={InstagramIcon}
        />
      </a>

      <Button onClick={onClose}>continue</Button>
    </div>
  </Modal>
)

export default OrderBlackListModal
