import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'

import type { TNavigationTab } from './types'
import styles from './NavigationTab.module.scss'

const NavigationTab: FC<TNavigationTab> = ({ Icon, text, active = false, className }) => (
  <div className={classNames(styles.wrapper, className, { [styles.wrapper_active]: active })}>
    <Icon />

    <Text text={text} />
  </div>
)

export default NavigationTab
