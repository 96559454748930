import { lazy } from 'react'

import { ERoutePaths, type TRoutePageType } from './types'

const FAQ = lazy(() => import('pages/FAQ'))
const Cart = lazy(() => import('pages/Cart'))
const Home = lazy(() => import('pages/Home'))
const Error = lazy(() => import('pages/Error'))
const Profile = lazy(() => import('pages/Profile'))
const Settings = lazy(() => import('pages/Settings'))
const Ordering = lazy(() => import('pages/Ordering'))
const Products = lazy(() => import('pages/Products'))
const Wishlist = lazy(() => import('pages/Wishlist'))
const MyOrders = lazy(() => import('pages/MyOrders'))
const ContactUs = lazy(() => import('pages/ContactUs'))
const Categories = lazy(() => import('pages/Categories'))
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'))
const ProductDetails = lazy(() => import('pages/ProductDetails'))

const routesList: TRoutePageType[] = [
  {
    element: Error,
    path: ERoutePaths.Error,
    title: 'Error',
  },

  {
    element: Home,
    path: ERoutePaths.Home,
    title: 'Home',
  },

  {
    element: Profile,
    path: ERoutePaths.Profile,
    title: 'Profile',
    isPrivate: true,
  },

  {
    element: Wishlist,
    path: ERoutePaths.Wishlist,
    title: 'Wishlist',
    isPrivate: true,
  },

  {
    element: Settings,
    path: ERoutePaths.Settings,
    title: 'Settings',
    isPrivate: true,
  },

  {
    element: Cart,
    path: ERoutePaths.Cart,
    title: 'Cart',
    isPrivate: true,
  },

  {
    element: Ordering,
    path: ERoutePaths.Ordering,
    title: 'Ordering',
    isPrivate: true,
  },

  {
    element: MyOrders,
    path: ERoutePaths.MyOrders,
    title: 'My Orders',
    isPrivate: true,
  },

  {
    element: Categories,
    path: ERoutePaths.Categories,
    title: 'Categories',
  },

  {
    element: ContactUs,
    path: ERoutePaths.Contacts,
    title: 'Contacts',
  },

  {
    element: PrivacyPolicy,
    path: ERoutePaths.PrivacyPolicy,
    title: 'Privacy Policy',
  },

  {
    element: Products,
    path: ERoutePaths.Products,
    title: 'Products',
  },

  {
    element: FAQ,
    path: ERoutePaths.FAQ,
    title: 'FAQ',
  },

  {
    element: ProductDetails,
    path: ERoutePaths.ProductDetails,
    title: 'Product Details',
  },
]

export default routesList
