import { Fragment, type FC } from 'react'

import { Skeleton } from 'components'

import type { TSkeleton } from '../types'
import styles from './OrderCardSkeleton.module.scss'

const OrderCardSkeleton: FC<TSkeleton> = ({ skeletonCount = 1 }) => (
  <Fragment>
    {Array.from({ length: skeletonCount }).map((_, index) => (
      <div key={index} className={styles.wrapper}>
        <div className={styles.wrapper_container}>
          <div className={styles.wrapper_row}>
            <Skeleton width='30%' height='16px' />
            <Skeleton width='30%' height='16px' />
          </div>

          <div className={styles.wrapper_column}>
            <div className={styles.wrapper_row}>
              <Skeleton width='30%' height='16px' />
              <Skeleton width='30%' height='16px' />
            </div>

            <div className={styles.wrapper_row}>
              <Skeleton width='30%' height='16px' />
              <Skeleton width='30%' height='16px' />
            </div>
          </div>

          <div className={styles.wrapper_item_container}>
            <Skeleton width='80%' height='64px' />
          </div>

          <div className={styles.wrapper_column}>
            <div className={styles.wrapper_row}>
              <Skeleton width='30%' height='16px' />
              <Skeleton width='30%' height='16px' />
            </div>

            <div className={styles.wrapper_row}>
              <Skeleton width='30%' height='16px' />
              <Skeleton width='30%' height='16px' />
            </div>

            <div className={styles.wrapper_row}>
              <Skeleton width='30%' height='16px' />
              <Skeleton width='30%' height='16px' />
            </div>
          </div>
        </div>

        <div className={styles.wrapper_bottom}>
          <div className={styles.wrapper_row}>
            <Skeleton width='30%' height='22px' />
            <Skeleton width='30%' height='22px' />
          </div>

          <div className={styles.wrapper_row}>
            <Skeleton width='50%' height='36px' />
            <Skeleton width='50%' height='36px' />
          </div>
        </div>
      </div>
    ))}
  </Fragment>
)

export default OrderCardSkeleton
