import type { FC } from 'react'

import { Modal } from 'components'
import { useCopyText } from 'hooks'
import { Text, Button } from 'components'
import { CheckCircleIcon, ContentCopyIcon, WarningIcon } from 'assets'

import type { TOrderResultModal } from './types'
import styles from './OrderResultModal.module.scss'

const OrderResultModal: FC<TOrderResultModal> = ({
  showModal,
  isOrderResultContentTypeModal,
  isOrderResultContentModal,
  ttnError,
  ttn,
  continueOrder,
  selectedPaymentMethod,
  onClose,
  paymentMethodDetail,
}) => {
  const { isCopied, handleCopyClickChange } = useCopyText()

  const isDetails = selectedPaymentMethod === 'bank_transfer_manual_check' && paymentMethodDetail
  const message = isDetails ? 'py_details' : String(isOrderResultContentModal)

  const renderTTN = () => {
    if (ttnError.length > 0) {
      return <Text text={ttnError} className={styles.wrapper_text} />
    } else if (ttn) {
      return (
        <div role='button' onClick={() => handleCopyClickChange(ttn)} className={styles.wrapper_container}>
          <Text text={ttn} className={styles.wrapper_text} />

          {isCopied ? (
            <CheckCircleIcon className={styles.wrapper_success} />
          ) : (
            <ContentCopyIcon className={styles.wrapper_warning} />
          )}
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <Modal className={styles.wrapper_modal} onClose={onClose} show={showModal}>
      <div className={styles.wrapper}>
        {isOrderResultContentTypeModal === 'success' ? (
          <CheckCircleIcon className={styles.wrapper_success} />
        ) : (
          <WarningIcon className={styles.wrapper_warning} />
        )}

        <Text className={styles.wrapper_title} text={message} RText={isDetails ? ' : ' + paymentMethodDetail : ''} />

        {renderTTN()}

        <Button onClick={continueOrder}>continue</Button>
      </div>
    </Modal>
  )
}

export default OrderResultModal
