import type { FC } from 'react'

export type TRoutePageType = {
  path: string
  element: FC
  title: string
  isPrivate?: boolean
}

export enum ERoutePaths {
  Error = '*',
  Home = '/',
  Profile = '/profile',

  Categories = '/categories/:categoryId',
  CategoriesMain = '/categories/main',

  Products = '/products',
  ProductDetails = '/product-details/:productId',

  Cart = '/cart',
  Wishlist = '/wishlist',

  FAQ = '/faq',
  Ordering = '/ordering',
  Contacts = '/contacts',
  Settings = '/settings',
  MyOrders = '/my-orders',
  PrivacyPolicy = '/privacy-policy',
}
