import { ToastContainer } from 'react-toastify'

import { CloseIcon } from 'assets'

const ToastServices = () => (
  <ToastContainer
    draggable
    rtl={false}
    theme='light'
    pauseOnHover
    closeOnClick
    autoClose={2000}
    pauseOnFocusLoss
    newestOnTop={false}
    position='top-right'
    hideProgressBar={false}
    toastStyle={{
      alignItems: 'center',
      background: '#ffff',
      justifyContent: 'space-between',
      boxShadow: ' 0px 1px 2px 0px #1018280d',
    }}
    closeButton={
      <>
        <CloseIcon />
      </>
    }
  />
)

export default ToastServices
