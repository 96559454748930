import { Fragment, type FC } from 'react'
import classNames from 'classnames'

import { Skeleton } from 'components'

import type { TSkeleton } from '../types'
import styles from './ProductCardSkeleton.module.scss'

const ProductCardSkeleton: FC<TSkeleton> = ({ skeletonCount = 1, className }) => (
  <Fragment>
    {Array.from({ length: skeletonCount }).map((_, index) => (
      <div key={index} className={classNames(styles.wrapper, className)}>
        <Skeleton className={styles.wrapper_image} width='100%' />

        <div className={styles.wrapper_bottom}>
          <Skeleton className={styles.wrapper_title} width='80%' height='16px' />

          <Skeleton className={styles.wrapper_price} width='50%' height='16px' />
        </div>
      </div>
    ))}
  </Fragment>
)

export default ProductCardSkeleton
