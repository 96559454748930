import { useCallback, useEffect, useMemo, useState, type FC } from 'react'
import classNames from 'classnames'
import { isArray } from 'lodash'
import { AnimatePresence, motion } from 'framer-motion'
import { useSearchParams } from 'react-router-dom'

import { CloseIcon } from 'assets'
import { useAppDispatch, useAppSelector } from 'hooks'
import { FilterSelectors } from 'store/filter/selectors'
import { getFilterOptionsList, getFilterSingleOptionList } from 'store/filter/actions'
import { Accordion, Button, Checkbox, PriceRangeSlider, Sidebar, Text } from 'components'

import { customFilters } from './utils'
import type { TCustomFilters, TFilterSidebarProps } from './types'
import styles from './FilterSidebar.module.scss'

const FilterSidebar: FC<TFilterSidebarProps> = ({
  isOpen,
  onClose,
  searchQuery,
  categoryId,
  order_by,
  options,
  priceFrom,
  priceTo,
}) => {
  const dispatch = useAppDispatch()
  const { prices } = useAppSelector(FilterSelectors.searchResults)
  const { data: optionsList } = useAppSelector(FilterSelectors.filterOptionsList)
  const { data: singleOptions } = useAppSelector(FilterSelectors.filterSingleOption)

  const MIN = Number(prices?.min)
  const MAX = Number(prices?.max)

  const defaultOrder = customFilters.find(element => element.method === order_by)
  const parsedOptions = useMemo(() => (options ? JSON.parse(options as string) : []), [options])
  const defaultSelectedOptions = useMemo(
    () => (options ? [...parsedOptions] : order_by ? [defaultOrder] : []),
    [defaultOrder, options, order_by, parsedOptions]
  )
  const from = priceFrom && Number(priceFrom) <= MAX ? Number(priceFrom) : MIN
  const to = priceTo && Number(priceTo) >= MIN ? Number(priceTo) : MAX
  const defaultPrice: [number, number] = useMemo(() => (from <= to ? [from, to] : [MIN, MAX]), [MAX, MIN, from, to])

  const [, setSearchParams] = useSearchParams()
  const [selectedOptions, setSelectedOptions] = useState<any[]>(defaultSelectedOptions)
  const [selectedOrder, setSelectedOrder] = useState<TCustomFilters | null>(defaultOrder || null)
  const [priceValues, setPriceValues] = useState<[number, number]>(defaultPrice)

  const isPriceFiltersApplied = priceValues[0] !== MIN || priceValues[1] !== MAX

  const handleFilterSelections = (item: any) => {
    setSelectedOptions(prev => (prev.includes(item) ? prev.filter(element => element !== item) : [...prev, item]))
  }

  const handleOrderSelection = (value: any) => {
    setSelectedOrder(value)

    setSelectedOptions(prev => {
      const orderIndex = prev.findIndex(element => element.method)

      return orderIndex === -1 ? [...prev, value] : prev.map((item, i) => (i === orderIndex ? value : item))
    })
  }

  const handleAccordionToggle = useCallback(
    (option_id: string) => {
      if (!singleOptions?.[Number(option_id)]) {
        dispatch(
          getFilterSingleOptionList({ option_id, category_id: categoryId || '', product_filter: searchQuery || '' })
        )
      }
    },
    [categoryId, dispatch, searchQuery, singleOptions]
  )

  const onBadgeClick = (item: any) => {
    if (item.method) {
      setSelectedOrder(null)
    }

    setSelectedOptions(prev => prev.filter(element => element !== item))
  }

  const renderBadges = selectedOptions.map(element => (
    <Text
      role='button'
      text={element.name}
      RightIcon={CloseIcon}
      key={element.id + element.name}
      onClick={() => onBadgeClick(element)}
      className={styles.wrapper_container_header_badge}
    />
  ))

  const onApplyFiltersClick = () => {
    setSearchParams(prev => {
      if (selectedOrder?.method) {
        prev.set('order_by', selectedOrder?.method)
      }

      if (selectedOptions.length > 0) {
        prev.set('option_value_filter', JSON.stringify(selectedOptions))
      }

      if (isPriceFiltersApplied) {
        prev.set('price_from', priceValues[0].toString())
        prev.set('price_to', priceValues[1].toString())
      }

      return prev
    })

    onClose()
  }

  const onClearAllFilterClick = useCallback(() => {
    setSearchParams(prev => {
      prev.delete('order_by')
      prev.delete('price_to')
      prev.delete('price_from')
      prev.delete('option_value_filter')

      return prev
    })

    setPriceValues([MIN, MAX])
    setSelectedOptions([])
    setSelectedOrder(null)
  }, [MAX, MIN, setSearchParams])

  useEffect(() => {
    dispatch(getFilterOptionsList({ category_id: categoryId || '', product_filter: searchQuery || '' }))
  }, [categoryId, dispatch, searchQuery])

  useEffect(() => {
    setSelectedOptions(defaultSelectedOptions)
  }, [categoryId, defaultSelectedOptions, dispatch, searchQuery])

  useEffect(() => {
    if (prices) {
      setPriceValues(defaultPrice)
    }
  }, [defaultPrice, prices])

  return (
    <Sidebar
      title='filters_and_sorting'
      closeText='clear_all'
      onClear={onClearAllFilterClick}
      onClose={onClose}
      isOpen={isOpen}
    >
      <div className={styles.wrapper}>
        <div className={styles.wrapper_container}>
          {/* HEADER */}
          <AnimatePresence>
            {(selectedOptions.length > 0 || isPriceFiltersApplied) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className={styles.wrapper_container_header}
              >
                <Text text='applied_filters' className={styles.wrapper_container_header_title} />

                <div className={styles.wrapper_container_header_badge_container}>
                  {isPriceFiltersApplied && (
                    <Text
                      role='button'
                      text='price'
                      RightIcon={CloseIcon}
                      onClick={() => setPriceValues([MIN, MAX])}
                      className={styles.wrapper_container_header_badge}
                    />
                  )}
                  {renderBadges}
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* CUSTOM OPTIONS */}
          <Accordion
            items={[
              {
                id: 1341234,
                title: 'sort_by',
                content: () => (
                  <div>
                    {customFilters.map(value => (
                      <Text
                        role='button'
                        key={value.id}
                        text={value.name}
                        onClick={() => handleOrderSelection(value)}
                        className={classNames(styles.wrapper_sort_by, {
                          [styles.wrapper_sort_by_active]: selectedOrder === value,
                        })}
                      />
                    ))}
                  </div>
                ),
              },
            ]}
          />

          {/* FETCHED OPTIONS */}
          {isArray(optionsList) && (
            <Accordion
              items={optionsList?.map(option => ({
                id: option.option_id,
                title: option.name,
                content: () => (
                  <div className={styles.wrapper_accordion}>
                    {singleOptions?.[option.option_id]?.map(value => (
                      <Checkbox
                        checked={selectedOptions.some(option => option.option_value_id === value.option_value_id)}
                        onChange={() => handleFilterSelections(value)}
                        key={value.option_value_id}
                        className={styles.wrapper_accordion_item}
                        label={value.name}
                      />
                    ))}
                  </div>
                ),
                onToggle: () => handleAccordionToggle(String(option.option_id)),
              }))}
            />
          )}

          {/* PRICE */}
          <Accordion
            items={[
              {
                id: 1341232,
                title: 'price',
                content: () => (
                  <div className={styles.wrapper_accordion}>
                    <PriceRangeSlider min={MIN} max={MAX} values={priceValues} setValues={setPriceValues} />
                  </div>
                ),
              },
            ]}
          />
        </div>

        {/* BOTTOM */}
        <div className={styles.wrapper_bottom}>
          <Button
            onClick={onApplyFiltersClick}
            textVar={{ number: isPriceFiltersApplied ? selectedOptions.length + 1 : selectedOptions.length }}
            size='small'
            disabled={selectedOptions.length === 0 && priceValues[0] === MIN && priceValues[1] === MAX}
          >
            apply_filters
          </Button>
        </div>
      </div>
    </Sidebar>
  )
}
export default FilterSidebar
