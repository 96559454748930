import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'libraries/axios'

export const fetchInformation = createAsyncThunk('information/fetchInformation', async () => {
  try {
    const response = await api.get('/v1/information')

    return response.data
  } catch (err) {
    throw Error('Failed to get privacy policy information data')
  }
})
