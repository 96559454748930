import type { TOrderedItem, TOrderedProduct } from 'store/order/types'
import type { TQrSearchItem } from 'store/products/types'

export const earningColor = (number: number) => {
  if (number) {
    if (number > 0) {
      return '#00AB2B'
    } else if (number < 0) {
      return '#ab0000'
    }
  }

  return '#101828'
}

export const renderShippingInfo = (
  value: 'city_id' | 'warehouse_id' | 'shipping_street',
  item?: TOrderedItem | null
): string => {
  switch (value) {
    case 'city_id':
      return item?.city ?? ''
    case 'warehouse_id':
      return item?.address ?? ''
    case 'shipping_street':
      return item?.shipping_street ?? ''
    default:
      return ''
  }
}

export const isOrderedProduct = (item: TOrderedProduct | TQrSearchItem): item is TOrderedProduct => {
  return (
    'order_product_id' in item &&
    'price' in item &&
    'quantity' in item &&
    'total' in item &&
    'shelves' in item &&
    Array.isArray(item.option) // Ensures `option` is an array
  )
}
