import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TCartState } from './types'
import { addToCart, getCartList, removeBasketItem, updateBasketQuantity } from './actions'

const initialState: TCartState = {
  addToCart: {
    data: null,
    error: null,
    loading: false,
  },

  cartList: {
    data: null,
    error: null,
    loading: false,
  },

  updateQuantity: {
    data: null,
    error: null,
    loading: false,
  },

  removeBasketItem: {
    data: null,
    error: null,
    loading: false,
  },
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    filterCartItemsById: (state, action: PayloadAction<number>) => {
      if (state.cartList.data) {
        state.cartList.data.data = state.cartList.data.data.filter(item => item.cart_id !== action.payload)
      }
    },

    refreshUpdatedItem: state => {
      if (state.updateQuantity.data) {
        state.updateQuantity.data = null
        state.removeBasketItem.data = null
      }
    },

    filterCartItemsOptionsById: (state, action: PayloadAction<number>) => {
      if (state.cartList.data) {
        state.cartList.data.data = state.cartList.data.data.map(item => ({
          ...item,
          option: item.option.filter(option => option.id !== action.payload),
        }))
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addToCart.fulfilled, (state, action) => {
        state.addToCart.loading = false
        state.addToCart.data = action.payload
      })
      .addCase(addToCart.pending, state => {
        state.addToCart.loading = true
        state.addToCart.error = null
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.addToCart.loading = false
        state.addToCart.error = action.error.message
      })

      //

      .addCase(getCartList.fulfilled, (state, action) => {
        state.cartList.loading = false
        state.cartList.data = action.payload
      })
      .addCase(getCartList.pending, state => {
        state.cartList.loading = true
        state.cartList.error = null
      })
      .addCase(getCartList.rejected, (state, action) => {
        state.cartList.loading = false
        state.cartList.error = action.error.message
      })

      //

      .addCase(updateBasketQuantity.fulfilled, (state, action: any) => {
        state.updateQuantity.loading = false
        state.updateQuantity.data = action.payload

        if (state.cartList.data) {
          state.cartList.data = {
            ...state.cartList.data,
            stock: action.payload?.stock,
            message: action.payload?.message,
            message_code: action.payload?.message_code,
          }
        }
      })
      .addCase(updateBasketQuantity.pending, state => {
        state.updateQuantity.loading = true
        state.updateQuantity.error = null
      })
      .addCase(updateBasketQuantity.rejected, (state, action) => {
        state.updateQuantity.loading = false
        state.updateQuantity.error = action.error.message
      })

      //

      .addCase(removeBasketItem.fulfilled, (state, action: any) => {
        state.removeBasketItem.loading = false
        state.removeBasketItem.data = action.payload

        if (state.cartList.data) {
          state.cartList.data = {
            ...state.cartList.data,
            stock: action.payload?.stock,
            message: action.payload?.message,
            message_code: action.payload?.message_code,
          }
        }
      })
      .addCase(removeBasketItem.pending, state => {
        state.removeBasketItem.loading = true
        state.removeBasketItem.error = null
      })
      .addCase(removeBasketItem.rejected, (state, action) => {
        state.removeBasketItem.loading = false
        state.removeBasketItem.error = action.error.message
      })
  },
})

const cartReducer = cartSlice.reducer

export const { filterCartItemsById, refreshUpdatedItem, filterCartItemsOptionsById } = cartSlice.actions

export default cartReducer
