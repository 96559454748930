import { useState, type FC } from 'react'
import { capitalize } from 'lodash'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { NotFoundImage, Text } from 'components'
import { toggleAuthModalOpen } from 'store/global'
import { useAppDispatch, useResponsive } from 'hooks'
import { HeartFilledIcon, HeartOutlineIcon } from 'assets'
import { getDiscountPercentage, getUserToken, parsePrice } from 'utils'
import { addToWishlist, removeFromWishlist } from 'store/products/action'

import type { TProductCard } from './types'
import styles from './ProductCard.module.scss'

const ProductCard: FC<TProductCard> = ({
  data,
  isNew,
  onClick,
  className,
  isBestseller,
  isNavigationBlocked,
  isInWishlist = false,
}) => {
  const userToken = getUserToken()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isTabletSmall } = useResponsive()
  const { thumb, thumb_large, name, in_wishlist, price, special, product_id, id } = data

  const [isSaved, setIsSaved] = useState<boolean>(Boolean(in_wishlist) || isInWishlist)

  const discount = special && getDiscountPercentage(parsePrice(price), parsePrice(special))

  const onCardClick = () => {
    if (!isNavigationBlocked) {
      navigate(`/product-details/${id || product_id}`)
    }

    onClick?.()
  }

  const toggleSave = () => {
    if (!userToken) return dispatch(toggleAuthModalOpen(true))

    setIsSaved(!isSaved)
    dispatch(isSaved ? removeFromWishlist(id || product_id) : addToWishlist(id || product_id))
  }

  const renderPrice = (
    <>
      {special ? (
        <>
          {special}{' '}
          <sup>
            / <Text emptyTag text='drop_price' />
          </sup>
          {isTabletSmall && <br />}
          <span className={styles.wrapper_price_special}>{price}</span>
        </>
      ) : (
        <>
          {price}{' '}
          <sup>
            / <Text emptyTag text='drop_price' />
          </sup>
        </>
      )}
    </>
  )

  return (
    <div role='button' onClick={onCardClick} className={classNames(styles.wrapper, className)}>
      <div className={styles.wrapper_header}>
        <div className={styles.wrapper_header_container}>
          <div className={styles.wrapper_badge_container}>
            {special && (
              <Text
                text='discount'
                variable={{ var: `-${discount}%` }}
                className={classNames(styles.wrapper_badge, styles.wrapper_badge_primary)}
              />
            )}
            {isNew && (
              <Text text='new_product' className={classNames(styles.wrapper_badge, styles.wrapper_badge_secondary)} />
            )}

            {isBestseller && (
              <Text text='bestseller' className={classNames(styles.wrapper_badge, styles.wrapper_badge_secondary)} />
            )}
          </div>

          <div
            role='button'
            onClick={event => {
              event.stopPropagation()
              toggleSave()
            }}
            className={styles.wrapper_saved}
          >
            {isSaved ? <HeartFilledIcon /> : <HeartOutlineIcon />}
          </div>
        </div>

        {thumb_large || thumb ? (
          <img src={thumb_large || thumb} width='305px' height='305px' className={styles.wrapper_image} alt={name} />
        ) : (
          <NotFoundImage className={styles.wrapper_image} />
        )}
      </div>

      <div className={styles.wrapper_bottom}>
        <Text text={capitalize(name)} className={styles.wrapper_title} />

        <p className={styles.wrapper_price}>{renderPrice}</p>
      </div>
    </div>
  )
}

export default ProductCard
