import type { FC } from 'react'

import { Skeleton } from 'components'

import styles from './RoundedCardSkeleton.module.scss'

const RoundedCardSkeleton: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper_container}>
      <Skeleton width='100%' height='100%' isRounded className={styles.wrapper_image} />
    </div>

    <Skeleton width='80%' height='16px' />
  </div>
)

export default RoundedCardSkeleton
