import type { RootState } from 'types'

const orderSendState = (state: RootState) => state.order.orderSend
const orderListState = (state: RootState) => state.order.orderList
const orderCancelState = (state: RootState) => state.order.orderCancel
const orderDetailState = (state: RootState) => state.order.orderDetail
const orderedProductAddState = (state: RootState) => state.order.orderedProductAdd
const orderedProductDeleteState = (state: RootState) => state.order.orderedProductDelete

export const OrderSelectors = {
  orderSendState,
  orderListState,
  orderDetailState,
  orderCancelState,
  orderedProductAddState,
  orderedProductDeleteState,
}
