import { createAsyncThunk } from '@reduxjs/toolkit'

import api from 'libraries/axios'
import { handleErrorMessage } from 'utils'
import { showNotification, ToastVersions } from 'libraries/toastify'

import type { TOrderedListParams, TOrderProductEditForm, TOrderStoreFormData, TOrderUpdateForm } from './types'

export const addToOrder = createAsyncThunk('order/send', async (data: TOrderStoreFormData) => {
  try {
    const response = await api.post(`/v3/order`, data)

    return response
  } catch (err: any) {
    showNotification(ToastVersions.error, handleErrorMessage(err) || 'checkout_error')

    return err
  }
})

export const getOrderedProductsList = createAsyncThunk(
  'order/list',
  async ({ page, search, searchType = 'filter_receiver' }: TOrderedListParams) => {
    try {
      const response = await api.get(`/v2/order?page=${page || 1}`)

      if (search) {
        return await api.get(`/v2/order?page=${page || 1}&search=${search}&search_type=${searchType}`)
      }

      return response
    } catch (err: any) {
      return err
    }
  }
)

export const getOrderedProductDetails = createAsyncThunk('order/details', async (id: string | number) => {
  try {
    const response = await api.get(`/v1/order/${id}`)

    return response
  } catch (err: any) {
    return err
  }
})

export const updateOrderInfo = createAsyncThunk(
  'order/update-info',
  async ({ id, data }: { id: number; data: TOrderUpdateForm }) => {
    try {
      const response = await api.put(`/v1/order/${id}`, data)
      showNotification(ToastVersions.success, 'update_success')

      return response
    } catch (err: any) {
      showNotification(ToastVersions.error, handleErrorMessage(err?.response?.data?.errors) || 'update_error')

      return err
    }
  }
)

export const cancelOrdering = createAsyncThunk('order/cancel', async (id: string | number) => {
  try {
    const response = await api.put(`/v1/order/cancel/${id}`)

    showNotification(ToastVersions.success, 'order_canceled_successfully')

    return response
  } catch (err: any) {
    showNotification(ToastVersions.error, err?.response?.data || '')

    return err
  }
})

export const deleteOrderedProduct = createAsyncThunk(
  'order/product-delete',
  async ({ order_id, order_product_id }: Omit<TOrderProductEditForm, 'data'>) => {
    try {
      const response = await api.delete(`/v1/order/${order_id}/products/${order_product_id}`)

      return response
    } catch (err: any) {
      showNotification(ToastVersions.error, err?.response?.data?.message || '')

      return err
    }
  }
)

export const addOrderedProduct = createAsyncThunk(
  'order/product-add',
  async ({ order_id, order_product_id, data }: TOrderProductEditForm) => {
    try {
      const response = await api.post(`/v1/order/${order_id}/products/${order_product_id}`, data)

      return response
    } catch (err: any) {
      showNotification(
        ToastVersions.error,
        Object.keys(err?.response?.data)
          .map(key => err?.response?.data[key])
          .join(' ')
      )

      return err
    }
  }
)
