import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { useResponsive } from 'hooks'
import { Button, Text } from 'components'
import { CloseIcon, EmailOutlineIcon } from 'assets'

import type { TNeedHelp } from './types'
import styles from './NeedHelp.module.scss'
import { ERoutePaths } from 'libraries/router/types'

const NeedHelp: FC<TNeedHelp> = ({ onClose }) => {
  const { isTablet } = useResponsive()
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(ERoutePaths.Contacts)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_left}>
        <div className={styles.wrapper_icon_container}>
          <EmailOutlineIcon />
        </div>

        <div className={styles.wrapper_left_container}>
          <div className={styles.wrapper_title_container}>
            <Text className={styles.wrapper_title} text='need_quick_response' />
            <Text className={styles.wrapper_subtitle} text='contact_buyme_representative' />
          </div>

          {isTablet && <CloseIcon onClick={onClose} role='button' />}
        </div>
      </div>

      <div className={styles.wrapper_right}>
        <Button 
          size='medium' 
          variant='secondary' 
          className={styles.wrapper_button}
          onClick={handleNavigate}
        >
          contact_representative
        </Button>

        {!isTablet && <CloseIcon onClick={onClose} role='button' />}
      </div>
    </div>
  )
}

export default NeedHelp
