import { useCallback, useEffect, useMemo, useState, type FC } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { Text } from 'components'
import { CartSelectors } from 'store/cart/selectors'
import { useAppDispatch, useAppSelector } from 'hooks'
import { AddIcon, DeleteIcon, MinusIcon } from 'assets'
import { removeBasketItem, updateBasketQuantity } from 'store/cart/actions'
import { filterCartItemsById, filterCartItemsOptionsById } from 'store/cart'

import type { TCartItemProps } from './types'
import styles from './CartItem.module.scss'

const CartItem: FC<TCartItemProps> = ({ data, setTotalItems, setTotalPrice, setIsManuallyUpdated }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { loading: removeLoading } = useAppSelector(CartSelectors.removeBasketItemState)
  const { loading: updateLoading } = useAppSelector(CartSelectors.updateQuantityState)

  const initialQuantity = useMemo(
    () => data?.option?.map(({ id, quantity }) => ({ option_id: id, quantity })) || [],
    [data?.option]
  )

  const oneItemPrice = parseInt(data?.product?.special || data?.product.price || '0')

  const [quantity, setQuantity] = useState(initialQuantity)
  const [totalPrice, setLocalPrice] = useState(oneItemPrice * Number(data?.quantity))

  useEffect(() => {
    setQuantity(initialQuantity)
  }, [initialQuantity])

  const updateCartItem = useCallback(
    async (option_id: number, newQuantity: number) => {
      if (newQuantity < 0) return

      setIsManuallyUpdated(true)

      const result = await dispatch(updateBasketQuantity({ quantity: newQuantity, cartOptionId: option_id }))
      if (!result?.payload) return

      setQuantity(prev =>
        prev
          .map(item => (item.option_id === option_id ? { ...item, quantity: newQuantity } : item))
          .filter(item => item.quantity > 0)
      )

      const prevQuantity = quantity.find(q => q.option_id === option_id)?.quantity || 0
      let quantityChange = newQuantity - prevQuantity
      if (quantityChange < 0) quantityChange = 0

      setTotalItems(prev => Math.max(prev + quantityChange, 0))
      setTotalPrice(prev => Math.max(prev + quantityChange * oneItemPrice, 0))
      setLocalPrice(prev => Math.max(prev + quantityChange * oneItemPrice, 0))
    },
    [setIsManuallyUpdated, dispatch, quantity, setTotalItems, setTotalPrice, oneItemPrice]
  )

  const handleQuantityChange = (option_id: number, change: number) => {
    if (removeLoading || updateLoading) return

    const selectedOption = quantity.find(q => q.option_id === option_id)
    if (!selectedOption) return

    const newQuantity = selectedOption.quantity + change
    if (newQuantity < 1) return onDeleteItem(data?.option.length !== 1 ? option_id : undefined)

    updateCartItem(option_id, newQuantity)
  }

  const onDeleteItem = useCallback(
    async (option_id?: number) => {
      if (removeLoading || updateLoading) return

      setIsManuallyUpdated(true)

      if (option_id) {
        const optionToRemove = quantity.find(q => q.option_id === option_id)
        const result = await dispatch(updateBasketQuantity({ quantity: 0, cartOptionId: option_id }))
        if (!result?.payload) return
        if (!optionToRemove) return

        setTotalItems(prev => Math.max(prev - optionToRemove.quantity, 0))
        setTotalPrice(prev => Math.max(prev - optionToRemove.quantity * oneItemPrice, 0))
        setLocalPrice(prev => Math.max(prev - optionToRemove.quantity * oneItemPrice, 0))

        dispatch(filterCartItemsOptionsById(option_id))
      } else {
        const result: any = await dispatch(removeBasketItem(data?.cart_id))
        if (result?.payload?.total_format) {
          dispatch(filterCartItemsById(Number(data?.cart_id)))

          const totalQuantity = quantity.reduce((sum, item) => sum + item.quantity, 0)
          setTotalItems(prev => Math.max(prev - totalQuantity, 0))
          setTotalPrice(prev => Math.max(prev - totalPrice, 0))
        }
      }
    },
    [
      data?.cart_id,
      dispatch,
      oneItemPrice,
      quantity,
      removeLoading,
      setIsManuallyUpdated,
      setTotalItems,
      setTotalPrice,
      totalPrice,
      updateLoading,
    ]
  )

  const handleProductDetailsNavigation = () => {
    navigate(`/product-details/${data?.product_id}`)
  }

  return (
    <div className={styles.wrapper}>
      {data?.option?.map(({ id, warehouse_name, value, price, quantity: optionQuantity }, index) => (
        <div key={id} className={styles.wrapper_container}>
          <div role='button' onClick={handleProductDetailsNavigation} className={styles.wrapper_left}>
            <img
              width={197}
              height={236}
              alt='product-image'
              src={data?.product.thumb_large}
              className={styles.wrapper_left_image}
            />
          </div>

          <div className={styles.wrapper_right}>
            {index === 0 && (
              <div role='button' onClick={handleProductDetailsNavigation} className={styles.wrapper_title_container}>
                <Text
                  text={data?.product?.name || ''}
                  className={classNames(styles.wrapper_title, styles.wrapper_title_name)}
                />
                <Text text={`${totalPrice} грн.`} className={styles.wrapper_title} />
              </div>
            )}

            {optionQuantity > 0 ? (
              <div className={styles.wrapper_right_container}>
                <div className={styles.wrapper_description_container}>
                  <Text text='stock' RText={`: ${warehouse_name}`} className={styles.wrapper_description} />
                  <Text text='size' RText={`: ${value}`} className={styles.wrapper_description} />
                  <Text text='price_type' RText={`: ${price.priceTypeName}`} className={styles.wrapper_description} />
                </div>

                <div className={styles.wrapper_right_bottom}>
                  <div className={styles.wrapper_quantity_container}>
                    <div
                      role='button'
                      onClick={() => handleQuantityChange(id, -1)}
                      className={classNames(styles.wrapper_quantity, styles.wrapper_quantity_left)}
                    >
                      <MinusIcon />
                    </div>

                    <div className={classNames(styles.wrapper_quantity, styles.wrapper_quantity_center)}>
                      {quantity.find(q => q.option_id === id)?.quantity || optionQuantity}
                    </div>

                    <div
                      role='button'
                      onClick={() => handleQuantityChange(id, 1)}
                      className={classNames(styles.wrapper_quantity, styles.wrapper_quantity_right)}
                    >
                      <AddIcon />
                    </div>
                  </div>
                  <DeleteIcon role='button' onClick={() => onDeleteItem(data?.option.length !== 1 ? id : undefined)} />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  )
}

export default CartItem
