import { useCallback, useState, type FC } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { ORDER_STATUSES } from 'config/constant/helper'
import { cancelOrdering } from 'store/order/actions'
import { OrderSelectors } from 'store/order/selectors'
import { Button, CautionaryModal, OrderedProduct, Text } from 'components'
import { AlertCircleIcon, CheckCircleIcon, ContentCopyIcon } from 'assets'
import { useAppDispatch, useAppSelector, useCopyText } from 'hooks'

import type { TOrderTypeProps } from './types'
import styles from './OrderCard.module.scss'

const OrderCard: FC<TOrderTypeProps> = ({ item, onClick }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { loading: cancelLoading } = useAppSelector(OrderSelectors.orderCancelState)
  const { isCopied: isTtnCopied, handleCopyClickChange: handleTtnCopyClickChange } = useCopyText()
  const { isCopied: isIdCopied, handleCopyClickChange: handleIdCopyClickChange } = useCopyText()

  const [openModal, setModalOpen] = useState<boolean>(false)

  const renderOrderedProducts = item?.products.map((element, index) => (
    <OrderedProduct item={element} key={element.product_id + index} />
  ))

  const toggleModal = (val: boolean) => {
    setModalOpen(val)
  }

  const onCancelClick = useCallback(() => {
    dispatch(cancelOrdering(item.order_id)).then(() => {
      toggleModal(false)
    })
  }, [dispatch, item.order_id])

  const handleCopyClick = useCallback(
    (event: React.MouseEvent | undefined, copyText: string, handleCopy: (text: string) => void) => {
      event?.stopPropagation()
      handleCopy(copyText)
    },
    []
  )

  return (
    <div className={styles.wrapper}>
      <div role='button' onClick={onClick} className={styles.wrapper_container}>
        <div className={classNames(styles.wrapper_row, styles.wrapper_row_wrap)}>
          <div className={styles.wrapper_badge}>
            <div className={styles.wrapper_dot} />
            <Text text={item?.status} />
          </div>

          <p className={styles.wrapper_text}>{item?.date}</p>
        </div>

        <div className={styles.wrapper_column}>
          <div className={styles.wrapper_row}>
            <Text text='order_number' className={styles.wrapper_text} />
            <Text
              role='button'
              text={String(item?.order_id)}
              onClick={e => handleCopyClick(e, String(item?.order_id), handleIdCopyClickChange)}
              className={classNames(styles.wrapper_text_bold, { [styles.wrapper_text_copied]: isIdCopied })}
              RightIcon={isIdCopied ? CheckCircleIcon : ContentCopyIcon}
            />
          </div>

          {item?.ttn ? (
            <div className={styles.wrapper_row}>
              <Text text='ttn_order' className={styles.wrapper_text} />
              <Text
                role='button'
                text={item?.ttn}
                onClick={e => handleCopyClick(e, item?.ttn, handleTtnCopyClickChange)}
                className={classNames(styles.wrapper_text_bold, { [styles.wrapper_text_copied]: isTtnCopied })}
                RightIcon={isTtnCopied ? CheckCircleIcon : ContentCopyIcon}
              />
            </div>
          ) : null}
        </div>

        <div className={styles.wrapper_item_container}>{renderOrderedProducts}</div>

        <div className={styles.wrapper_column}>
          <div className={styles.wrapper_row}>
            <Text text='recipient' className={styles.wrapper_text} />
            <Text text={`${item?.first_name} ${item?.last_name}`} className={styles.wrapper_text_bold} />
          </div>

          <div className={styles.wrapper_row}>
            <Text text='phone_number' className={styles.wrapper_text} />
            <Text text={item?.telephone} className={styles.wrapper_text_bold} />
          </div>

          <div className={styles.wrapper_row}>
            <Text text='payment_method' className={styles.wrapper_text} />
            <Text text={item?.payment_method} className={styles.wrapper_text_bold} />
          </div>
        </div>
      </div>

      <div className={styles.wrapper_bottom}>
        <div className={styles.wrapper_row}>
          <Text text='total_price' className={styles.wrapper_text_bottom} />
          <Text text={item?.total_format} className={styles.wrapper_text_bottom} />
        </div>
        {!['bank_transfer', 'bank_transfer_cod', 'bank_transfer_commerce_cod', 'bank_transfer_required_cod'].includes(
          item.payment_code
        ) &&
        [ORDER_STATUSES.STATUS_NEW, ORDER_STATUSES.STATUS_PRINT].includes(item.order_status_id) &&
        ![ORDER_STATUSES.STATUS_CANCEL_TO_SEND].includes(item.order_status_id) ? (
          <div className={styles.wrapper_row}>
            <Button
              size='small'
              variant='secondary'
              disabled={cancelLoading}
              className={styles.wrapper_button}
              onClick={() => toggleModal(true)}
            >
              cancel
            </Button>

            {/* @TODO add edit functionality
              <Button className={styles.wrapper_button} size='small'>
                edit
              </Button> */}
          </div>
        ) : null}
      </div>

      <CautionaryModal
        title='cancel_order'
        showModal={openModal}
        Icon={AlertCircleIcon}
        loading={cancelLoading}
        saveButtonText='confirm'
        onSavePress={onCancelClick}
        cancelButtonText='cancel_action'
        closeModal={() => toggleModal(false)}
        subtitle={t('cancel_order_confirmation', { var: String(item?.order_id) })}
      />
    </div>
  )
}

export default OrderCard
