import * as yup from 'yup'

const addressPatterns = {
  bitcoin: /^(1|3|bc1)[a-zA-HJ-NP-Z0-9]{25,39}$/,
  ethereum: /^0x[a-fA-F0-9]{40}$/,
  solana: /^[1-9A-HJ-NP-Za-km-z]{32,44}$/,
  litecoin: /^(L|M|ltc1)[a-zA-HJ-NP-Z0-9]{25,39}$/,
  erc20: /^0x[a-fA-F0-9]{40}$/,
  trc20: /^T[a-zA-HJ-NP-Z0-9]{33}$/,
  omni: /^(1|3|bc1)[a-zA-HJ-NP-Z0-9]{25,39}$/,
}

export const updateUserInfoSchema = yup.object().shape({
  firstname: yup.string().required('first_name_required').min(2, 'first_name_min_length'),
  lastname: yup.string().required('last_name_required').min(2, 'last_name_min_length'),
  telephone: yup
    .string()
    .required('phone_required')
    .test('no-underscore', 'phone_incomplete', value => {
      return value ? !value.includes('_') : true
    }),
  middlename: yup.string().required('middle_name_required').min(2, 'middle_name_min_length'),
  email: yup
    .string()
    .required('emailRequired')
    .email('emailValidate')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, 'emailWithoutWhitespace'),
})

export const cryptoScheme = yup.object().shape({
  crypto_wallet: yup
    .string()
    .required('crypto_wallet_required')
    .test('is-valid-usdt', 'invalid_address', value => {
      if (!value) return false

      return Object.values(addressPatterns).some(regex => regex.test(value))
    }),
})

export const updateUserPaymentSchema = yup.object().shape({
  card_fio: yup.string().required('card_fio_required').min(5, 'card_fio_min_length'),

  ederpou: yup
    .string()
    .required('ederpou_required')
    .min(8, 'ederpou_min_length')
    .matches(/^\d{8}$/, 'ederpou_invalid')
    .test('is-valid-edrpou', 'ederpou_invalid', value => {
      if (!value) return false

      const digits = value.split('').map(Number)
      const weights = [1, 2, 3, 4, 5, 6, 7]

      let sum = weights.reduce((acc, weight, index) => acc + weight * digits[index], 0)
      let controlDigit = sum % 11

      if (controlDigit === 10) {
        sum = weights.map(w => (w + 2) % 10).reduce((acc, weight, index) => acc + weight * digits[index], 0)
        controlDigit = sum % 11
      }

      return controlDigit === digits[7]
    }),

  iban: yup
    .string()
    .required('iban_required')
    .matches(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/, 'iban_invalid_format'),
})
