import type { FC } from 'react'
import classNames from 'classnames'

import { CheckIcon } from 'assets'

import styles from './Select.module.scss'

type TSelectMenuItem = {
  flag?: string
  countryName: string
  isSelected: boolean
  prefix: string
  handleSelect: (item: any) => void
}

const SelectMenuItem: FC<TSelectMenuItem> = ({ isSelected, flag, countryName, prefix, handleSelect }) => (
  <div
    role='button'
    onClick={handleSelect}
    className={classNames(styles.wrapper__menu__item, { [styles.wrapper__menu__item__selected]: isSelected })}
  >
    <div className={styles.wrapper__menu__item__container}>
      <img src={flag || ''} alt='flag' className={styles.wrapper__flag} width={24} height={24} />

      <p>{countryName}</p>

      {prefix && <p className={styles.wrapper__menu__item__prefix}>{prefix}</p>}
    </div>

    {isSelected && <CheckIcon className={styles.wrapper__menu__item__icon} />}
  </div>
)

export default SelectMenuItem
