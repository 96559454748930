import { useMemo, useState, type FC } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useResponsive } from 'hooks'
import { Button, Input } from 'components'
import { SearchIcon, SmallLogo } from 'assets'
import { ERoutePaths } from 'libraries/router/types'

import styles from './Search.module.scss'

const Search: FC = () => {
  const navigate = useNavigate()
  const { isTabletLarge } = useResponsive()
  const [searchParams] = useSearchParams()
  const getParamsSearch = useMemo(() => searchParams.get('search'), [searchParams])
  const [searchQuery, setSearchQuery] = useState<string>(getParamsSearch || '')

  const handleProductSearch = () => {
    if (searchQuery.trim()) {
      setSearchQuery('')
      navigate(`/products?search=${encodeURIComponent(searchQuery)}`)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleProductSearch()
    }
  }

  const handleHomeNavigation = () => {
    navigate(ERoutePaths.Home)
  }

  return (
    <div className={styles.wrapper}>
      <Input
        value={searchQuery}
        isFillIgnore={isTabletLarge}
        onKeyDown={handleKeyDown}
        placeholder='search_placeholder'
        className={styles.wrapper_input}
        Icon={!isTabletLarge ? SearchIcon : undefined}
        onChange={e => setSearchQuery(e.target.value)}
        LeftComponent={
          isTabletLarge ? (
            <SmallLogo className={styles.wrapper_icon} role='button' onClick={handleHomeNavigation} />
          ) : null
        }
      />

      <Button
        size='medium'
        onClick={handleProductSearch}
        className={styles.wrapper_button}
        LeftIcon={isTabletLarge ? SearchIcon : undefined}
      >
        {isTabletLarge ? null : 'search_products'}
      </Button>
    </div>
  )
}

export default Search
