import { ERoutePaths } from 'libraries/router/types'

import { CartOutlineIcon, HeartOutlineIcon, UserOutlineIcon } from 'assets'

export const navigationTabs = [
  {
    id: 1,
    text: 'cabinet',
    icon: UserOutlineIcon,
  },

  {
    id: 2,
    nav: ERoutePaths.Wishlist,
    text: 'favorites',
    icon: HeartOutlineIcon,
  },

  {
    id: 3,
    text: 'cart',
    nav: ERoutePaths.Cart,
    icon: CartOutlineIcon,
  },
]
