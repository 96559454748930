import { Fragment, useCallback, useRef, type FC } from 'react'
import classNames from 'classnames'
import { motion } from 'motion/react'
import { Link, useLocation } from 'react-router-dom'

import { getUserToken } from 'utils'
import { ERoutePaths } from 'libraries/router/types'
import { ListBulletedIcon, PrimaryLogo } from 'assets'
import { GlobalSelectors } from 'store/global/selectors'
import { toggleCatalogOpen, toggleAuthModalOpen } from 'store/global'
import { useAppDispatch, useAppSelector, useResponsive } from 'hooks'
import { AuthModal, Catalog, NavigationTab, Search, Text } from 'components'

import { navigationTabs } from './utils'
import styles from './Header.module.scss'

const Header: FC = () => {
  const dispatch = useAppDispatch()
  const userToken = getUserToken()
  const { pathname } = useLocation()
  const { isTabletSmall } = useResponsive()
  const buttonRef = useRef<HTMLButtonElement>(null)

  const isCatalogOpen = useAppSelector(GlobalSelectors.isCatalogOpen)
  const isAuthModalOpen = useAppSelector(GlobalSelectors.isAuthModalOpen)

  const toggleCatalog = useCallback(() => {
    dispatch(toggleCatalogOpen(!isCatalogOpen))
  }, [dispatch, isCatalogOpen])

  const toggleAuthModal = useCallback(() => {
    dispatch(toggleAuthModalOpen(!isAuthModalOpen))
  }, [dispatch, isAuthModalOpen])

  const renderTabs = navigationTabs.map(({ nav, id, icon, text }) => (
    <Fragment key={id}>
      {nav && userToken ? (
        <Link to={nav}>
          <NavigationTab
            Icon={icon}
            text={text}
            className={classNames(styles.wrapper_tab, {
              [styles.wrapper_tab_active]: pathname.includes(String(nav)),
            })}
          />
        </Link>
      ) : (
        <div role='button' onClick={toggleAuthModal}>
          <NavigationTab
            Icon={icon}
            text={text}
            className={classNames(styles.wrapper_tab, { [styles.wrapper_tab_active]: isAuthModalOpen })}
          />
        </div>
      )}
    </Fragment>
  ))

  return (
    <Fragment>
      <motion.header
        className={styles.wrapper}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <div className={styles.wrapper_header}>
          <Text text='discount_first_order' />
        </div>

        <nav className={styles.wrapper_nav}>
          {!isTabletSmall ? (
            <Link to={ERoutePaths.Home}>
              <PrimaryLogo className={styles.wrapper_logo} />
            </Link>
          ) : null}

          <div className={styles.wrapper_center}>
            {!isTabletSmall ? (
              <button ref={buttonRef} onClick={toggleCatalog} className={styles.wrapper_button}>
                <ListBulletedIcon /> <Text emptyTag text='catalog' />
              </button>
            ) : null}

            <Search />
          </div>

          {!isTabletSmall ? <div className={styles.wrapper_right}>{renderTabs}</div> : null}
        </nav>
      </motion.header>

      <Catalog buttonRef={buttonRef} />

      <AuthModal />
    </Fragment>
  )
}

export default Header
