import { useEffect, RefObject } from 'react'

const useOutsideClick = (refs: RefObject<HTMLElement>[], handler: (event: MouseEvent | TouchEvent) => void) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // If any ref contains the event target, do nothing
      if (refs.some(ref => ref?.current?.contains(event.target as Node))) {
        return
      }
      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [refs, handler])
}

export default useOutsideClick
