export const languagesDropdownUtils = [
  {
    lang: 'uk',
    language_id: 2,
    text: 'Українська',
  },
  {
    lang: 'ru',
    language_id: 1,
    text: 'Русский',
  },
]
