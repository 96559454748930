import { type FC } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { motion } from 'motion/react'

import { Text } from 'components'
import { ERoutePaths } from 'libraries/router/types'
import { appStoreLink, googlePlayLink } from 'utils'
import { AppStoreBadge, PlayMarketBadge, SecondaryLogo } from 'assets'

import styles from './Footer.module.scss'

const Footer: FC = () => {
  //@TODO maybe will add in future
  // const renderSocialLinks = socialMedias.map(element => (
  //   <Link className={styles.wrapper_link} key={element.id} to={element.nav}>
  //     <element.icon />
  //   </Link>
  // ))

  return (
    <motion.footer
      className={styles.wrapper}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
    >
      <div className={styles.wrapper_between_container}>
        <div className={styles.wrapper_column_container}>
          <SecondaryLogo className={styles.wrapper_logo} />

          <Text className={classNames(styles.wrapper_text, styles.wrapper_description)} text='slogan' />

          <div className={styles.wrapper_links_container}>
            <div className={styles.wrapper_row_container}>
              <Link to={ERoutePaths.CategoriesMain} className={classNames(styles.wrapper_text, styles.wrapper_link)}>
                <Text emptyTag text='catalog' />
              </Link>
              <Link to={ERoutePaths.Contacts} className={classNames(styles.wrapper_text, styles.wrapper_link)}>
                <Text emptyTag text='contacts' />
              </Link>
              <Link to={ERoutePaths.FAQ} className={classNames(styles.wrapper_text, styles.wrapper_link)}>
                <Text emptyTag text='faqs' />
              </Link>
            </div>

            <div className={styles.wrapper_row_container}>
              <Link to={ERoutePaths.PrivacyPolicy} className={classNames(styles.wrapper_text, styles.wrapper_link)}>
                <Text emptyTag text='terms_and_conditions' />
              </Link>

              <Link to={ERoutePaths.PrivacyPolicy} className={classNames(styles.wrapper_text, styles.wrapper_link)}>
                <Text emptyTag text='privacy_policy' />
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.wrapper_right}>
          <Text className={styles.wrapper_text} text='download_app' />

          <div className={styles.wrapper_badges}>
            <a href={appStoreLink} target='_blank'>
              <AppStoreBadge />
            </a>

            <a href={googlePlayLink} target='_blank'>
              <PlayMarketBadge />
            </a>
          </div>
        </div>
      </div>

      <div className={styles.wrapper_bottom}>
        <Text className={classNames(styles.wrapper_bottom_copyright, styles.wrapper_text)} text='copyright' />

        {/* @TODO maybe will add in future */}
        {/* <div className={styles.wrapper_bottom_social}>{renderSocialLinks}</div> */}
      </div>
    </motion.footer>
  )
}

export default Footer
