import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'

import type { TEmptyStateProps } from './types'
import styles from './EmptyState.module.scss'

const EmptyState: FC<TEmptyStateProps> = ({ image, title, titleVar, subtitle, Button, className }) => (
  <div className={classNames(styles.wrapper, className)}>
    <div className={styles.wrapper_container}>
      <img src={image} alt='empty_state_image' className={styles.wrapper_image} />

      <div className={styles.wrapper_title_container}>
        {title && <Text variable={titleVar} className={styles.wrapper_title} text={title} />}
        {subtitle && <Text className={styles.wrapper_subtitle} text={subtitle} />}
      </div>

      {Button && Button}
    </div>
  </div>
)

export default EmptyState
