import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState, type FC } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'motion/react'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import { Input, Loader, Skeleton, Text } from 'components'
import { CheckCircleIcon, ChevronDownIcon, SearchIcon } from 'assets'
import { useAppDispatch, useAppSelector, useDebounce, useOnClickOutside } from 'hooks'

import { formatData, getData, getSelector } from './utils'
import type { TSelectDataItem, TSelectOption, TSelectorInitial } from './types'
import styles from './SelectOption.module.scss'

const SelectOption: FC<TSelectOption> = ({
  name,
  error,
  label,
  hint,
  search,
  loading,
  onChange,
  register,
  disabled,
  selectApi,
  data = [],
  setValue,
  className,
  city_ref,
  itemToSet,
  placeholder,
  defaultValue,
  inputClassName,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    data: apiData,
    meta: apiMeta,
    loading: apiLoading,
  } = useAppSelector(getSelector(selectApi)) as TSelectorInitial

  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedValue, setSelectedValue] = useState<TSelectDataItem | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(apiMeta?.current_page || 1)

  const conditionalData = useMemo(() => {
    return selectApi && apiData ? formatData(apiData || apiData?.data, selectApi) : data
  }, [selectApi, apiData, data])

  const findDefaultValue = useMemo(
    () => conditionalData?.find(item => Object.values(item)?.some(val => val === defaultValue)),
    [conditionalData, defaultValue]
  )

  const defaultOrSelected = useMemo(() => selectedValue || findDefaultValue, [findDefaultValue, selectedValue])
  const debouncedSearch = useDebounce(searchValue, 600)
  const hasNextPage = apiMeta?.current_page !== apiMeta?.last_page

  const toggleShowMenu = useCallback(() => {
    if (!loading && !apiLoading && conditionalData && !disabled) {
      setShowMenu(!showMenu)
    }
  }, [apiLoading, conditionalData, disabled, loading, showMenu])

  const handleSelect = useCallback(
    (item: any) => {
      if (!disabled) {
        setSelectedValue(item)
        onChange?.(item)
        toggleShowMenu()
        setSearchValue('')
        setValue?.(name, item[itemToSet || '_id'])
      }
    },
    [disabled, itemToSet, name, onChange, setValue, toggleShowMenu]
  )

  const closeMenu = () => {
    setShowMenu(false)
  }

  useOnClickOutside([menuRef], closeMenu)

  const renderComponent = () => {
    const isLanguageSelect = selectApi === 'languages'
    const containerClass = classNames(styles.wrapper_input_icon, {
      [styles.wrapper_input_icon_error]: error,
      [styles.wrapper_input_flag]: isLanguageSelect,
    })

    return (
      <div className={containerClass}>
        {isLanguageSelect &&
          (defaultOrSelected?.flag ? (
            <img src={defaultOrSelected.flag} alt='flag' className={styles.wrapper_flag} width={24} height={24} />
          ) : (
            <Skeleton isRounded width='24px' height='24px' />
          ))}
        <ChevronDownIcon />
      </div>
    )
  }

  const renderSelectItem = conditionalData?.map(element => {
    const isActive = selectedValue?._id ? selectedValue?._id === element._id : findDefaultValue?._id === element._id

    return (
      <div
        role='button'
        key={element._id}
        onClick={() => handleSelect(element)}
        className={styles.wrapper_menu_item_container}
      >
        {element?.flag && <img src={element?.flag} alt='flag' className={styles.wrapper_flag} width={24} height={24} />}

        <Text
          text={element.name}
          RightIcon={isActive ? CheckCircleIcon : null}
          className={classNames(styles.wrapper_menu_item, { [styles.wrapper_menu_item_selected]: isActive })}
        />
      </div>
    )
  })

  const handleLoadMore = useCallback(() => {
    setCurrentPage(prev => prev + 1)
  }, [])

  const [sentryRef] = useInfiniteScroll({
    hasNextPage,
    loading: apiLoading,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 20px 0px',
  })

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event?.target?.value)

    setTimeout(() => setCurrentPage(1), 600)
  }

  useEffect(() => {
    if (selectApi && !disabled) {
      const action = getData(selectApi, currentPage, { search: debouncedSearch as string, city_ref })

      if (action) {
        dispatch<any>(action)
      }
    }
  }, [disabled, dispatch, debouncedSearch, selectApi, currentPage, city_ref])

  return (
    <div ref={menuRef} className={classNames(styles.wrapper, className)}>
      <div onClick={toggleShowMenu} className={styles.wrapper_input_container}>
        <Input
          readOnly
          hint={hint}
          error={error}
          label={label}
          register={register}
          disabled={disabled}
          placeholder={placeholder}
          LeftComponent={renderComponent()}
          value={t(defaultOrSelected?.name) || ''}
          className={classNames(styles.wrapper_input, inputClassName, {
            [styles.wrapper_input_error]: error,
            [styles.wrapper_input_lang]: defaultOrSelected?.flag,
          })}
        />
      </div>

      <AnimatePresence>
        {showMenu && (
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className={classNames(styles.wrapper_menu, { [styles.wrapper_menu_low]: label })}
          >
            {search && (
              <Input
                type='text'
                name='search'
                Icon={SearchIcon}
                isDirty={false}
                value={searchValue}
                placeholder='search'
                className={styles.wrapper_search}
                onChange={onSearchChange}
              />
            )}
            <div className={styles.wrapper_menu_container}>
              {renderSelectItem}

              {conditionalData?.length === 0 && !apiLoading && !loading && (
                <Text text='no_data_found' className={styles.wrapper_empty} />
              )}

              {(apiLoading || hasNextPage) && (
                <div ref={sentryRef} className={styles.wrapper_loader_container}>
                  <Loader size={16} color='#ab0000' />
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default SelectOption
