import { type LegacyRef, useState, useEffect, useRef, forwardRef } from 'react'
import { noop } from 'lodash'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

import { Text } from 'components'

import type { TTabsProps } from './types'
import styles from './Tabs.module.scss'

const Tabs = forwardRef(
  (
    { tabs, children, active, setActive, onClick = noop, className = '', tabsClassName = '' }: TTabsProps,
    ref: LegacyRef<HTMLDivElement>
  ) => {
    const tabContainerRef = useRef<HTMLDivElement | null>(null)
    const [tabsWidth, setTabsWidth] = useState<number[]>([])
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(active || tabs[0].id)

    useEffect(() => {
      if (tabContainerRef.current) {
        const childrenWidths = Array.from(tabContainerRef.current.children)
          .slice(0, tabContainerRef.current.children.length - 1)
          .map(child => child.clientWidth)

        setTabsWidth(childrenWidths)
      }
    }, [tabContainerRef])

    const activeIndex = (active || active === 0) && setActive ? active : currentTabIndex
    const lineWidthValue = tabsWidth[activeIndex] || 0
    const lineLeftValueCalc = tabsWidth.slice(0, activeIndex).reduce((sum, width) => sum + width, 0)
    const isActive = (id: number) => active === id || (!active && !setActive && currentTabIndex === id)

    const clickHandler = (id: number) => {
      setActive?.(id) || setCurrentTabIndex(id)
      onClick?.(id)

      if (tabContainerRef.current) {
        if (id > activeIndex) {
          tabContainerRef.current.scrollLeft += lineWidthValue
        }

        if (id < activeIndex) {
          tabContainerRef.current.scrollLeft -= lineWidthValue
        }
      }
    }

    const renderTabs = tabs.map(({ id, text, Icon, count }) => (
      <div
        key={id}
        role='button'
        onClick={() => clickHandler(id)}
        className={classNames(styles.wrapper__tabs__tab__container, {
          [styles.wrapper__tabs__tab__container__active]: isActive(id),
        })}
      >
        <Text
          text={text}
          LeftIcon={Icon}
          className={classNames(styles.wrapper__tabs__tab, {
            [styles.wrapper__tabs__tab__active]: isActive(id),
          })}
        />

        {count ? <span className={styles.wrapper__tabs__tab__count}>{count}</span> : null}
      </div>
    ))

    return (
      <>
        <div ref={ref} className={classNames(styles.wrapper, { [className]: className })}>
          <div className={classNames(styles.wrapper__tabs, { [tabsClassName]: tabsClassName })} ref={tabContainerRef}>
            {renderTabs}

            <span
              style={{
                left: `${lineLeftValueCalc}px`,
                width: `${lineWidthValue}px`,
              }}
              className={styles.wrapper__line}
            />
          </div>

          <span className={styles.wrapper__divider} />

          {children ? children : null}
        </div>

        <AnimatePresence mode='wait'>
          {tabs.map(
            ({ id, ContentComponents }) =>
              isActive(id) && (
                <motion.div
                  key={id}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3 }}
                >
                  {ContentComponents}
                </motion.div>
              )
          )}
        </AnimatePresence>
      </>
    )
  }
)

export default Tabs
