import type { useStateType } from 'types'

export enum EModalState {
  Login = 'login',
  Confirm = 'confirm',
  Register = 'register',
  Cabinet = 'cabinet',
}

export type TAuthComponentsProps = {
  modalState: EModalState
  setModalState: useStateType<EModalState>
}

export type TLoginProps = {
  isWithPassword?: boolean
} & TAuthComponentsProps
