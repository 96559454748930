import { useState, useEffect, useCallback } from 'react'
import Pusher from 'pusher-js'

import { WEBSOCKET } from 'config/endpoint'
import { getUserData, getUserToken } from 'utils'
import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from 'config/constant'

const usePusherChannel = (channelPath: string, mustBeSigned: boolean, disabled?: boolean) => {
  const [pusherChannel, setPusherChannel] = useState<any>()

  const user = getUserData()
  const token = getUserToken()

  const subscribeToPusher = useCallback(async () => {
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CLUSTER,
      authEndpoint: WEBSOCKET.AUTH,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    })

    const channel = pusher.subscribe(channelPath)
    setPusherChannel(channel)
  }, [channelPath, token])

  useEffect(() => {
    if (disabled) return

    const shouldSubscribe = !mustBeSigned || (mustBeSigned && token && user?.customer_id)

    if (shouldSubscribe) {
      subscribeToPusher()
    }
  }, [user?.customer_id, mustBeSigned, token, subscribeToPusher, disabled])

  return { pusherChannel }
}

export default usePusherChannel
