import { Fragment, type FC } from 'react'

import { Skeleton } from 'components'

import type { TSkeleton } from '../types'
import styles from './CartItemSkeleton.module.scss'

const CartItemSkeleton: FC<TSkeleton> = ({ skeletonCount = 1 }) => (
  <Fragment>
    {Array.from({ length: skeletonCount }).map((_, index) => (
      <div key={index} className={styles.wrapper}>
        <div className={styles.wrapper_left}>
          <Skeleton width='197px' height='236px' className={styles.wrapper_left_image} />
        </div>

        <div className={styles.wrapper_right}>
          <div className={styles.wrapper_title_container}>
            <Skeleton width='100%' height='24px' className={styles.wrapper_title} />
            <Skeleton width='20%' height='24px' className={styles.wrapper_title} />
          </div>

          <div className={styles.wrapper_right_container}>
            <div className={styles.wrapper_description_container}>
              <Skeleton width='100px' height='20px' className={styles.wrapper_description} />
              <Skeleton width='100px' height='20px' className={styles.wrapper_description} />
              <Skeleton width='100px' height='20px' className={styles.wrapper_description} />
            </div>

            <div className={styles.wrapper_right_bottom}>
              <div className={styles.wrapper_quantity_container}>
                <Skeleton width='30px' height='30px' className={styles.wrapper_quantity} />
                <Skeleton width='30px' height='30px' className={styles.wrapper_quantity} />
                <Skeleton width='30px' height='30px' className={styles.wrapper_quantity} />
              </div>
              <Skeleton width='24px' height='24px' className={styles.delete_icon} />
            </div>
          </div>
        </div>
      </div>
    ))}
  </Fragment>
)

export default CartItemSkeleton
