import {
  ShopIcon,
  LogoutIcon,
  QrCodeIcon,
  WalletIcon,
  SupportIcon,
  HistoryIcon,
  FileMoveIcon,
  SettingsIcon,
  ChatBubbleIcon,
  ReceiptTextIcon,
  AddShoppingCartIcon,
  NotificationsNoneIcon,
  ShoppingCartArrowDownIcon,
} from 'assets'

import { ERoutePaths } from 'libraries/router/types'
import { CUSTOMER_GROUPS } from 'config/constant/helper'

export const cabinetRoutes = [
  {
    id: 1,
    Icon: HistoryIcon,
    text: 'my_orders',
    nav: ERoutePaths.MyOrders,
  },
  {
    id: 2,
    Icon: WalletIcon,
    text: 'balance',
    group: [
      CUSTOMER_GROUPS.CUSTOMER_GROUP_PARTNER,
      CUSTOMER_GROUPS.CUSTOMER_GROUP_WHOLESALE,
      CUSTOMER_GROUPS.CUSTOMER_GROUP_DROPSHIPPING,
      CUSTOMER_GROUPS.CUSTOMER_GROUP_MANAGER,
      CUSTOMER_GROUPS.CUSTOMER_GROUP_DROP_MANAGER,
      CUSTOMER_GROUPS.CUSTOMER_GROUP_PROVIDER,
    ],
  },

  {
    id: 15,
    Icon: AddShoppingCartIcon,
    text: 'add_product',
    group: [CUSTOMER_GROUPS.CUSTOMER_GROUP_COURIER],
  },

  {
    id: 9,
    Icon: ShoppingCartArrowDownIcon,
    text: 'buyout_goods',
    group: [CUSTOMER_GROUPS.CUSTOMER_GROUP_STORE_SELLER, CUSTOMER_GROUPS.CUSTOMER_GROUP_COURIER],
  },

  {
    id: 3,
    Icon: ShopIcon,
    text: 'my_stores',
    line: true,
  },
  {
    id: 4,
    Icon: NotificationsNoneIcon,
    text: 'notifications',
  },

  {
    id: 5,
    Icon: FileMoveIcon,
    text: 'my_exports',
  },
  {
    id: 6,
    Icon: ChatBubbleIcon,
    text: 'chats',
    line: true,
  },
  {
    id: 7,
    Icon: SettingsIcon,
    text: 'settings',
    nav: ERoutePaths.Settings,
  },

  {
    id: 8,
    Icon: SupportIcon,
    text: 'support_service',
    line: true,
    nav: ERoutePaths.Contacts,
  },

  {
    id: 10,
    Icon: QrCodeIcon,
    text: 'qr_scanner',
    warning: true,
    group: [
      CUSTOMER_GROUPS.CUSTOMER_GROUP_COURIER,
      CUSTOMER_GROUPS.CUSTOMER_GROUP_PACKER,
      CUSTOMER_GROUPS.CUSTOMER_GROUP_SUPER_COURIER,
    ],
  },

  {
    id: 11,
    Icon: ReceiptTextIcon,
    text: 'recalculation',
    line: true,
    group: [
      CUSTOMER_GROUPS.CUSTOMER_GROUP_COURIER,
      CUSTOMER_GROUPS.CUSTOMER_GROUP_PACKER,
      CUSTOMER_GROUPS.CUSTOMER_GROUP_SUPER_COURIER,
    ],
  },

  {
    id: 12,
    Icon: LogoutIcon,
    text: 'logout',
    line: true,
  },
]
