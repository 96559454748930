import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'

import type { TAdvantageCardProps } from './types'
import styles from './AdvantageCard.module.scss'

const AdvantageCard: FC<TAdvantageCardProps> = ({ Icon, title, subtitle, className }) => (
  <div className={classNames(styles.wrapper, className)}>
    <div className={styles.wrapper_icon}>
      <Icon />
    </div>

    <div className={styles.wrapper_container}>
      <Text className={styles.wrapper_title} text={title} />
      <Text className={styles.wrapper_subtitle} text={subtitle} />
    </div>
  </div>
)

export default AdvantageCard
