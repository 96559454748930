import { ChangeEvent, useEffect, useRef, useState, type FC } from 'react'
import { noop } from 'lodash'
import classNames from 'classnames'

import { SearchIcon, SearchNotFoundImage } from 'assets'
import type { TQrSearchItem } from 'store/products/types'
import { ProductsSelectors } from 'store/products/selectors'
import { getProductsByQrSearch } from 'store/products/action'
import { Input, OrderedProduct, Skeleton, Text } from 'components'
import { useAppDispatch, useAppSelector, useDebounce } from 'hooks'
import { addOrderedProduct, deleteOrderedProduct } from 'store/order/actions'

import type { TOrderDetailsStateProps } from './types'
import styles from './OrderDetails.module.scss'

const ProductEdit: FC<TOrderDetailsStateProps> = ({ item }) => {
  const dispatch = useAppDispatch()
  const hasSearched = useRef(false)
  const { data, loading } = useAppSelector(ProductsSelectors.qrSearch)

  const [searchValue, setSearchValue] = useState<string>('')

  const debouncedSearch = useDebounce(searchValue, 600)

  const onOrderProductDeleteClick = (order_product_id: number) => {
    const params = {
      order_id: item?.order_id || 0,
      order_product_id,
    }

    dispatch(deleteOrderedProduct(params))
  }

  const onOrderProductAddClick = (product: TQrSearchItem) => {
    const params = {
      order_id: item?.order_id || 0,
      order_product_id: product.product_id,
      data: {
        option_value_id: product?.option_value_id,
        warehouse_id: product?.warehouse_id,
      },
    }

    dispatch(addOrderedProduct(params))
  }

  const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const renderOrderedProducts = item?.products.map(element => (
    <OrderedProduct
      onClick={noop}
      item={element}
      key={element.product_id}
      onClose={onOrderProductDeleteClick}
      isTheLastItem={item?.products.length === 1}
    />
  ))

  const renderSearchProducts = data?.map((element, index) => (
    <OrderedProduct
      addIcon
      item={element}
      onClick={onOrderProductAddClick}
      className={styles.wrapper_products}
      key={element.product_id + index}
    />
  ))

  const renderSkeleton = new Array(5).fill('').map((_, index) => <Skeleton key={index} width='100%' height='82px' />)

  useEffect(() => {
    if (hasSearched.current) {
      dispatch(getProductsByQrSearch({ search: debouncedSearch as string }))
    } else {
      hasSearched.current = true
    }
  }, [debouncedSearch, dispatch])

  return (
    <div className={styles.edit}>
      <div className={classNames(styles.edit_head, styles.wrapper_products_head)}>
        <Text text='order_number_var' variable={{ var: item?.order_id }} className={styles.wrapper_text_bold} />

        <div className={styles.wrapper_item_container}>{renderOrderedProducts}</div>
      </div>

      <div className={styles.edit_container}>
        <Input
          Icon={SearchIcon}
          value={searchValue}
          placeholder='enter_product_sku'
          onChange={e => onSearchValueChange(e)}
        />
      </div>

      <div className={styles.wrapper_products_container}>
        {loading ? (
          renderSkeleton
        ) : !data?.length && debouncedSearch ? (
          <img
            alt='search'
            width={56}
            height={56}
            src={SearchNotFoundImage}
            className={styles.wrapper_products_image}
          />
        ) : (
          renderSearchProducts
        )}
      </div>
    </div>
  )
}

export default ProductEdit
