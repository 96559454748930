import type { FC } from 'react'

import { Text } from 'components'

import type { TToastTextsProps } from './types'
import styles from './Toast.module.scss'

const ToastTexts: FC<TToastTextsProps> = ({ color, title, description }) => (
  <div className={styles.container}>
    <p style={{ color: color }} className={styles.container__title}>
      <Text emptyTag text={title} />
    </p>
    {description && <Text tagName='span' className={styles.container__description} text={description} />}
  </div>
)

export default ToastTexts
