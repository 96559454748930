import { API_VERSION_V1, API_VERSION_V2, API_VERSION_V3, getEnvVars } from './constant'

const { apiUrl } = getEnvVars()

const getPath = (endpoint: string, version: string = API_VERSION_V1) => {
  return `${apiUrl}api/${version}/${endpoint}`
}

export const WEBSOCKET = {
  AUTH: apiUrl + 'api/broadcasting/auth',
}

export const AUTH = {
  LOGIN: getPath('auth/login', API_VERSION_V2),
  REGISTER: getPath('auth/register', API_VERSION_V3),
  FORGOTTEN: getPath('auth/forgotten', API_VERSION_V2),
  FORGOTTEN_CONFIRM: getPath('auth/forgotten-confirm', API_VERSION_V2),
  SETTINGS: getPath('configurable-settings'),
}

export const USER = {
  CURRENT: getPath('customer', API_VERSION_V2),
  SAVE_SETTINGS: getPath('customer/save-settings', API_VERSION_V2),
  CHANGE_LANG: getPath('customer/change-lang', API_VERSION_V2),
  ALL: getPath('customers'),
  SAVE_CARD: getPath('customers/save-card'),
  SAVE_PASSWORD: getPath('customers/save-password'),
  ADD_TOKEN: getPath('customers/token'),
  CHANGE_PASSWORD: getPath('users/change-password'),
  CHANGE_AVATAR: getPath('users/change-avatar'),
  BALANCE: getPath('customers/balance'),
  ARCHIVES: getPath('archive'),
  NOTIFICATION: getPath('customers/notification'),
  CUSTOMER_GROUP: getPath('customer-groups'),
  INVOICES: getPath('customers/invoices'),
  UP_BALANCE: getPath('customers/up-balance'),
}

export const LOCALISATION = {
  COUNTRIES: getPath('countries', API_VERSION_V2),
  CITIES: getPath('localisation/cities', API_VERSION_V2),
  WAREHOUSES: getPath('localisation/warehouses'),
  ADDRESS: getPath('localisation/streets'),
  LANGUAGES: getPath('languages', API_VERSION_V2),
}

export const INFORMATION = {
  ALL: getPath('information'),
  TOP: getPath('information/top'),
}

export const PRODUCT = {
  ALL: getPath('products'),
  PRODUCTS: getPath('products', API_VERSION_V2),
  SEARCH: getPath('products/search'),
  QR: getPath('products/qr-search'),
  LATEST: getPath('products/latest'),
  SPECIAL: getPath('products/special'),
  BEST_SELLERS: getPath('products/best-sellers', API_VERSION_V2),
  WISHLIST: getPath('products/wishlist'),
  IMAGES: getPath('products/gallery'),
  REVIEWS: getPath('products/reviews'),
  SOCIAL_NUMBER_CONFIRM: getPath('products/social'),
  RE_DISCOUNT: getPath('products/rediscount'),
  SUBSCRIBE: getPath('products/subscribe'),
  RETURN: getPath('products/return'),
  SOCIAL: getPath('products/social'),
  SHELVINGS: getPath('products/shelvings', API_VERSION_V2),
  INCREMENT_QUANTITY: getPath('shelvings/increment-quantity'),
  CHECK_SHELVINGS: getPath('shelvings/'),
}

export const CATEGORY = {
  ALL: getPath('categories'),
  STORE: getPath('categories?filter_prepared_for_site=1'),
  FILTERS: getPath('categories/filters'),
  FILTER_OPTIONS: getPath('categories/filter-options', API_VERSION_V2),
  FILTER_OPTION_VALUES: getPath('categories/filter-options/%s/option-values', API_VERSION_V2),
  ROOT_PARENTS: getPath('categories/root-parents'),
}

export const NOTIFICATION = {
  PATH: getPath('notifications'),
  NEW: getPath('notifications/new'),
  READ: getPath('notifications/read'),
}

export const CART = {
  LIST: getPath('cart', API_VERSION_V3),
  ACTION: getPath('cart/%s', API_VERSION_V3),
}
export const CART_OPTIONS = {
  ACTION: `${getPath('cart-option/%s', API_VERSION_V3)}`,
  CALCULATE_CART_TOTAL: getPath('cart-option/%s/calculate-total', API_VERSION_V3),
}
export const ORDER = {
  // V3
  ACTION: getPath('order', API_VERSION_V3),

  // V2

  PAYMENT_METHODS: getPath('orders/payment-methods', API_VERSION_V2),
  ASSEMBLY: getPath('couriers/get-assembly-order'),
  ALL: getPath('order'),
  STORE_APPROVED: getPath('order/%s/store-approve'),
  QR: getPath('order/qr'),
  FORMING: getPath('order/forming'),
  READY_FOR_SALE: getPath('order/ready-for-sale'),
  CANCEL: getPath('order/cancel'),
  PRODUCT_RETURN: getPath('order/product-return'),
  NOT_IN_FULL: getPath('order/not-in-full'),
  CHANGE_STATUS: getPath('order/change-status'),
  CHANGE_STATUS_QR_ORDER: getPath('order/change-status-qr-order'),
  ADD_TO_BLACKLIST: getPath('order/add-to-black-list'),
  REDEMPTION: getPath('order/redemption'),
  STATUSES: getPath('order/statuses'),
  PURCHASE_CARDS: getPath('purchase-cards'),
  CALCULATE_RETURN_TOTAL: getPath('orders/%s/calculate-return-total', API_VERSION_V2),
  RETURN_PRODUCT: getPath('orders/%s/return-product', API_VERSION_V2),
  EXPORT_REQUETS: getPath('order/exports/request-for-active-user'),
  CHECK_EXPORT_STATUS: getPath('order/exports/queues/'),
}

export const REVIEW = {
  PATH: getPath('reviews'),
}

export const STORIES = {
  INDEX: getPath('stories/categories?sort_type=views&sort_direction=asc'),
  MEDIAS: getPath('stories/categories/%s/medias'),
  SEND_MESSAGE: getPath('stories/medias/send-message'),
  INCREASE_CATEGORY_VIEWS: getPath('stories/categories/%s/increase-views'),
}

export const STORE = {
  CREATE: getPath('store'), // create store
  SHOW: getPath('store'), // show store by id
  DELETE: getPath('store'), // delete store by id
  UPDATE: getPath('store'), // update, update, get
  LIST: getPath('store/list'), // list of all stores records for current customer
  TEMPLATES: getPath('store-templates'),
  STOREPRICE: getPath('store'), // create store
  DOMAINS: getPath('store-domains'),
}
