import { type FC, Suspense, useEffect, useMemo } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { getUserToken } from 'utils'
import { useAppSelector } from 'hooks'
import { AuthSelectors } from 'store/auth/selectors'
import { HelmetLayout, RouteLoader, PrivateRoute, PageLayout } from 'components'

import routesList from './routes'

export const RoutesWrapper: FC = () => {
  const { pathname } = useLocation()
  const { data } = useAppSelector(AuthSelectors.confirmState)
  const isAuthenticated = !!getUserToken() || !!data?.token

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const renderRoutes = useMemo(
    () =>
      routesList.map(({ element: Element, path, title, isPrivate }) => (
        <Route
          key={path}
          path={path}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} isPrivate={isPrivate}>
              <Suspense fallback={<RouteLoader />}>
                <HelmetLayout title={title}>
                  <PageLayout>
                    <Element />
                  </PageLayout>
                </HelmetLayout>
              </Suspense>
            </PrivateRoute>
          }
        />
      )),
    [isAuthenticated]
  )

  return <Routes>{renderRoutes}</Routes>
}
