import type { FC } from 'react'

import { Text } from 'components'
import { ImageOutlineIcon } from 'assets'

import type { TRoundedCard } from './types'
import styles from './RoundedCard.module.scss'

const RoundedCard: FC<TRoundedCard> = ({ image, text }) => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper_container}>
      {image ? (
        <img src={image} width='100%' className={styles.wrapper_image} height='100%' alt={text} />
      ) : (
        <div className={styles.wrapper_empty}>
          <ImageOutlineIcon />
        </div>
      )}
    </div>

    <Text text={text} className={styles.wrapper_text} />
  </div>
)

export default RoundedCard
