import { PROD, STAGE } from 'config/env'

export const getEnvVars = () => {
  const API_ENV = process.env.REACT_APP_API_ENV

  const env = API_ENV

  switch (env) {
    case 'local':
      return STAGE
    case 'stage':
      return STAGE
    default:
      return PROD
  }
}

const { apiUrl, apiEnv, pusher, telegramBot } = getEnvVars()

export const config = {
  apiEnv: apiEnv,
  apiUrl: apiUrl,
  telegramBot: telegramBot,
  pusher: {
    appId: pusher.PUSHER_APP_ID,
    key: pusher.PUSHER_APP_KEY,
    secret: pusher.PUSHER_APP_SECRET,
    cluster: pusher.PUSHER_APP_CLUSTER,
    encrypted: pusher.PUSHER_APP_ENCRYPTED === true,
  },
}

export const API_VERSION_V1 = 'v1'
export const API_VERSION_V2 = 'v2'
export const API_VERSION_V3 = 'v3'
export const API_VERSION_V4 = 'v4'
export const API_VERSION_V5 = 'v5'
