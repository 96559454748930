import { createAsyncThunk } from '@reduxjs/toolkit'

import api from 'libraries/axios'
import { showNotification, ToastVersions } from 'libraries/toastify'
import { handleErrorMessage, setUserDataToLocalStorage } from 'utils'

import type { TCustomerInfoFormData, TNotificationSettingForm, TSavePasswordForm } from './types'
import { isArray } from 'lodash'

export const fetchCostumerInformation = createAsyncThunk('customer/info', async () => {
  try {
    const response = await api.get(`/v2/customer`)
    setUserDataToLocalStorage(response.data)

    return response.data
  } catch (err: any) {
    return err
  }
})

export const updateNotificationSettings = createAsyncThunk(
  'customer/notification-settings-update',
  async (settings: TNotificationSettingForm) => {
    try {
      const response = await api.put(`/v1/customers/notification`, settings)

      showNotification(ToastVersions.success, 'update_success')

      return response
    } catch (error: any) {
      showNotification(ToastVersions.error, handleErrorMessage(error?.response?.data?.errors) || 'update_error')

      return error
    }
  }
)

export const getNotificationSettings = createAsyncThunk('customer/notification-settings', async () => {
  try {
    const response = await api.get(`/v1/customers/notification`)

    return response
  } catch (err: any) {
    return err
  }
})

export const updateCustomerInformation = createAsyncThunk(
  'customer/info-update',
  async (customerInfo: TCustomerInfoFormData, { rejectWithValue }) => {
    try {
      const response = await api.put(`/v2/customer/save-settings`, customerInfo)

      showNotification(ToastVersions.success, 'update_success')

      setUserDataToLocalStorage(response.data)

      return response.data
    } catch (err: any) {
      showNotification(ToastVersions.error, handleErrorMessage(err?.response?.data?.errors) || 'update_error')

      return rejectWithValue(err.response?.data?.error)
    }
  }
)

export const changeCustomerAvatar = createAsyncThunk(
  'customer/info-avatar-update',
  async (avatar: File, { rejectWithValue }) => {
    try {
      const formData = new FormData()
      formData.append('avatar', avatar)

      const response = await api.post(`/v1/customers/avatar?_method=PUT`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      showNotification(ToastVersions.success, 'update_success')

      return response.data
    } catch (err: any) {
      showNotification(ToastVersions.error, handleErrorMessage(err?.response?.data?.errors) || 'update_error')

      return rejectWithValue(err.response?.data?.error)
    }
  }
)

export const changeCustomerLanguage = createAsyncThunk(
  'customer/change-language',
  async (languageId: { language_id: number }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/v2/customer/change-lang`, languageId)

      showNotification(ToastVersions.success, 'language_changed_successfully')

      return response.data
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.error)
    }
  }
)

export const savePassword = createAsyncThunk(
  'customer/save-password',
  async (formData: TSavePasswordForm, { rejectWithValue }) => {
    try {
      const response: any = await api.put(`/v1/customers/save-password`, formData)

      showNotification(ToastVersions.success, 'password_changed_successfully')

      return response
    } catch (err: any) {
      showNotification(
        ToastVersions.error,
        isArray(err?.response?.data?.errors)
          ? (handleErrorMessage(err?.response?.data?.errors) ?? 'password_change_error')
          : 'password_change_error'
      )

      return rejectWithValue(err)
    }
  }
)
