import type { FC } from 'react'

import { Text, Button, Modal } from 'components'

import type { TOrderDuplicateModal } from './types'
import styles from './OrderDuplicateModal.module.scss'

const OrderDuplicateModal: FC<TOrderDuplicateModal> = ({ isOrderResultDuplicateModal, onClose }) => (
  <Modal onClose={onClose} className={styles.wrapper_modal} show={isOrderResultDuplicateModal}>
    <div className={styles.wrapper}>
      <Text className={styles.wrapper_title} text='order_create_duplicate' />
      <Text className={styles.wrapper_text} text='order_create_duplicate_description' />

      <Button isMaxContent onClick={onClose}>
        continue
      </Button>
    </div>
  </Modal>
)

export default OrderDuplicateModal
