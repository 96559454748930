import { InstagramIcon, FacebookIcon, YoutubeIcon, PinterestIcon, TiktokIcon } from 'assets'

export const socialMedias = [
  {
    id: 1,
    icon: InstagramIcon,
    nav: '',
  },

  {
    id: 2,
    icon: TiktokIcon,
    nav: '',
  },

  {
    id: 3,
    icon: FacebookIcon,
    nav: '',
  },

  {
    id: 4,
    icon: PinterestIcon,
    nav: '',
  },

  {
    id: 5,
    icon: YoutubeIcon,
    nav: '',
  },
]
