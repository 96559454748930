import type { FC } from 'react'

import { ProductOption, Text } from 'components'
import type { TWarehouse } from 'store/products/types'

import type { TProductStoreItemProps } from './types'
import styles from './ProductStoreItem.module.scss'

const ProductStoreItem: FC<TProductStoreItemProps> = ({ data, selectedOption, handleStoreItemClick }) => {
  const renderStoreItems = (warehouse: TWarehouse, isMain: boolean) =>
    warehouse?.options?.map((element, index) => (
      <ProductOption
        key={index}
        element={element}
        index={index}
        selectedIndex={isMain ? selectedOption.mainIndex : selectedOption.additionalIndex}
        onClick={(idx, optId, whId) => handleStoreItemClick(idx, optId, whId, isMain, element.product_option_value_id)}
      />
    ))

  return (
    <>
      {data?.map(({ warehouses, option_id }) => (
        <div key={option_id} className={styles.wrapper}>
          {warehouses?.map(element => (
            <div key={element.warehouse_id} className={styles.wrapper}>
              <Text text={element.name} className={styles.wrapper_title} />

              <div className={styles.wrapper_container}>{renderStoreItems(element, element.warehouse_id === 0)}</div>
            </div>
          ))}
        </div>
      ))}
    </>
  )
}

export default ProductStoreItem
