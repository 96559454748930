import api from 'libraries/axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { handleErrorMessage, setUserDataToLocalStorage, setUserTokenToLocalStorage } from 'utils'
import { showNotification, ToastVersions } from 'libraries/toastify'

import type { ILoginResponse, TLoginForm, TRegisterForm } from './types'

export const signIn = createAsyncThunk<any, any>('auth/login', async (formData: { telephone: string }) => {
  try {
    const response: ILoginResponse = await api.post(`/v5/auth/login`, formData)

    showNotification(ToastVersions.success, 'request_sent_successfully')

    return response
  } catch (err: any) {
    showNotification(ToastVersions.error, handleErrorMessage(err?.response?.data?.errors))

    throw new Error(err.response?.data?.error)
  }
})

export const signIn_V2 = createAsyncThunk<any, any>('auth/login_v2', async (formData: TLoginForm) => {
  try {
    const response: any = await api.post(`/v2/auth/login`, formData)
    setUserDataToLocalStorage(response.data)
    setUserTokenToLocalStorage(response.data.token)
    localStorage.removeItem('isResendOnce')

    showNotification(ToastVersions.success, 'login_successful')

    return response
  } catch (err: any) {
    showNotification(ToastVersions.error, err?.response?.data?.error)

    throw new Error(err.response?.data?.error)
  }
})

export const registrationUser = createAsyncThunk('auth/register', async (formData: TRegisterForm) => {
  try {
    const response: any = await api.post(`/v5/auth/register`, {
      ...formData,
      term: true,
    })

    showNotification(ToastVersions.success, 'request_sent_successfully')

    return response
  } catch (err: any) {
    showNotification(ToastVersions.error, handleErrorMessage(err?.response?.data?.errors))

    return err.response
  }
})

export const confirmAuth = createAsyncThunk(
  'auth/confirmAuth',
  async (formData: { code: string; telephone: string }, thunkAPI) => {
    try {
      const response: any = await api.post(`/v5/auth/confirm-code`, formData)
      setUserDataToLocalStorage(response.data)
      setUserTokenToLocalStorage(response.data.token)

      showNotification(ToastVersions.success, 'login_successful')

      return response.data
    } catch (err: any) {
      showNotification(ToastVersions.error, err?.response?.data.errors.code[0])

      return thunkAPI.rejectWithValue(err.response?.data?.error)
    }
  }
)
