import { useCallback, useEffect, useRef, useState, type FC } from 'react'
import Cropper from 'react-easy-crop'

import { base64toFile } from 'utils'
import { Button, Modal } from 'components'

import type { TEventPositionType, TImageCropProps, TRefPositionProps } from './types'
import styles from './ImageCrop.module.scss'

const ImageCrop: FC<TImageCropProps> = ({ imageSrc, setFile, setImageSrc }) => {
  const croppedAreaRef = useRef<TRefPositionProps | any>(null)
  const croppedAreaPixelsRef = useRef<TRefPositionProps | any>(null)

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [showModal, setShowModal] = useState<boolean>(!!imageSrc)

  const onCropComplete = useCallback((croppedArea: TEventPositionType, croppedAreaPixels: TEventPositionType) => {
    croppedAreaRef.current = croppedArea
    croppedAreaPixelsRef.current = croppedAreaPixels
  }, [])

  const [visibleCrop, setVisible] = useState<boolean>(false)

  const cropImageNow = () => {
    const canvas = document.createElement('canvas')

    canvas.width = croppedAreaPixelsRef?.current?.width
    canvas.height = croppedAreaPixelsRef?.current?.height
    const ctx: any = canvas.getContext('2d')
    const imageElement = document.createElement('img')
    imageElement.setAttribute('src', imageSrc)
    ctx.drawImage(
      imageElement,
      croppedAreaPixelsRef.current?.x,
      croppedAreaPixelsRef.current?.y,
      croppedAreaPixelsRef.current?.width,
      croppedAreaPixelsRef.current?.height,
      0,
      0,
      croppedAreaPixelsRef.current?.width,
      croppedAreaPixelsRef.current?.height
    )

    const base64Image = canvas.toDataURL('image/jpeg')

    setShowModal(false)
    setFile(base64toFile(base64Image, 'userAvatar.jpeg', 'image/jpeg'))
  }

  const onCancelClick = useCallback(() => {
    setImageSrc(null)
    setShowModal(false)
  }, [setImageSrc])

  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), 500)

    return () => clearTimeout(timer)
  }, [])

  return (
    <Modal className={styles.wrapper} show={showModal} onClose={onCancelClick}>
      <div className={styles.wrapper_crop}>
        {visibleCrop && (
          <Cropper
            crop={crop}
            zoom={zoom}
            aspect={4 / 3}
            image={imageSrc}
            cropShape='round'
            objectFit='contain'
            onZoomChange={setZoom}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            classes={{ containerClassName: styles.wrapper_crop_container }}
          />
        )}
      </div>

      <div className={styles.wrapper_button_container}>
        <Button size='medium' onClick={onCancelClick} variant='secondary'>
          cancel
        </Button>

        <Button size='medium' onClick={cropImageNow}>
          save_changes
        </Button>
      </div>
    </Modal>
  )
}

export default ImageCrop
