import type { FC } from 'react'

import { Text } from 'components'

import styles from './DeliveryAndPayment.module.scss'

const DeliveryAndPayment: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper_container}>
      <Text text='delivery_methods' className={styles.wrapper_title} />

      <div className={styles.wrapper_title_container}>
        <Text text='nova_poshta' className={styles.wrapper_subtitle} />
        <Text text='nova_poshta_description' className={styles.wrapper_description} />
      </div>

      <div className={styles.wrapper_title_container}>
        <Text text='courier_delivery' className={styles.wrapper_subtitle} />
        <Text text='courier_delivery_description' className={styles.wrapper_description} />
      </div>
    </div>

    <div className={styles.wrapper_container}>
      <Text text='payment_methods' className={styles.wrapper_title} />

      <div className={styles.wrapper_title_container}>
        <Text text='cash_on_delivery' className={styles.wrapper_subtitle} />
        <Text text='cash_on_delivery_fee' className={styles.wrapper_description} />
      </div>

      <div className={styles.wrapper_title_container}>
        <Text text='online_payment' className={styles.wrapper_subtitle} />
        <Text text='online_payment_description' className={styles.wrapper_description} />
      </div>
    </div>

    <Text text='return_policy' className={styles.wrapper_description} />
  </div>
)

export default DeliveryAndPayment
