export const formatPhoneNumber = (number: string) => {
  if (typeof number !== 'string' || number.length !== 12) {
    return number
  }

  return `+${number.slice(0, 2)}(${number.slice(2, 5)})-${number.slice(5, 8)}-${number.slice(8, 10)}-${number.slice(
    10
  )}`
}

export const handleErrorMessage = (errorObject: { errors: Record<string, string[]> }): string => {
  const messages: string[] = []

  if (errorObject.errors) {
    for (const errorList of Object.values(errorObject?.errors)) {
      messages.push(...errorList)
    }
  }

  return messages.length === 0 ? '' : messages.join('\n')
}

export const advancedFormatPhoneNumber = (input: string, mask: string) => {
  let formatted = ''
  let inputIndex = 0

  for (let i = 0; i < mask.length; i++) {
    if (inputIndex >= input.length) break
    if (mask[i] === '_') {
      formatted += input[inputIndex]
      inputIndex++
    } else {
      formatted += mask[i]
    }
  }

  return formatted
}
