import { useCallback, useState, type FC } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { Cabinet, Sidebar } from 'components'
import { toggleAuthModalOpen } from 'store/global'
import { useAppDispatch, useAppSelector } from 'hooks'
import { GlobalSelectors } from 'store/global/selectors'

import Login from './Login'
import Confirm from './Confirm'
import Register from './Register'
import { EModalState } from './types'
import styles from './AuthModal.module.scss'

const AuthModal: FC = () => {
  const dispatch = useAppDispatch()
  const userDataString = localStorage.getItem('userData')
  const isAuthModalOpen = useAppSelector(GlobalSelectors.isAuthModalOpen)

  const [modalState, setModalState] = useState<EModalState>(userDataString ? EModalState.Cabinet : EModalState.Login)

  const states = {
    [EModalState.Cabinet]: <Cabinet />,
    [EModalState.Login]: <Login setModalState={setModalState} modalState={modalState} />,
    [EModalState.Confirm]: <Confirm setModalState={setModalState} modalState={modalState} />,
    [EModalState.Register]: <Register setModalState={setModalState} modalState={modalState} />,
  }

  const currentState = states[modalState]
  const renderTitle =
    modalState === EModalState.Register ? 'registration' : modalState === EModalState.Cabinet ? 'cabinet' : 'auth'

  const closeAuthModal = useCallback(() => {
    dispatch(toggleAuthModalOpen(false))
  }, [dispatch])

  return (
    <Sidebar title={renderTitle} onClose={closeAuthModal} isOpen={isAuthModalOpen}>
      <AnimatePresence mode='wait'>
        <motion.div
          key={modalState}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          className={classNames(styles.wrapper, { [styles.wrapper_cabinet]: modalState === EModalState.Cabinet })}
        >
          {currentState}
        </motion.div>
      </AnimatePresence>
    </Sidebar>
  )
}

export default AuthModal
