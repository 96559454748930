import type { RootState } from 'types'
import { SelectComponentSelectors } from 'store/select/selectors'
import {
  fetchCountries,
  getAddresses,
  getCategories,
  getCities,
  getCustomers,
  getLanguages,
  getWarehouses,
} from 'store/select/actions'

import type { TSelectApi, TSelectDataItem, TSelectorInitial } from './types'

export const getData = (
  type: TSelectApi,
  page: number,
  { search, city_ref }: { search?: string; city_ref?: string }
) => {
  switch (type) {
    case 'address':
      return getAddresses({ searchValue: search || '', page })

    case 'warehouse':
      return getWarehouses({ searchValue: search || '', page, city_ref: city_ref ?? '' })

    case 'cities':
      return getCities({ searchValue: search || '', page })

    case 'countries':
      return fetchCountries({ searchValue: search || '', page })

    case 'customers':
      return getCustomers({ searchValue: search || '', page })

    case 'languages':
      return getLanguages()

    case 'categories':
      return getCategories({ searchValue: search || '', page })

    default:
      return null
  }
}

export const getSelector: any = (type?: TSelectApi): ((state: RootState) => TSelectorInitial) => {
  switch (type) {
    case 'address':
      return SelectComponentSelectors.addressesState

    case 'warehouse':
      return SelectComponentSelectors.warehousesState

    case 'cities':
      return SelectComponentSelectors.citiesState

    case 'countries':
      return SelectComponentSelectors.countriesState

    case 'customers':
      return SelectComponentSelectors.customersState

    case 'languages':
      return SelectComponentSelectors.languagesState

    case 'categories':
      return SelectComponentSelectors.categoriesState

    default:
      return SelectComponentSelectors.custom
  }
}

type DataItem = Record<string, any>

export const formatData = (data: DataItem[], selectApi: TSelectApi, lang?: string): TSelectDataItem[] => {
  return data?.map(item => {
    // Define a universal way to pick an ID field
    const idField =
      Object.keys(item).find(key =>
        ['ref', 'id', 'product_id', 'option_id', 'customer_id', 'category_id', 'language_id'].includes(key)
      ) || 'id'

    // Define a universal way to pick a name field
    const nameField = Object.keys(item).find(key => ['name', 'title', 'description', 'domain'].includes(key)) || 'name'

    return {
      _id: item[idField],
      name: item[nameField],
      ...(lang === 'uk' && item.description ? { name: item.description } : {}),
      ...(item.ref ? { ref: item.ref } : {}),
      ...Object.fromEntries(Object.entries(item).filter(([key]) => key !== idField && key !== nameField)),
    }
  })
}
