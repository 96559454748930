import { Fragment, type FC } from 'react'
import classNames from 'classnames'
import { capitalize } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { useResponsive } from 'hooks'
import { HomeOutlineIcon } from 'assets'
import { formatStringLength } from 'utils'
import { ERoutePaths } from 'libraries/router/types'

import type { TBreadcrumbProps } from './types'
import styles from './Breadcrumb.module.scss'

const Breadcrumb: FC<TBreadcrumbProps> = ({ breadcrumbs, className, onItemClick }) => {
  const navigate = useNavigate()
  const { isMobileSmall, isTabletSmall } = useResponsive()

  const onBreadcrumbClick = (nav: string, id: string) => {
    if (onItemClick) {
      onItemClick?.(id)
    } else {
      navigate(nav)
    }
  }

  const renderBreadcrumb = breadcrumbs.map((breadcrumb, index) => {
    const lastOneFormatting =
      index === breadcrumbs.length - 1 && isMobileSmall
        ? formatStringLength(capitalize(breadcrumb.name), 28)
        : capitalize(breadcrumb.name)

    return (
      <Fragment key={breadcrumb.id}>
        <span className={styles.wrapper_item}>/</span>
        <span
          role='button'
          className={classNames(styles.wrapper_item, {
            [styles.wrapper_item_active]: index === breadcrumbs.length - 1,
          })}
          onClick={() => onBreadcrumbClick(`/categories/${breadcrumb.id}`, breadcrumb.id)}
        >
          {isTabletSmall && index !== breadcrumbs.length - 1 ? '...' : lastOneFormatting}
        </span>
      </Fragment>
    )
  })

  return (
    <div className={classNames(styles.wrapper, className)}>
      <HomeOutlineIcon role='button' onClick={() => onBreadcrumbClick(ERoutePaths.CategoriesMain, 'main')} />

      {renderBreadcrumb}
    </div>
  )
}

export default Breadcrumb
