import { useEffect, type FC } from 'react'
import { useLocation } from 'react-router-dom'

import { getUserToken, pagesToHideFooter } from 'utils'
import { useAppDispatch, useResponsive } from 'hooks'
import { Footer, Header, MobileNavBar } from 'components'
import { fetchCostumerInformation } from 'store/customer/actions'

import type { TPageLayoutProps } from './types'
import styles from './PageLayout.module.scss'

const PageLayout: FC<TPageLayoutProps> = ({ children }) => {
  const token = getUserToken()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { isTabletSmall } = useResponsive()

  const currentPageFooterHide = pagesToHideFooter.find(element => element.pathname === pathname)

  useEffect(() => {
    if (token) {
      dispatch(fetchCostumerInformation())
    }
  }, [dispatch, token])

  return (
    <main className={styles.wrapper}>
      <Header />

      {children}

      {currentPageFooterHide?.isHideOnlyMobile && isTabletSmall ? null : <Footer />}

      {isTabletSmall && <MobileNavBar />}
    </main>
  )
}

export default PageLayout
