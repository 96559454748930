import type { FC } from 'react'

import { Button, Modal, Text, WrappedIcon } from 'components'

import type { TCautionaryModal } from './types'
import styles from './CautionaryModal.module.scss'

const CautionaryModal: FC<TCautionaryModal> = ({
  Icon,
  title,
  loading,
  subtitle,
  showModal,
  closeModal,
  onSavePress,
  saveButtonText = 'save',
  cancelButtonText = 'cancel',
}) => (
  <Modal className={styles.wrapper} onClose={closeModal} show={showModal}>
    <div className={styles.wrapper}>
      <WrappedIcon type='danger' Icon={Icon} />

      <div className={styles.wrapper_container}>
        <Text text={title} className={styles.wrapper_title} />

        <Text text={subtitle} className={styles.wrapper_subtitle} />
      </div>

      <div className={styles.wrapper_button_container}>
        <Button size='small' variant='secondary' onClick={closeModal} disabled={loading}>
          {cancelButtonText}
        </Button>
        <Button size='small' variant='primary' disabled={loading} onClick={onSavePress}>
          {saveButtonText}
        </Button>
      </div>
    </div>
  </Modal>
)

export default CautionaryModal
