import type { FC } from 'react'
import { useCallback } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { motion } from 'motion/react'

import { getUserToken } from 'utils'
import { useAppDispatch } from 'hooks'
import { NavigationTab } from 'components'
import { ERoutePaths } from 'libraries/router/types'
import { toggleAuthModalOpen, toggleCatalogOpen } from 'store/global'

import { mobileNavigationTabs } from './utils'
import styles from './MobileNavBar.module.scss'

const MobileNavBar: FC = () => {
  const dispatch = useAppDispatch()
  const userToken = getUserToken()
  const { pathname } = useLocation()

  const handleCatalogOpen = useCallback(() => {
    dispatch(toggleCatalogOpen(true))
  }, [dispatch])

  const toggleAuthModal = useCallback(() => {
    dispatch(toggleAuthModalOpen(true))
  }, [dispatch])

  const actionHandlers: Record<number, () => void> = {
    5: toggleAuthModal,
    2: handleCatalogOpen,
  }

  return (
    <motion.nav
      className={styles.wrapper}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      {mobileNavigationTabs.map(({ id, nav, icon, text }) =>
        nav && (userToken || nav === ERoutePaths.Home) ? (
          <NavLink key={id} to={nav}>
            <NavigationTab Icon={icon} text={text} className={styles.wrapper_tab} active={nav === pathname} />
          </NavLink>
        ) : (
          <div key={id} role='button' onClick={!userToken && id !== 2 ? actionHandlers[5] : actionHandlers[id]}>
            <NavigationTab className={styles.wrapper_tab} Icon={icon} text={text} />
          </div>
        )
      )}
    </motion.nav>
  )
}

export default MobileNavBar
