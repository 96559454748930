import type { FC } from 'react'
import classNames from 'classnames'

import type { TSkeletonProps } from './types'
import styles from './Skeleton.module.scss'

const Skeleton: FC<TSkeletonProps> = ({ className, width, height, isRounded }) => (
  <div
    className={classNames(styles.wrapper, className)}
    style={{ width: width, height: height, borderRadius: isRounded ? '50%' : '16px' }}
  />
)

export default Skeleton
