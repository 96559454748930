export const SHIPPING_METHOD_NOVAPOSHTA_INTERNATIONAL = 'novaposhta.international'
export const SHIPPING_METHOD_NOVAPOSHTA_WAREHOUSE = 'novaposhta.warehouse'
export const SHIPPING_METHOD_NOVAPOSHTA_COURIER = 'novaposhta.courier'

export const CUSTOMER_GROUPS = {
  CUSTOMER_GROUP_RETAIL: 1,
  CUSTOMER_GROUP_WHOLESALE: 2,
  CUSTOMER_GROUP_DROPSHIPPING: 3,
  CUSTOMER_GROUP_MANAGER: 5,
  CUSTOMER_GROUP_DROP_MANAGER: 6,
  CUSTOMER_GROUP_COURIER: 7,
  CUSTOMER_GROUP_STORE_SELLER: 8,
  CUSTOMER_GROUP_MANAGER_OVERLAY: 9,
  CUSTOMER_GROUP_PACKER: 10,
  CUSTOMER_GROUP_PARTNER: 11,
  CUSTOMER_GROUP_PROVIDER: 12,
  CUSTOMER_GROUP_SUPER_COURIER: 13,
}

export const ORDER_STATUSES = {
  STATUS_EMPTY: 0, // Пустой
  STATUS_NEW: 1, // Новый
  STATUS_IN_THE_WAY: 15, // В дороге
  STATUS_REFUSAL: 18, // Отказ
  STATUS_CANCEL_TO_SEND: 19, // Отменен (до отправления)
  STATUS_RECEIVED: 21, // Получен
  STATUS_PRE_PAY: 24, // Предоплата 100грн
  STATUS_FULL_PAY: 25, // Полная оплата
  STATUS_PRINT: 26, // Распечатан
  STATUS_ASSEMBLED: 27, // Собран
  STATUS_ASSEMBLY: 36, // Сборка
  STATUS_UNDERSTAFFED: 37, // Недоукомплектован

  STATUS_OUR_ERROR: 28, // Наша ошибка
  STATUS_ERROR_PAY: 29, // Ошибка платежа
  STATUS_PRE_ORDER: 30, // Предварительный
  STATUS_SITE_ORDER: 31, // Заказ с сайта
  STATUS_READY_TO_SEND: 33, // Готов к отправке
  STATUS_WAITING_MONEY: 34, // Ожидает оплаты
  STATUS_IN_REGISTRY: 35, // В реестре
}

export const PRODUCT_PRICE_TYPE = {
  PRICE_TYPE_DROP: 1,
  PRICE_TYPE_WHOLESALE_SMALL: 2,
  PRICE_TYPE_WHOLESALE_LARGE: 3,
  PRICE_TYPE_SPECIAL: 4,
  PRICE_TYPE_PARTNER: 5,
  PRICE_TYPE_INDIVIDUAL_SMALL: 6,
  PRICE_TYPE_INDIVIDUAL_LARGE: 7,
}
