import { useRef, useState, FC, useEffect } from 'react'
import classNames from 'classnames'
import { capitalize } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import { ChevronRightIcon } from 'assets'
import { toggleCatalogOpen } from 'store/global'
import { CatalogItemSkeleton, Text } from 'components'
import { GlobalSelectors } from 'store/global/selectors'
import type { TCategoryData } from 'store/categories/types'
import { fetchCategoryTreeV1 } from 'store/categories/actions'
import { CategoriesSelectors } from 'store/categories/selectors'
import { useAppDispatch, useAppSelector, useLockBodyScroll, useOnClickOutside, useResponsive } from 'hooks'

import type { TCatalog } from './types'
import CatalogMobile from './CatalogMobile'
import styles from './Catalog.module.scss'

const Catalog: FC<TCatalog> = ({ buttonRef }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isTabletSmall } = useResponsive()
  const { data: tree, loading } = useAppSelector(CategoriesSelectors.treeData)
  const isCatalogOpen = useAppSelector(GlobalSelectors.isCatalogOpen)

  const ref = useRef<HTMLDivElement>(null)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const nodeRef = useRef<HTMLDivElement>(null)

  const [children, setChildren] = useState<TCategoryData[] | null>(null)
  const [activeCategory, setActiveCategory] = useState<string | null>(null)

  const closeCatalog = () => {
    dispatch(toggleCatalogOpen(false))
  }

  useLockBodyScroll(isCatalogOpen)
  useOnClickOutside([ref, sidebarRef, buttonRef], closeCatalog)

  useEffect(() => {
    if (isCatalogOpen && !tree) {
      dispatch(fetchCategoryTreeV1())
    }
  }, [dispatch, isCatalogOpen, tree])

  const handleMouseOver = (category: TCategoryData) => {
    setChildren(category.childrens || null)
    setActiveCategory(category.id)
  }

  const onCategoryClick = (category: TCategoryData) => {
    navigate(`/products?categoryId=${category.id}`)
    closeCatalog()
  }

  const onViewAllClick = () => {
    navigate(`/products?categoryId=${activeCategory}`)
    closeCatalog()
  }

  return (
    <div className={styles.wrapper}>
      {isTabletSmall ? (
        <div ref={sidebarRef}>
          <CatalogMobile closeCatalog={closeCatalog} isCatalogOpen={isCatalogOpen} />
        </div>
      ) : (
        <CSSTransition
          in={isCatalogOpen}
          unmountOnExit
          timeout={200}
          nodeRef={nodeRef}
          classNames={{
            enterDone: styles.wrapper__dropdown__dropped,
          }}
        >
          <div ref={nodeRef} className={styles.wrapper__container}>
            <div ref={ref} className={styles.wrapper__dropdown}>
              <div className={styles.wrapper__dropdown__left}>
                <Text text='categories' tagName='h4' className={styles.wrapper__dropdown__left__title} />

                <div>
                  {loading && !tree ? (
                    <CatalogItemSkeleton skeletonCount={10} />
                  ) : (
                    tree?.map(category => (
                      <div
                        role='button'
                        key={category.id}
                        className={classNames(styles.wrapper__dropdown__left__item, {
                          [styles.wrapper__dropdown__left__item__active]: activeCategory === category.id,
                        })}
                        onMouseOver={() => handleMouseOver(category)}
                      >
                        <div className={styles.wrapper__dropdown__left__item_container}>
                          {category.icon && <img width={24} height={24} src={category.icon} alt={category.name} />}

                          <p className={styles.wrapper__dropdown__left__item__text}>{capitalize(category.name)}</p>
                        </div>

                        {category.childrens?.length ? <ChevronRightIcon /> : null}
                      </div>
                    ))
                  )}
                </div>
              </div>

              {children && (
                <div className={styles.wrapper__dropdown__right}>
                  <div className={styles.wrapper__dropdown__right_scrollable}>
                    {children.map(subcategory => (
                      <div className={styles.wrapper__dropdown__right_container} key={subcategory.id}>
                        <div
                          role='button'
                          onClick={() => onCategoryClick(subcategory)}
                          className={styles.wrapper__dropdown__right__text_title}
                        >
                          {subcategory.name}
                        </div>

                        {subcategory.childrens?.map((child: any) => (
                          <div
                            role='button'
                            onClick={() => onCategoryClick(child)}
                            className={styles.wrapper__dropdown__right__text}
                            key={child.id}
                          >
                            {child.name}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div className={styles.wrapper__dropdown__right_bottom}>
                    <Text
                      role='button'
                      onClick={onViewAllClick}
                      text='view_all_products'
                      className={classNames(styles.wrapper__dropdown__right__text, styles.sidebar_item_text_all)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </CSSTransition>
      )}
    </div>
  )
}

export default Catalog
