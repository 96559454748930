import { createRoot } from 'react-dom/client'

import './i18n'
import App from './App'
import './styles/index.global.scss'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(<App />)
