import type { TCategoryData } from 'store/categories/types'

export const parsePrice = (priceString: string): number => {
  const parsed = priceString.match(/\d+([\s\d]*)/g)?.[0]?.replace(/\s/g, '') || '0'

  return Number(parsed)
}

export const insertAfterId = (routes: any, itemsToInsert: any, afterId: number) => {
  const index = routes.findIndex((route: any) => route.id === afterId)
  if (index === -1) return routes

  return [...routes.slice(0, index + 1), ...itemsToInsert, ...routes.slice(index + 1)]
}

export const fullNameFirstLetters = (string: string) => {
  if (string) {
    const firstLetters = string?.split(' ')[0][0] + string?.split(' ')[1][0]

    return firstLetters.toUpperCase()
  }

  return ''
}

export const base64toFile: (image: string, filename: string, mimeType: string) => File = (
  image: string,
  filename: string,
  mimeType: string
) => {
  const base64Data = atob(image.split(',')[1])

  const arrayBuffer = new Uint8Array(base64Data.length)

  for (let i = 0; i < base64Data.length; i++) {
    arrayBuffer[i] = base64Data.charCodeAt(i)
  }

  const blob = new Blob([arrayBuffer], { type: mimeType })
  const file = new File([blob], filename, { type: mimeType })

  return file
}

export const findCategoryPath = (
  categories: TCategoryData[],
  id: number,
  path: TCategoryData[] = []
): TCategoryData[] | null => {
  for (const category of categories) {
    const newPath = [...path, category]
    if (Number(category.id) === id) return newPath
    if (category.childrens) {
      const foundPath = findCategoryPath(category.childrens, id, newPath)
      if (foundPath) return foundPath
    }
  }

  return null
}

export const formatStringLength = (text: string, length: number): string => {
  if (text.length <= length) return text

  return text.slice(0, length) + '...'
}

export const compareNumbers = (value: string, comparingNum: number, message: string) => {
  if (value !== String(Number(value))) {
    return message
  } else if (Number(value) < comparingNum) {
    return message
  }
}

export const normalizeText = (text: string) => {
  return text
    .toLowerCase()
    .replace(/(<p>\s*)([а-яa-z])/i, (_, tag, char) => tag + char.toUpperCase())
    .replace(/\)\s*([а-яa-z])/g, (_, char) => ') ' + char.toUpperCase())
    .replace(/\s{2,}/g, ' ')
    .trim()
}
