import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'libraries/axios'

export const fetchCategoryTreeV1 = createAsyncThunk('category/category-tree-v1', async () => {
  try {
    const response = await api.get('/v1/categories/tree')

    return response.data
  } catch (err) {
    throw Error('Failed to get category tree data')
  }
})

export const fetchCategoryRoot = createAsyncThunk('category/root-parents', async () => {
  try {
    const response = await api.get('/v1/categories/root-parents')

    return response.data
  } catch (err) {
    throw Error('Failed to get category root')
  }
})
