import { useAppDispatch } from 'libraries/redux'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { changeCustomerLanguage } from 'store/customer/actions'

import { ELanguage } from 'types'

const useLanguageTranslation = () => {
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()

  const onLanguageChangeCallback = useCallback(
    (language: string, language_id: number) => {
      i18n.changeLanguage(language)
      dispatch(changeCustomerLanguage({ language_id: language_id }))
    },
    [dispatch, i18n]
  )

  return {
    language: i18n.language as ELanguage,
    isUkrainian: i18n.language === 'uk',
    onLanguageChangeCallback,
  }
}
export default useLanguageTranslation
