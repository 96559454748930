import { Fragment, useCallback, useState, type FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { UserOutlineIcon } from 'assets'
import { setConfirmNumber } from 'store/auth'
import type { TLoginForm } from 'store/auth/types'
import { loginSchema, loginSchema_V2 } from 'utils'
import { AuthSelectors } from 'store/auth/selectors'
import { signIn, signIn_V2 } from 'store/auth/actions'
import { useAppDispatch, useAppSelector } from 'hooks'
import { Button, Input, Select, Text } from 'components'

import { EModalState, type TLoginProps } from './types'
import styles from './AuthModal.module.scss'

const Login: FC<TLoginProps> = ({ setModalState }) => {
  const dispatch = useAppDispatch()
  const loginSlice = useAppSelector(AuthSelectors.loginState)

  const [isSameUser, setIsSameUser] = useState<boolean>(false)

  const isWithPassword = isSameUser || loginSlice.isFromConfirm
  const isResendOnce = JSON.parse(localStorage.getItem('isResendOnce') as string)

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, dirtyFields },
    ...methods
  } = useForm<TLoginForm>({
    resolver: yupResolver(isWithPassword ? loginSchema_V2 : loginSchema),
    mode: 'onChange',
  })

  const openRegister = useCallback(() => {
    setModalState(EModalState.Register)
  }, [setModalState])

  const telephoneValue = watch('telephone')
  const isValidTelephoneValue = telephoneValue || !telephoneValue?.includes('_')

  const onSubmit: SubmitHandler<TLoginForm> = async data => {
    if (isWithPassword) {
      const result = await dispatch(signIn_V2(data))

      if (!!result.payload?.data?.token) {
        setModalState(EModalState.Cabinet)
      }
    }

    if (isResendOnce?.telephone === data.telephone) {
      setIsSameUser(true)
    } else if (!isWithPassword) {
      await dispatch(signIn(data))

      if (!loginSlice.error) {
        localStorage.setItem('tel', data.telephone)
        dispatch(setConfirmNumber(data.telephone))
        setModalState(EModalState.Confirm)
      }
    }
  }

  return (
    <Fragment>
      <div className={styles.wrapper_icon}>
        <UserOutlineIcon />
      </div>

      <div className={styles.wrapper_container}>
        <Text className={styles.wrapper_title} text='login_to_account' />
        <Text className={styles.wrapper_subtitle} text='login_description' />
      </div>

      <form className={styles.wrapper_form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.wrapper_container}>
          <Select
            isDirty={!!dirtyFields.telephone}
            register={register('telephone')}
            methods={methods}
            label='phone_number'
            error={errors.telephone?.message}
          />
          {isWithPassword && (
            <Input
              isDirty={!!dirtyFields.password}
              label='password'
              type='password'
              placeholder='password'
              register={register('password')}
              error={errors?.password?.message}
            />
          )}
        </div>

        <Button
          type='submit'
          className={styles.wrapper_button}
          disabled={!isValid || isSubmitting || !isValidTelephoneValue}
        >
          login
        </Button>
      </form>

      <div className={styles.wrapper_footer}>
        <Text className={styles.wrapper_footer_text} text='no_account' />
        <Text role='button' onClick={openRegister} className={styles.wrapper_footer_link} text='register' />
      </div>
    </Fragment>
  )
}

export default Login
