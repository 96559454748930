import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Text } from 'components'
import { supportEmail } from 'utils'
import { ArrowRightIcon, EmailIcon, PhoneIcon } from 'assets'

import styles from './ProductDetailsSupport.module.scss'
import { ERoutePaths } from 'libraries/router/types'

const ProductDetailsSupport: FC = () => {
  const navigate = useNavigate()

  const handleNavigationToContacts = () => {
    navigate(ERoutePaths.Contacts)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_header}>
        <Text text='need_help' />
      </div>

      <div className={styles.wrapper_divider} />

      <div className={styles.wrapper_container}>
        <div className={styles.wrapper_title_container}>
          <div className={styles.wrapper_icon}>
            <PhoneIcon />
          </div>

          <Text
            text='need_help'
            onClick={handleNavigationToContacts}
            RightIcon={ArrowRightIcon}
            role='button'
            className={styles.wrapper_title}
          />
        </div>

        <Text text='support_availability' className={styles.wrapper_subtitle} />
      </div>

      <div className={styles.wrapper_divider} />

      <div className={styles.wrapper_container}>
        <div className={styles.wrapper_title_container}>
          <div className={styles.wrapper_icon}>
            <EmailIcon />
          </div>

          <Text text={supportEmail} className={styles.wrapper_title} />
        </div>

        <Text text='contact_support_long' className={styles.wrapper_subtitle} />
      </div>
    </div>
  )
}

export default ProductDetailsSupport
