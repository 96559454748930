import type { FC, MouseEvent } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { AddIcon, DeleteIcon } from 'assets'
import { NotFoundImage, Text } from 'components'

import { isOrderedProduct } from '../OrderDetails/utils'
import type { TOrderedProductProps } from './types'
import styles from './OrderedProduct.module.scss'

const OrderedProduct: FC<TOrderedProductProps> = ({ item, onClose, isTheLastItem, onClick, className, addIcon }) => {
  const navigate = useNavigate()

  const handleItemClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation

    if (onClick) {
      if (!isOrderedProduct(item)) onClick(item)
    } else {
      navigate(`/product-details/${item?.product_id}`)
    }
  }

  const onDeleteClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation

    if (isOrderedProduct(item)) {
      onClose?.(item?.order_product_id)
    } else {
      onClose?.(item?.product_id)
    }
  }

  return (
    <div role='button' onClick={event => handleItemClick(event)} className={classNames(styles.wrapper, className)}>
      <div className={styles.wrapper_left}>
        {item?.thumb ? (
          <img src={item.thumb} alt='product-image' width={72} height={72} className={styles.wrapper_left_image} />
        ) : (
          <NotFoundImage className={styles.wrapper_left_image} />
        )}
      </div>

      <div className={styles.wrapper_right}>
        <Text text={item?.name} className={styles.wrapper_title} />

        {isOrderedProduct(item) && (
          <>
            <Text text='size' RText={`: ${item?.option[0]?.value}`} className={styles.wrapper_text} />

            <Text text={`${item?.quantity} шт., ${item?.option[0]?.warehouse_name}`} className={styles.wrapper_text} />

            <p className={styles.wrapper_price}>
              {item?.price}
              <sup>
                <Text emptyTag LText=' / ' text='drop_price' />
              </sup>
            </p>
          </>
        )}
      </div>

      {onClose && !isTheLastItem && (
        <div role='button' onClick={event => onDeleteClick(event)} className={styles.wrapper_close}>
          <DeleteIcon />
        </div>
      )}

      {addIcon && (
        <div role='button' className={styles.wrapper_close}>
          <AddIcon />
        </div>
      )}
    </div>
  )
}

export default OrderedProduct
