import { type FC, Fragment, useCallback } from 'react'
import classNames from 'classnames'

import { useCopyText } from 'hooks'
import { Button, OrderedProduct, Text } from 'components'
import { CheckCircleIcon, ContentCopyIcon } from 'assets'
import {
  ORDER_STATUSES,
  SHIPPING_METHOD_NOVAPOSHTA_COURIER,
  SHIPPING_METHOD_NOVAPOSHTA_INTERNATIONAL,
} from 'config/constant/helper'

import { earningColor } from './utils'
import type { TOrderDetailsStateProps } from './types'
import styles from './OrderDetails.module.scss'

const Detail: FC<TOrderDetailsStateProps> = ({ item, setSidebarState }) => {
  const { isCopied: isIdCopied, handleCopyClickChange: handleIdCopyClickChange } = useCopyText()
  const { isCopied: isTtnCopied, handleCopyClickChange: handleTtnCopyClickChange } = useCopyText()

  const renderOrderedProducts = item?.products.map(element => (
    <OrderedProduct item={element} key={element.product_id} />
  ))

  const onEditClick = useCallback(() => {
    setSidebarState('edit')
  }, [setSidebarState])

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_container}>
        <div className={styles.wrapper_column}>
          <div className={classNames(styles.wrapper_row, styles.wrapper_row_wrap)}>
            <div className={styles.wrapper_badge}>
              <div className={styles.wrapper_dot} />
              <Text text={item?.status ?? ''} />
            </div>

            <p className={styles.wrapper_text}>{item?.date}</p>
          </div>

          <div className={styles.wrapper_row}>
            <Text text='order_number' className={styles.wrapper_text} />
            <Text
              text={String(item?.order_id)}
              RightIcon={isIdCopied ? CheckCircleIcon : ContentCopyIcon}
              onClick={() => handleIdCopyClickChange(String(item?.order_id))}
              className={classNames(styles.wrapper_text_bold, { [styles.wrapper_text_copied]: isIdCopied })}
            />
          </div>

          <div className={styles.wrapper_item_container}>{renderOrderedProducts}</div>
        </div>

        <div className={styles.wrapper_line} />

        <div className={styles.wrapper_column_12}>
          <Text text='recipient_details' className={styles.wrapper_text_bottom} />
          <div className={styles.wrapper_column_6}>
            <div className={styles.wrapper_row}>
              <Text text='recipient' className={styles.wrapper_text} />
              <Text text={`${item?.first_name} ${item?.last_name}`} className={styles.wrapper_text_bold} />
            </div>

            <div className={styles.wrapper_row}>
              <Text text='phone_number' className={styles.wrapper_text} />
              <Text text={item?.telephone ?? ''} className={styles.wrapper_text_bold} />
            </div>
          </div>
        </div>

        {item?.comment && (
          <Fragment>
            <div className={styles.wrapper_line} />

            <div className={styles.wrapper_column_12}>
              <Text text='order_comment' className={styles.wrapper_text_bottom} />

              <Text text={item?.comment} className={styles.wrapper_text} />
            </div>
          </Fragment>
        )}

        <div className={styles.wrapper_line} />

        <div className={styles.wrapper_column_12}>
          <Text text='delivery' className={styles.wrapper_text_bottom} />

          <div className={styles.wrapper_column_6}>
            {item?.city && (
              <div className={styles.wrapper_row}>
                <Text text='city' className={styles.wrapper_text} />
                <Text text={item?.city ?? ''} className={styles.wrapper_text_bold} />
              </div>
            )}
            {item?.address && (
              <div className={styles.wrapper_row}>
                <Text text='warehouse_id.title' className={styles.wrapper_text} />

                {item?.shipping_code === SHIPPING_METHOD_NOVAPOSHTA_COURIER ? (
                  <Text
                    text={`${item?.shipping_street}, ${item?.shipping_house}${item?.shipping_house && item?.shipping_flat ? `/${item?.shipping_flat}` : ''}`}
                    className={styles.wrapper_text_bold}
                  />
                ) : (
                  <Text text={item?.address ?? ''} className={styles.wrapper_text_bold} />
                )}
              </div>
            )}

            {item?.shipping_code === SHIPPING_METHOD_NOVAPOSHTA_INTERNATIONAL && (
              <div className={styles.wrapper_row}>
                <Text text='country_id.title' className={styles.wrapper_text} />
                <Text text={item?.country ?? ''} className={styles.wrapper_text_bold} />
              </div>
            )}

            {item?.ttn && (
              <div className={styles.wrapper_row}>
                <Text text='ttn_order' className={styles.wrapper_text} />
                <Text
                  text={item?.ttn ?? ''}
                  RightIcon={isTtnCopied ? CheckCircleIcon : ContentCopyIcon}
                  onClick={() => handleTtnCopyClickChange(String(item?.ttn))}
                  className={classNames(styles.wrapper_text_bold, { [styles.wrapper_text_copied]: isTtnCopied })}
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles.wrapper_line} />

        <div className={styles.wrapper_column_12}>
          <Text text='payment' className={styles.wrapper_text_bottom} />

          <div className={styles.wrapper_column_6}>
            <div className={styles.wrapper_row}>
              <Text text='total_price' className={styles.wrapper_text} />
              <Text text={item?.total_format ?? ''} className={styles.wrapper_text_bottom} />
            </div>

            <div className={styles.wrapper_row}>
              <Text text='payment_method' className={styles.wrapper_text} />
              <Text text={item?.payment_method ?? ''} className={styles.wrapper_text_bold} />
            </div>

            {!!item?.total_pay && (
              <Fragment>
                <div className={styles.wrapper_row}>
                  <Text text='cash_on_delivery_cost' className={styles.wrapper_text} />
                  <Text text={item?.total_pay_format ?? ''} className={styles.wrapper_text_bold} />
                </div>

                <div className={styles.wrapper_row}>
                  <Text text='earnings' className={styles.wrapper_text} />
                  <Text
                    text={item?.amount_format ?? ''}
                    className={styles.wrapper_text_bold}
                    style={{ color: earningColor(item?.amount) }}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>

      {item?.order_status_id == ORDER_STATUSES.STATUS_NEW && (
        <div className={styles.wrapper_bottom}>
          <Button onClick={onEditClick} className={styles.wrapper_button} size='small'>
            edit
          </Button>
        </div>
      )}
    </div>
  )
}

export default Detail
