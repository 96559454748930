import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'
import { RadioMarkedIcon, RadioBlankIcon } from 'assets'

import type { IRadioProps } from './types'
import styles from './Radio.module.scss'

const Radio: FC<IRadioProps> = ({ label, name, value, checked, onChange, className }) => (
  <label className={classNames(styles.wrapper, className)}>
    <input type='radio' name={name} value={value} checked={checked} onChange={() => onChange?.(value)} />

    {checked ? (
      <RadioMarkedIcon className={styles.wrapper_dot} />
    ) : (
      <RadioBlankIcon className={styles.wrapper_circle} />
    )}

    <Text tagName='span' className={styles.wrapper_label} text={label} />
  </label>
)

export default Radio
