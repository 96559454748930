import { useRef, type FC } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { CloseIcon } from 'assets'
import { useLockBodyScroll, useOnClickOutside } from 'hooks'

import type { TModalProps } from './types'
import styles from './Modal.module.scss'

const Modal: FC<TModalProps> = ({ show, children, onClose, className, closeIcon }) => {
  const nodeRef = useRef(null)

  useLockBodyScroll(!!show)
  useOnClickOutside([nodeRef], onClose)

  return createPortal(
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={styles.wrapper}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.5 }}
            ref={nodeRef}
            className={classNames(className, styles.wrapper_block)}
          >
            {closeIcon ? (
              <div role='button' className={styles.wrapper_close} onClick={onClose}>
                <CloseIcon />
              </div>
            ) : null}

            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,

    document.body
  )
}

export default Modal
