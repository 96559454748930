import { Fragment, useCallback, useEffect, useState, type FC } from 'react'
import OTPInput from 'react-otp-input'
import { SubmitHandler, useForm } from 'react-hook-form'

import { formatPhoneNumber } from 'utils'
import { setIsFromConfirm } from 'store/auth'
import { TConfirmForm } from 'store/auth/types'
import { AuthSelectors } from 'store/auth/selectors'
import { useAppDispatch, useAppSelector } from 'hooks'
import { Button, CodeNotComing, Text } from 'components'
import { confirmAuth, signIn } from 'store/auth/actions'
import { showNotification, ToastVersions } from 'libraries/toastify'

import { EModalState, type TAuthComponentsProps } from './types'
import styles from './AuthModal.module.scss'

const RESEND_CODE_TIMER = 60

const Confirm: FC<TAuthComponentsProps> = ({ setModalState }) => {
  const [otp, setOtp] = useState<string>('')
  const [isResendOnce, setIsResendOnce] = useState<boolean>(false)
  const [timer, setTimer] = useState<number>(RESEND_CODE_TIMER)

  const dispatch = useAppDispatch()
  const confirmSlice = useAppSelector(AuthSelectors.confirmState)
  const loginState = useAppSelector(AuthSelectors.loginState)
  const { handleSubmit } = useForm<TConfirmForm>()

  const phoneNumber = loginState.phoneNumber || localStorage.getItem('tel')

  const isResendOnceGlobal = JSON.parse(localStorage.getItem('isResendOnce') as string)?.telephone === phoneNumber
  const resendDisable = isResendOnce || isResendOnceGlobal

  const handleResendCode = () => {
    const savedItems = {
      isResendOnce: true,
      telephone: phoneNumber,
    }

    if (!timer) {
      if (!isResendOnce && !isResendOnceGlobal) {
        setTimer(RESEND_CODE_TIMER)
        dispatch(signIn({ telephone: formatPhoneNumber(String(phoneNumber)) }))
        localStorage.setItem('isResendOnce', JSON.stringify(savedItems))
        setIsResendOnce(true)
      } else if (resendDisable) {
        dispatch(setIsFromConfirm(true))
        setModalState(EModalState.Login)
      } else {
        showNotification(ToastVersions.error, 'limit_one')
      }
    }
  }

  const onSubmit: SubmitHandler<TConfirmForm> = useCallback(async () => {
    const result = await dispatch(confirmAuth({ code: otp, telephone: String(phoneNumber) }))

    if (!!result.payload?.token) {
      localStorage.removeItem('tel')
      localStorage.removeItem('isResendOnce')
      setModalState(EModalState.Cabinet)
    }
  }, [dispatch, otp, phoneNumber, setModalState])

  const openRegister = useCallback(() => {
    if (resendDisable) {
      setModalState(EModalState.Login)
      dispatch(setIsFromConfirm(true))
    } else {
      setModalState(EModalState.Register)
    }
  }, [dispatch, resendDisable, setModalState])

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer(prev => prev - 1)
      }, 1000)

      return () => clearInterval(countdown)
    }
  }, [timer])

  useEffect(() => {
    if (otp.length === 4) {
      handleSubmit(onSubmit)()
    }
  }, [handleSubmit, onSubmit, otp])

  return (
    <Fragment>
      <div className={styles.wrapper_container}>
        <Text className={styles.wrapper_title} text='confirm_phone' />
        <Text className={styles.wrapper_subtitle} variable={{ var: phoneNumber }} text='sms_sent' />
      </div>

      <form className={styles.wrapper_form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.wrapper_container_center}>
          <OTPInput
            value={otp}
            numInputs={4}
            inputType='number'
            onChange={setOtp}
            containerStyle={{ gap: '8px' }}
            inputStyle={styles.wrapper__input}
            renderInput={props => <input {...props} />}
          />

          <CodeNotComing />
        </div>

        <div className={styles.wrapper_button_container}>
          <Button
            type='submit'
            isLoading={confirmSlice.loading}
            className={styles.wrapper_button}
            disabled={otp.length !== 4}
          >
            confirm
          </Button>

          <Button
            type='button'
            textVar={{ timer: timer }}
            variant='secondary'
            onClick={handleResendCode}
            disabled={!!timer || resendDisable}
            isLoading={loginState.loading}
            className={styles.wrapper_button}
          >
            {timer && !resendDisable ? 'resend_code' : 'resend'}
          </Button>
        </div>
      </form>

      <div className={styles.wrapper_footer}>
        <Text className={styles.wrapper_footer_text} text={resendDisable ? 'no_sms' : 'no_account'} />

        <Text
          role='button'
          onClick={openRegister}
          className={styles.wrapper_footer_link}
          text={resendDisable ? 'login_with_password' : 'register'}
        />
      </div>
    </Fragment>
  )
}

export default Confirm
