import { useRef, type FC } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'motion/react'

import { capitalize } from 'lodash'
import { useButtonClickScroll, useResponsive } from 'hooks'
import { ChevronLeftIcon, ChevronRightIcon } from 'assets'
import type { TProductItem } from 'store/products/types'
import { Button, ProductCard, ProductCardSkeleton, RoundedCard, RoundedCardSkeleton, Text } from 'components'

import type { TGallery } from './types'
import styles from './Gallery.module.scss'

const ITEM_WIDTH = 400

const Gallery: FC<TGallery> = ({ data, isRounded, linkName, linkTo, title, isNew, isBestseller, isLoading }) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null)
  const { isTabletSmall } = useResponsive()

  const {
    onContentScroll,
    handleNextButtonClick,
    handlePrevButtonClick,
    isPrevButtonsDisabled,
    isNextButtonsDisabled,
  } = useButtonClickScroll(scrollableContainerRef, ITEM_WIDTH)

  const renderProducts = data.map((element, index) => (
    <motion.div
      key={index}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: index * 0.1 + 0.6, duration: 0.3 }}
    >
      {isRounded ? (
        <Link to={`/products?categoryId=${element.id}`}>
          <RoundedCard image={element?.thumb_large || element?.thumb || ''} text={capitalize(element.name)} />
        </Link>
      ) : (
        <ProductCard
          className={styles.wrapper_product}
          isBestseller={isBestseller}
          isNew={isNew}
          data={element as TProductItem}
        />
      )}
    </motion.div>
  ))

  const renderSkeletons = new Array(10)
    .fill('')
    .map((_, index) =>
      isRounded ? (
        <RoundedCardSkeleton key={index} />
      ) : (
        <ProductCardSkeleton className={styles.wrapper_product} key={index} />
      )
    )

  return (
    <motion.div
      className={styles.wrapper}
      initial={{ opacity: 0 }}
      viewport={{ once: true }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.6, delay: 0.3 }}
    >
      {title ? (
        <div className={styles.wrapper_header}>
          <Text text={title} className={styles.wrapper_title} />

          {linkTo || linkName ? (
            <Link to={linkTo ?? ''}>
              <Text text={linkName ?? ''} RightIcon={ChevronRightIcon} className={styles.wrapper_link} />
            </Link>
          ) : !isTabletSmall ? (
            <div className={styles.wrapper_button_container}>
              <Button
                disabled={isPrevButtonsDisabled}
                onClick={handlePrevButtonClick}
                variant='secondary'
                LeftIcon={ChevronLeftIcon}
              />
              <Button
                disabled={isNextButtonsDisabled}
                onClick={handleNextButtonClick}
                variant='secondary'
                LeftIcon={ChevronRightIcon}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      <div onScroll={onContentScroll} ref={scrollableContainerRef} className={styles.wrapper_container}>
        {isLoading && data.length === 0 ? renderSkeletons : renderProducts}
      </div>
    </motion.div>
  )
}

export default Gallery
