import { Fragment, useState, type FC } from 'react'
import { motion } from 'motion/react'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useResponsive } from 'hooks'
import { Button, NotFoundImage } from 'components'
import { ChevronDownIcon, ChevronUpIcon } from 'assets'

import type { TProductDetailImagesProps } from './types'
import styles from './ProductDetailImages.module.scss'

const ProductDetailImages: FC<TProductDetailImagesProps> = ({ images }) => {
  const { isTabletSmall } = useResponsive()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const visibleItems = isExpanded ? images : images?.slice(0, 4)

  const toggleExpandImages = () => {
    setIsExpanded(prev => !prev)
  }

  const renderMediaItem = (media: any, index: number) => {
    const isVideo = !!media.video
    const mediaProps = isVideo ? { src: media.thumb, type: 'video/mp4' } : { src: media.thumb, alt: `Image ${index}` }
    const className = classNames(styles.wrapper_image, {
      [styles.wrapper_image_single]: images?.length === 1,
    })

    return (
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: index * 0.1, duration: 0.3 }}
      >
        {isVideo ? (
          <video className={className} onTouchStart={e => e.stopPropagation()} width='100%' height='100%' controls>
            <source {...mediaProps} />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img width='100%' height='100%' className={className} {...mediaProps} />
        )}
      </motion.div>
    )
  }

  return (
    <div className={styles.wrapper}>
      {isTabletSmall ? (
        <Swiper grabCursor className={styles.wrapper_swiper} spaceBetween={10} slidesPerView={1}>
          {Number(images?.length) > 0 ? (
            images?.map((media, index) => <SwiperSlide key={index}>{renderMediaItem(media, index)}</SwiperSlide>)
          ) : (
            <SwiperSlide>
              <NotFoundImage className={styles.wrapper_image_single} />
            </SwiperSlide>
          )}
        </Swiper>
      ) : (
        <Fragment>
          <motion.div
            initial='hidden'
            animate='visible'
            variants={{
              hidden: {},
              visible: {
                transition: {
                  staggerChildren: 0.1,
                },
              },
            }}
            className={classNames(styles.wrapper_container, {
              [styles.wrapper_container_single]: Number(images?.length) < 2,
            })}
          >
            {Number(images?.length) > 0 ? (
              visibleItems?.map(renderMediaItem)
            ) : (
              <NotFoundImage className={styles.wrapper_image_single} />
            )}
          </motion.div>

          {Number(images?.length) > 4 && (
            <Button
              size='medium'
              isMaxContent
              variant='secondary'
              onClick={toggleExpandImages}
              RightIcon={isExpanded ? ChevronUpIcon : ChevronDownIcon}
            >
              {isExpanded ? 'collapse' : 'show_more_photos'}
            </Button>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default ProductDetailImages
