import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'

import { getUserToken, getLanguageFromLocalStorage } from 'utils'

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-LOCALE': getLanguageFromLocalStorage() ?? 'uk',
  },
})

api.interceptors.request.use(
  config => {
    const token = getUserToken()

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

export default api
