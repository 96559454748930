import { createSlice } from '@reduxjs/toolkit'

import {
  fetchCountries,
  getAddresses,
  getCategories,
  getCities,
  getCustomers,
  getLanguages,
  getPaymentMethods,
  getWarehouses,
} from './actions'
import type { TSelectState } from './types'

const initialState: TSelectState = {
  countries: {
    data: null,
    meta: null,
    error: null,
    loading: false,
  },

  languages: {
    data: null,
    meta: null,
    error: null,
    loading: false,
  },

  paymentMethodsState: {
    data: null,
    error: null,
    loading: false,
  },

  categories: {
    data: null,
    meta: null,
    error: null,
    loading: false,
  },
  customers: {
    data: null,
    meta: null,
    error: null,
    loading: false,
  },
  cities: {
    data: null,
    meta: null,
    error: null,
    loading: false,
  },
  addresses: {
    data: null,
    meta: null,
    error: null,
    loading: false,
  },
  warehouses: {
    data: null,
    meta: null,
    error: null,
    loading: false,
  },
  custom: {
    data: null,
    meta: null,
    error: null,
    loading: false,
  },
}

const selectSlice = createSlice({
  name: 'select',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries.loading = false
        state.countries.data = action.payload

        if (Number(action?.meta?.arg?.page) > 1) {
          state.countries.data = [...state.countries.data, ...action.payload.data]
        } else {
          state.countries.data = action.payload.data
        }
      })
      .addCase(fetchCountries.pending, state => {
        state.countries.loading = true
        state.countries.error = null
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.countries.loading = false
        state.countries.error = action.error.message
      })

      //

      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethodsState.loading = false
        state.paymentMethodsState.data = action.payload as any
      })
      .addCase(getPaymentMethods.pending, state => {
        state.paymentMethodsState.loading = true
        state.paymentMethodsState.error = null
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.paymentMethodsState.error = action.error.message
      })

      //

      .addCase(getLanguages.fulfilled, (state, action) => {
        state.languages.loading = false
        state.languages.data = action.payload.data
      })
      .addCase(getLanguages.pending, state => {
        state.languages.loading = true
        state.languages.error = null
      })
      .addCase(getLanguages.rejected, (state, action) => {
        state.languages.error = action.error.message
      })

      //

      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories.loading = false
        state.categories.meta = action.payload.meta

        if (Number(action?.meta?.arg?.page) > 1) {
          state.categories.data = [...state.categories.data, ...action.payload.data]
        } else {
          state.categories.data = action.payload.data
        }
      })
      .addCase(getCategories.pending, state => {
        state.categories.loading = true
        state.categories.error = null
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.categories.error = action.error.message
      })

      //

      .addCase(getCustomers.fulfilled, (state, action) => {
        state.customers.loading = false
        state.customers.meta = action.payload.meta

        if (Number(action?.meta?.arg?.page) > 1) {
          state.customers.data = [...state.customers.data, ...action.payload.data]
        } else {
          state.customers.data = action.payload.data
        }
      })
      .addCase(getCustomers.pending, state => {
        state.customers.loading = true
        state.customers.error = null
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.customers.error = action.error.message
      })

      //

      .addCase(getCities.fulfilled, (state, action) => {
        state.cities.loading = false
        state.cities.meta = action.payload.meta

        if (Number(action?.meta?.arg?.page) > 1) {
          state.cities.data = [...state.cities.data, ...action.payload.data]
        } else {
          state.cities.data = action.payload.data
        }
      })
      .addCase(getCities.pending, state => {
        state.cities.loading = true
        state.cities.error = null
      })
      .addCase(getCities.rejected, (state, action) => {
        state.cities.error = action.error.message
      })

      //

      .addCase(getAddresses.fulfilled, (state, action) => {
        state.addresses.loading = false
        state.addresses.meta = action.payload.meta

        if (Number(action?.meta?.arg?.page) > 1) {
          state.addresses.data = [...state.addresses.data, ...action.payload.data]
        } else {
          state.addresses.data = action.payload.data
        }
      })
      .addCase(getAddresses.pending, state => {
        state.addresses.loading = true
        state.addresses.error = null
      })
      .addCase(getAddresses.rejected, (state, action) => {
        state.addresses.error = action.error.message
      })

      //

      .addCase(getWarehouses.fulfilled, (state, action) => {
        state.warehouses.loading = false
        state.warehouses.meta = action.payload.meta

        if (Number(action?.meta?.arg?.page) > 1) {
          state.warehouses.data = [...state.warehouses.data, ...action.payload.data]
        } else {
          state.warehouses.data = action.payload.data
        }
      })
      .addCase(getWarehouses.pending, state => {
        state.warehouses.loading = true
        state.warehouses.error = null
      })
      .addCase(getWarehouses.rejected, (state, action) => {
        state.warehouses.error = action.error.message
      })
  },
})

const selectReducer = selectSlice.reducer

export default selectReducer
