import { createSlice } from '@reduxjs/toolkit'

import { fetchSearchResults, getFilterOptionsList, getFilterSingleOptionList } from './actions'

import type { TFilterState } from './types'

const initialState: TFilterState = {
  searchResults: {
    data: null,
    meta: null,
    error: null,
    prices: null,
    loading: false,
  },

  filterSingleOption: {
    data: {},
    error: null,
    loading: false,
  },

  filterOptionsList: {
    data: null,
    error: null,
    loading: false,
  },
}

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.searchResults.loading = false
        state.searchResults.meta = action.payload.meta
        state.searchResults.prices = action.payload.prices
        state.searchResults.data = action.payload.data
      })
      .addCase(fetchSearchResults.pending, state => {
        state.searchResults.loading = true
        state.searchResults.error = null
      })
      .addCase(fetchSearchResults.rejected, (state, action) => {
        state.searchResults.data = null
        state.searchResults.loading = false
        state.searchResults.error = action.error.message
      })

      //

      .addCase(getFilterOptionsList.fulfilled, (state, action) => {
        state.filterOptionsList.loading = false
        state.filterOptionsList.data = action.payload
      })
      .addCase(getFilterOptionsList.pending, state => {
        state.filterOptionsList.loading = true
        state.filterOptionsList.error = null
      })
      .addCase(getFilterOptionsList.rejected, (state, action) => {
        state.filterOptionsList.loading = false
        state.filterOptionsList.error = action.error.message
      })

      //

      .addCase(getFilterSingleOptionList.fulfilled, (state, action) => {
        state.filterSingleOption.loading = false

        if (action.meta.arg.option_id) {
          state.filterSingleOption.data[action.meta.arg.option_id as any] = action.payload
        }
      })
      .addCase(getFilterSingleOptionList.pending, state => {
        state.filterSingleOption.loading = true
        state.filterSingleOption.error = null
      })
      .addCase(getFilterSingleOptionList.rejected, (state, action) => {
        state.filterSingleOption.loading = false
        state.filterSingleOption.error = action.error.message
      })
  },
})

const filterReducer = filterSlice.reducer

export default filterReducer
