import { createSlice } from '@reduxjs/toolkit'

import type { TOrderInitialState } from './types'
import {
  addOrderedProduct,
  addToOrder,
  cancelOrdering,
  deleteOrderedProduct,
  getOrderedProductDetails,
  getOrderedProductsList,
  updateOrderInfo,
} from './actions'

const initialState: TOrderInitialState = {
  orderSend: {
    data: null,
    error: null,
    loading: false,
  },

  orderDetail: {
    data: null,
    error: null,
    loading: false,
  },

  orderCancel: {
    data: null,
    error: null,
    loading: false,
  },

  orderUpdate: {
    data: null,
    error: null,
    loading: false,
  },

  orderList: {
    meta: null,
    data: null,
    error: null,
    loading: false,
  },

  orderedProductDelete: {
    error: null,
    loading: false,
  },

  orderedProductAdd: {
    data: null,
    error: null,
    loading: false,
  },
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(addToOrder.pending, state => {
        state.orderSend.loading = true
        state.orderSend.error = null
      })
      .addCase(addToOrder.fulfilled, (state, action) => {
        state.orderSend.loading = false
        state.orderSend.data = action.payload
      })
      .addCase(addToOrder.rejected, (state, action) => {
        state.orderSend.loading = false
        state.orderSend.error = action.error
      })

      //

      .addCase(getOrderedProductsList.pending, state => {
        state.orderList.loading = true
        state.orderList.error = null
      })
      .addCase(getOrderedProductsList.fulfilled, (state, action) => {
        state.orderList.loading = false
        state.orderList.data = action.payload.data
        state.orderList.meta = action.payload.meta
      })
      .addCase(getOrderedProductsList.rejected, (state, action) => {
        state.orderList.loading = false
        state.orderList.error = action.error
      })

      //

      .addCase(getOrderedProductDetails.pending, state => {
        state.orderDetail.loading = true
        state.orderDetail.error = null
      })
      .addCase(getOrderedProductDetails.fulfilled, (state, action) => {
        state.orderDetail.loading = false
        state.orderDetail.data = action.payload.data
      })
      .addCase(getOrderedProductDetails.rejected, (state, action) => {
        state.orderDetail.loading = false
        state.orderDetail.error = action.error
      })

      //

      .addCase(cancelOrdering.pending, state => {
        state.orderCancel.loading = true
        state.orderCancel.error = null
      })
      .addCase(cancelOrdering.fulfilled, (state, action) => {
        state.orderCancel.loading = false
        state.orderCancel.data = action.payload.data

        //Change current item in list after cancelling
        if (state.orderList.data) {
          state.orderList.data = state.orderList.data.map(element =>
            element.order_id === action.payload?.data?.order_id ? action.payload?.data : element
          )
        }
      })
      .addCase(cancelOrdering.rejected, (state, action) => {
        state.orderCancel.loading = false
        state.orderCancel.error = action.error
      })

      //

      .addCase(updateOrderInfo.pending, state => {
        state.orderUpdate.loading = true
        state.orderUpdate.error = null
      })
      .addCase(updateOrderInfo.fulfilled, (state, action) => {
        state.orderUpdate.loading = false
        state.orderUpdate.data = action.payload.data

        //Change current item in list after cancelling
        if (state.orderList.data) {
          state.orderList.data = state.orderList.data.map(element =>
            element.order_id === action.payload?.data?.order_id ? action.payload?.data : element
          )
        }
      })
      .addCase(updateOrderInfo.rejected, (state, action) => {
        state.orderUpdate.loading = false
        state.orderUpdate.error = action.error
      })

      //

      .addCase(deleteOrderedProduct.pending, state => {
        state.orderedProductDelete.loading = true
        state.orderedProductDelete.error = null
      })
      .addCase(deleteOrderedProduct.fulfilled, (state, action) => {
        state.orderedProductDelete.loading = false

        //Change current item in list after deleting
        if (state.orderList.data) {
          state.orderList.data = state.orderList.data.map(order => {
            if (order.order_id === action.meta?.arg?.order_id) {
              return {
                ...order,
                products: order.products?.filter(
                  product => product.order_product_id !== action.meta?.arg?.order_product_id
                ),
              }
            }

            return order
          })
        }
      })
      .addCase(deleteOrderedProduct.rejected, (state, action) => {
        state.orderedProductDelete.loading = false
        state.orderedProductDelete.error = action.error
      })

      //

      .addCase(addOrderedProduct.pending, state => {
        state.orderedProductAdd.loading = true
        state.orderedProductAdd.error = null
      })
      .addCase(addOrderedProduct.fulfilled, (state, action) => {
        state.orderedProductAdd.loading = false
        state.orderedProductAdd.data = action.payload.data

        if (state.orderList.data) {
          state.orderList.data = state.orderList.data.map(order => {
            // Check if the product with the same order_product_id already exists
            const productExists = order.products?.some(
              product => product.order_product_id === action.payload?.data?.order_product_id
            )

            return {
              ...order,
              products: productExists
                ? order.products.map(product =>
                    product.order_product_id === action.payload?.data?.order_product_id
                      ? { ...product, ...action.payload?.data } // Update the existing product
                      : product
                  )
                : [...order.products, action.payload?.data], // Add the new product
            }

            return order
          })
        }
      })
      .addCase(addOrderedProduct.rejected, (state, action) => {
        state.orderedProductAdd.loading = false
        state.orderedProductAdd.error = action.error
      })
  },
})

const orderSliceReducer = orderSlice.reducer

export default orderSliceReducer
