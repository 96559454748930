import type { RootState } from 'types'

const custom = (state: RootState) => state.select.custom
const citiesState = (state: RootState) => state.select.cities
const languagesState = (state: RootState) => state.select.languages
const countriesState = (state: RootState) => state.select.countries
const addressesState = (state: RootState) => state.select.addresses
const customersState = (state: RootState) => state.select.customers
const categoriesState = (state: RootState) => state.select.categories
const warehousesState = (state: RootState) => state.select.warehouses
const paymentMethodsState = (state: RootState) => state.select.paymentMethodsState

export const SelectComponentSelectors = {
  custom,
  citiesState,
  countriesState,
  languagesState,
  addressesState,
  customersState,
  categoriesState,
  warehousesState,
  paymentMethodsState,
}
