import { useEffect, useState } from 'react'
import { capitalize } from 'lodash'

import { findCategoryPath } from 'utils'
import type { TCategoryData } from 'store/categories/types'
import { fetchCategoryTreeV1 } from 'store/categories/actions'
import { CategoriesSelectors } from 'store/categories/selectors'

import { useAppDispatch, useAppSelector } from './useTypedSelector'

const useCategoryBreadcrumb = (id: string | undefined, isMain?: boolean) => {
  const dispatch = useAppDispatch()
  const { data: tree, loading: treeLoading } = useAppSelector(CategoriesSelectors.treeData)

  const [breadcrumbs, setBreadcrumbs] = useState<TCategoryData[]>([])

  const currentCategories = breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].childrens : tree
  const currentCategoryTitle = breadcrumbs.length > 0 ? capitalize(breadcrumbs[breadcrumbs.length - 1].name) : 'catalog'

  useEffect(() => {
    if (!tree) {
      dispatch(fetchCategoryTreeV1())
    }
  }, [dispatch, tree])

  useEffect(() => {
    if (isMain) {
      setBreadcrumbs([])

      return
    }

    if (tree && id) {
      const numericId = Number(id)
      if (!isNaN(numericId)) {
        const path = findCategoryPath(tree, numericId)
        if (path) setBreadcrumbs(path)
      }
    }
  }, [id, isMain, tree])

  return {
    breadcrumbs,
    treeLoading,
    setBreadcrumbs,
    currentCategories,
    currentCategoryTitle,
  }
}

export default useCategoryBreadcrumb
