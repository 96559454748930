import { useLayoutEffect, type FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import { store } from 'libraries/redux'
import { RoutesWrapper } from 'libraries/router'
import ToastServices from 'libraries/toastify/toastServices'

import './styles/index.global.scss'

const App: FC = () => {
  const APP_VERSION = '1.0.0'

  useLayoutEffect(() => {
    const storedVersion = localStorage.getItem('app_version')

    if (storedVersion !== APP_VERSION) {
      localStorage.clear()
      localStorage.setItem('app_version', APP_VERSION)
      window.location.reload()
    }
  }, [])

  return (
    <Provider store={store}>
      <BrowserRouter>
        <RoutesWrapper />
        <ToastServices />
      </BrowserRouter>
    </Provider>
  )
}

export default App
