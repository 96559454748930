import { useState, type UIEvent } from 'react'

const useButtonClickScroll = (scrollableContainerRef: any, itemWidth: number) => {
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0)

  const maxScrollLeft =
    Number(scrollableContainerRef?.current?.scrollWidth) - Number(scrollableContainerRef?.current?.clientWidth)

  const handleNextButtonClick = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft += itemWidth
    }
  }

  const handlePrevButtonClick = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft -= itemWidth
    }
  }

  //Need to give to onScroll event to scrollable container
  const onContentScroll = (event: UIEvent<HTMLDivElement>) => {
    setCurrentScrollPosition(event.currentTarget.scrollLeft)
  }

  //Buttons disabled conditions
  const isPrevButtonsDisabled = !currentScrollPosition
  const isNextButtonsDisabled = maxScrollLeft - 10 <= currentScrollPosition

  return {
    onContentScroll,
    handleNextButtonClick,
    handlePrevButtonClick,
    isPrevButtonsDisabled,
    isNextButtonsDisabled,
  }
}

export default useButtonClickScroll
