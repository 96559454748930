import { Fragment, type FC } from 'react'

import { Skeleton } from 'components'

import type { TSkeleton } from '../types'
import styles from './OrderItemSkeleton.module.scss'

const OrderItemSkeleton: FC<TSkeleton> = ({ skeletonCount = 1 }) => (
  <Fragment>
    {Array.from({ length: skeletonCount }).map((_, index) => (
      <div key={index} className={styles.wrapper}>
        <div className={styles.wrapper_left}>
          <Skeleton className={styles.wrapper_image} width='72px' height='72px' />
        </div>

        <div className={styles.wrapper_right}>
          <div className={styles.wrapper_right_container}>
            <Skeleton width='100%' height='24px' />
          </div>

          <Skeleton width='30px' height='16px' />
        </div>
      </div>
    ))}
  </Fragment>
)

export default OrderItemSkeleton
