import { Fragment, useEffect, useRef, useState, type FC } from 'react'
import _ from 'lodash'

import { getUserToken } from 'utils'
import { TWarehouse } from 'store/products/types'
import { useAppDispatch, useAppSelector } from 'hooks'
import { Button, Checkbox, Modal, Text } from 'components'
import { ProductsSelectors } from 'store/products/selectors'
import { getSubscribedOptions, setSubscribeToProduct } from 'store/products/action'

import type { TSubscribeToProductProps } from './types'
import styles from './SubscribeToProduct.module.scss'

const SubscribeToProduct: FC<TSubscribeToProductProps> = ({ show, onClose, product }) => {
  const token = getUserToken()
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector(ProductsSelectors.subscribe)

  const prevSelectedOptions = useRef<number[]>([])

  const [selectedOptions, setSelectedOption] = useState<number[]>([])

  const onCheckboxChange = (product_option_value_id: number) => {
    if (selectedOptions.includes(product_option_value_id)) {
      setSelectedOption(prev => prev.filter(element => element !== product_option_value_id))
    } else {
      setSelectedOption(prev => [...prev, product_option_value_id])
    }
  }

  const haveSameElements = (arr1: number[], arr2: number[]) => {
    return _.isEqual(_.sortBy(arr1), _.sortBy(arr2))
  }

  const onNotifyClick = () => {
    const params = {
      product_id: Number(product?.product_id),
      product_option_value_id: selectedOptions,
    }

    dispatch(setSubscribeToProduct(params)).then(res => {
      if (res.payload) {
        prevSelectedOptions.current = selectedOptions
        onClose()
      }
    })
  }

  const renderStoreItems = (warehouse: TWarehouse) =>
    warehouse?.options?.map((element, index) => (
      <Checkbox
        key={index}
        label={element.name}
        checked={selectedOptions?.includes(element.product_option_value_id)}
        onChange={() => onCheckboxChange(element.product_option_value_id)}
      />
    ))

  useEffect(() => {
    if (product?.product_id && token) {
      dispatch(getSubscribedOptions(Number(product?.product_id))).then(res => {
        prevSelectedOptions.current = res?.payload?.product_option_value_ids || []
        setSelectedOption(res?.payload?.product_option_value_ids)
      })
    }
  }, [dispatch, product?.product_id, token])

  return (
    <Modal closeIcon className={styles.wrapper} show={show} onClose={onClose}>
      <div className={styles.wrapper_title_container}>
        <Text text='select_size_short' className={styles.wrapper_title} />
        <Text text='after_selection_press_notify' className={styles.wrapper_subtitle} />
      </div>

      <div className={styles.wrapper_container}>
        {product?.allOptions?.map(({ warehouses, option_id }) => (
          <div key={option_id} className={styles.wrapper_checkboxes}>
            {warehouses?.map(element => (
              <Fragment key={element.warehouse_id}>
                <Text text={element.name} className={styles.wrapper_checkboxes_title} />
                {renderStoreItems(element)}
              </Fragment>
            ))}
          </div>
        ))}
      </div>

      <div className={styles.wrapper_bottom}>
        <Button
          disabled={
            (selectedOptions?.length === 0 && prevSelectedOptions.current.length === 0) ||
            haveSameElements(prevSelectedOptions.current, selectedOptions)
          }
          isLoading={loading}
          onClick={onNotifyClick}
        >
          notify_availability
        </Button>
      </div>
    </Modal>
  )
}

export default SubscribeToProduct
