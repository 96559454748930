import type { RootState } from 'types'

const searchResults = (state: RootState) => state.filter.searchResults
const filterOptionsList = (state: RootState) => state.filter.filterOptionsList
const filterSingleOption = (state: RootState) => state.filter.filterSingleOption

export const FilterSelectors = {
  searchResults,
  filterOptionsList,
  filterSingleOption,
}
