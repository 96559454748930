import { useMemo, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'

import { Sidebar } from 'components'
import { useAppSelector } from 'hooks'
import { OrderSelectors } from 'store/order/selectors'

import Detail from './Detail'
import EditOrder from './EditOrder'
import ProductEdit from './ProductEdit'
import type { TOrderDetails } from './types'

const OrderDetails: FC<TOrderDetails> = ({ onClose, isOpened, orderId, state, setSidebarState }) => {
  const { t } = useTranslation()
  const { data } = useAppSelector(OrderSelectors.orderListState)
  const item = useMemo(() => data?.find(element => element?.order_id === Number(orderId)), [data, orderId])

  const states = {
    detail: <Detail setSidebarState={setSidebarState} item={item} />,
    edit: <EditOrder setSidebarState={setSidebarState} item={item} />,
    productEdit: <ProductEdit setSidebarState={setSidebarState} item={item} />,
  }

  const isProductEdit = state === 'productEdit'

  const onBackClick = () => {
    setSidebarState('edit')
  }

  return (
    <Sidebar
      isTitleCenter
      onClose={onClose}
      isOpen={isOpened}
      onBack={isProductEdit ? onBackClick : undefined}
      title={isProductEdit ? 'edit_products' : t('order_number_var', { var: item?.order_id ?? '0' })}
    >
      <AnimatePresence mode='wait'>
        <motion.div
          key={state}
          exit={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -20 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          {states[state]}
        </motion.div>
      </AnimatePresence>
    </Sidebar>
  )
}

export default OrderDetails
