import { ERoutePaths } from 'libraries/router/types'
import { CartOutlineIcon, HeartOutlineIcon, HomeOutlineIcon, ListBulletedIcon, UserOutlineIcon } from 'assets'

export const mobileNavigationTabs = [
  {
    id: 1,
    text: 'home',
    nav: ERoutePaths.Home,
    icon: HomeOutlineIcon,
  },

  {
    id: 2,
    text: 'catalog',
    icon: ListBulletedIcon,
  },

  {
    id: 3,
    text: 'cart',
    nav: ERoutePaths.Cart,
    icon: CartOutlineIcon,
  },

  {
    id: 4,
    text: 'favorites',
    nav: ERoutePaths.Wishlist,
    icon: HeartOutlineIcon,
  },
  {
    id: 5,
    text: 'cabinet',
    icon: UserOutlineIcon,
  },
]
