import type { FC } from 'react'
import { motion } from 'motion/react'

import { AdvantageCard, Text } from 'components'

import { advantages } from './utils'
import styles from './OurAdvantages.module.scss'

const OurAdvantages: FC = () => {
  const renderItems = advantages.map(({ id, Icon, title, subtitle }) => (
    <AdvantageCard key={id} Icon={Icon} title={title} subtitle={subtitle} />
  ))

  return (
    <motion.div
      className={styles.wrapper}
      initial={{ opacity: 0 }}
      viewport={{ once: true }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <div className={styles.wrapper_header}>
        <Text className={styles.wrapper_header_title} text='our_advantages' />
        <Text className={styles.wrapper_header_subtitle} text='why_choose_us' />
      </div>

      <div className={styles.wrapper_container}>{renderItems}</div>
    </motion.div>
  )
}

export default OurAdvantages
