import { createAsyncThunk } from '@reduxjs/toolkit'

import api from 'libraries/axios'

import type { TFilterOptionsList, TFilterOptionsSet, TSearchProductForm } from './types'

export const fetchSearchResults = createAsyncThunk(
  'products/search',
  async (searchProductForm: TSearchProductForm, { rejectWithValue }) => {
    try {
      const response = await api.post('/v2/products/search', searchProductForm)

      return response
    } catch (error: any) {
      rejectWithValue(error)

      return error.response
    }
  }
)

export const getFilterSingleOptionList = createAsyncThunk(
  'product/filter-options-single',

  async ({ option_id, category_id, product_filter }: TFilterOptionsSet, { rejectWithValue }) => {
    try {
      let url = `/v2/categories/filter-options/${option_id}/option-values`

      if (category_id) {
        url = `/v2/categories/filter-options/${option_id}/option-values?category_id=${category_id}`
      } else if (product_filter) {
        url = `/v2/categories/filter-options/${option_id}/option-values?product_filter=${product_filter}`
      }

      const response = await api.get(url)

      return response.data
    } catch (error) {
      rejectWithValue(error)

      return error
    }
  }
)

export const getFilterOptionsList = createAsyncThunk(
  'product/filter-options-list',
  async ({ category_id, product_filter }: TFilterOptionsList, { rejectWithValue }) => {
    try {
      let url = '/v2/categories/filter-options'

      if (category_id) {
        url = `/v2/categories/filter-options?category_id=${category_id}`
      } else if (product_filter) {
        url = `/v2/categories/filter-options?product_filter=${product_filter}`
      }

      const response = await api.get(url)

      return response.data
    } catch (error) {
      rejectWithValue(error)

      return error
    }
  }
)
