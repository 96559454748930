import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import russianDataSet from 'libraries/locales/ru.json'
import ukDataSet from 'libraries/locales/uk.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: !localStorage.getItem('i18nextLng') ? 'uk' : '',
    fallbackLng: 'uk',
    resources: {
      ru: {
        translation: russianDataSet,
      },
      uk: {
        translation: ukDataSet,
      },
    },
  })

export default i18n
