import { Skeleton } from 'components';

import styles from './PrivacyPolicySkeleton.module.scss';

const PrivacyPolicySkeleton = () => (
  <section className={styles.wrapper}>
    <div className={styles.wrapper_head}>
      <Skeleton width="60%" height="24px" />
      <Skeleton width="80%" height="36px" />
      <Skeleton width="40%" height="24px" />
    </div>
    <Skeleton width="100%" height="200px" />
  </section>
);

export default PrivacyPolicySkeleton;
