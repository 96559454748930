import { Fragment, useState, type FC } from 'react'
import classNames from 'classnames'
import { Trans } from 'react-i18next'
import { useDropzone } from 'react-dropzone'

import { CloudUploadIcon } from 'assets'
import { ImageCrop, Text } from 'components'

import type { TImageSelectProps } from './types'
import styles from './ImageSelect.module.scss'

const ImageSelect: FC<TImageSelectProps> = ({ setFiles, isMultiple = false, isWithCrop, className }) => {
  const [imageSrc, setImageSrc] = useState<any>(null)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] },
    maxSize: 50 * 1024 * 1024, // 50MB
    multiple: isMultiple,
    onDrop: acceptedFiles => {
      if (isWithCrop) {
        const reader = new FileReader()
        reader.onload = () => {
          setImageSrc(reader.result)
        }
        reader.readAsDataURL(acceptedFiles[0])
      } else {
        setFiles(acceptedFiles[0])
      }
    },
  })

  return (
    <Fragment>
      <div
        {...getRootProps()}
        className={classNames(styles.wrapper, className, { [styles.wrapper_active]: isDragActive })}
      >
        <input {...getInputProps()} />

        <div className={styles.wrapper_icon}>
          <CloudUploadIcon />
        </div>

        <div className={styles.wrapper_container}>
          <p className={styles.wrapper_text}>
            <Trans
              i18nKey='upload_image'
              components={{
                1: <span className={styles.wrapper_text_special} />,
              }}
            />
          </p>

          <Text text='image_formats' className={styles.wrapper_format} />
        </div>
      </div>

      {imageSrc && isWithCrop && <ImageCrop setImageSrc={setImageSrc} imageSrc={imageSrc} setFile={setFiles} />}
    </Fragment>
  )
}

export default ImageSelect
