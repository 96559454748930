import { useRef, useState, type FC } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'motion/react'

import { Text } from 'components'
import { ChevronDownIcon, GlobeIcon } from 'assets'
import { useLanguageTranslation, useOnClickOutside } from 'hooks'

import type { TChangeLanguage } from './types'
import { languagesDropdownUtils } from './utils'
import styles from './ChangeLang.module.scss'

const ChangeLang: FC<TChangeLanguage> = ({ className }) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const [dropActive, setDropActive] = useState<boolean>(false)
  const { onLanguageChangeCallback, language, isUkrainian } = useLanguageTranslation()

  const onOutsideClick = () => {
    setDropActive(false)
  }

  const onDropdownClick = () => {
    setDropActive(!dropActive)
  }

  const onLanguageClickCallback = (lang: string, language_id: number) => () => {
    setDropActive(false)
    onLanguageChangeCallback(lang, language_id)
  }

  useOnClickOutside([ref], onOutsideClick)

  const pickedLanguage = isUkrainian ? 'Українська' : 'Русский'

  const renderLanguages = languagesDropdownUtils.map(({ text, lang, language_id }) => (
    <Text
      role='button'
      text={text}
      key={language_id}
      onClick={onLanguageClickCallback(lang, language_id)}
      className={classNames(styles.wrapper_dropdown_text, { [styles.wrapper_dropdown_text_active]: language === lang })}
    />
  ))

  return (
    <div ref={ref} className={classNames(styles.wrapper, className)}>
      <div role='button' onClick={onDropdownClick} className={styles.wrapper_header}>
        <GlobeIcon />

        <Text
          text={pickedLanguage}
          className={classNames(styles.wrapper_title, { [styles.wrapper_title_icon]: dropActive })}
          RightIcon={ChevronDownIcon}
        />
      </div>

      <AnimatePresence>
        {dropActive && (
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className={styles.wrapper_dropdown}
          >
            <div className={styles.wrapper_dropdown_container}>{renderLanguages}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ChangeLang
