import { useCallback, useEffect, useRef, useState, type FC } from 'react'
import classNames from 'classnames'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { AnimatePresence, motion } from 'motion/react'

import { advancedFormatPhoneNumber } from 'utils'
import { fetchCountries } from 'store/select/actions'
import { ChevronDownIcon, SearchIcon } from 'assets'
import { Input, Loader, Skeleton, Text } from 'components'
import { SelectComponentSelectors } from 'store/select/selectors'
import { useDebounce, useOnClickOutside, useAppDispatch, useAppSelector } from 'hooks'

import type { ISelect } from './types'
import SelectMenuItem from './SelectMenuItem'
import styles from './Select.module.scss'

const Select: FC<ISelect> = ({
  register,
  methods,
  onChange,
  name,
  disabled,
  error,
  label,
  isDirty,
  hint,
  defaultValue,
  className,
}) => {
  const { data, meta, loading } = useAppSelector(SelectComponentSelectors.countriesState)
  const defaultSelectedValue = data?.find((element: any) => element.default)
  const hasNextPage = meta?.current_page !== meta?.last_page

  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<any>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(data?.meta?.current_page || 1)

  const dispatch = useAppDispatch()
  const menuRef = useRef<HTMLDivElement | null>(null)
  const debouncedSearch = useDebounce(searchValue, 600)
  const mask = selectedValue?.phone_mask.replace(/#/g, '_') || '+38(___)-___-__-__'
  const defaultOrSelected = selectedValue || defaultSelectedValue

  const handleSelect = (item: any) => {
    setSelectedValue(item)
    onChange?.(item)
    toggleShowMenu()
    setSearchValue('')
    setCurrentPage(1)
    methods.setValue(register.name, '')
  }

  useEffect(() => {
    if (!disabled) {
      dispatch(
        fetchCountries({
          page: currentPage,
          searchValue: debouncedSearch as string,
        })
      )
    }
  }, [currentPage, debouncedSearch, disabled, dispatch])

  const handleLoadMore = useCallback(() => {
    setCurrentPage(prev => prev + 1)
  }, [])

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    disabled: !!searchValue,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 20px 0px',
  })

  const renderSelectItem = data?.map((element: any) => (
    <SelectMenuItem
      key={element.id}
      handleSelect={() => handleSelect(element)}
      countryName={element.name}
      isSelected={selectedValue?.id === element.id}
      prefix={element.phone_prefix}
      flag={element.flag}
    />
  ))

  const toggleShowMenu = () => {
    if (!loading && data && !disabled) {
      setShowMenu(!showMenu)
    }
  }

  const closeMenu = () => {
    setShowMenu(false)
  }

  useOnClickOutside([menuRef], closeMenu)

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()

    const pastedText = e.clipboardData.getData('Text').replace(/\D/g, '')

    let formattedText = pastedText
    if (pastedText.length > 10) {
      formattedText = pastedText.slice(-10)
    }

    const maskedText = advancedFormatPhoneNumber(formattedText, mask)
    e.currentTarget.value = maskedText

    methods.setValue(register.name, maskedText, { shouldValidate: true })
  }

  const renderFlag = () => (
    <div role='button' className={styles.wrapper__input__lang} onClick={toggleShowMenu}>
      {defaultOrSelected?.flag ? (
        <img src={defaultOrSelected?.flag} alt='flag' className={styles.wrapper__flag} width={24} height={24} />
      ) : (
        <Skeleton isRounded width='24px' height='24px' />
      )}

      <ChevronDownIcon />
    </div>
  )

  return (
    <div ref={menuRef} className={classNames(styles.wrapper, className)}>
      <div className={styles.wrapper__input__container}>
        <Input
          mask={mask}
          error={error}
          label={label}
          name={name}
          disabled={disabled}
          isDirty={isDirty}
          LeftComponent={renderFlag()}
          placeholder={defaultOrSelected?.phone_prefix}
          className={styles.wrapper__input}
          defaultValue={defaultValue}
          hint={hint}
          register={register}
          onPaste={handlePaste}
        />
      </div>

      <AnimatePresence>
        {showMenu && (
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className={styles.wrapper__menu}
          >
            <Input
              type='text'
              name='search'
              placeholder='search'
              className={styles.wrapper__search}
              Icon={SearchIcon}
              isDirty={false}
              onChange={e => setSearchValue(e.target.value)}
              value={searchValue}
            />

            <div className={styles.wrapper__menu__container}>
              {renderSelectItem}

              {data?.length === 0 && !loading && <Text text='no_data_found' className={styles.wrapper__empty} />}

              {(loading || hasNextPage) && !searchValue && (
                <div ref={sentryRef} className={styles.wrapper__loader__container}>
                  <Loader color='#ab0000' className={styles.wrapper__loader} />
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Select
