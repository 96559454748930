import type { FC } from 'react'
import classNames from 'classnames'

import type { TWrappedIconProps } from './types'
import styles from './WrappedIcon.module.scss'

const WrappedIcon: FC<TWrappedIconProps> = ({ Icon, type }) => (
  <div className={classNames(styles.wrapper, styles[type])}>
    <Icon />
  </div>
)

export default WrappedIcon
