import { createAsyncThunk } from '@reduxjs/toolkit'

import api from 'libraries/axios'
import { showNotification, ToastVersions } from 'libraries/toastify'

import type { TAddToCartForm } from './types'

export const addToCart = createAsyncThunk('cart/add', async (formData: TAddToCartForm) => {
  try {
    const response: any = await api.post(`/v3/cart`, formData)

    return response.data
  } catch (err: any) {
    showNotification(ToastVersions.error, 'added_to_cart_error')

    return err
  }
})

export const toggleGiftPackage = createAsyncThunk('cart/gift-package', async (giftPackage: boolean) => {
  try {
    const response: any = await api.put(`v3/cart/toggle-gift-package`, { include_gift_package: giftPackage })

    return response.data
  } catch (err: any) {
    return err
  }
})

export const getCartList = createAsyncThunk('cart/list', async () => {
  try {
    const response: any = await api.get(`/v3/cart`)

    return response
  } catch (err) {
    return err
  }
})

export const removeBasketItem = createAsyncThunk('cart/remove-item', async (cartId: any) => {
  try {
    const response = await api.delete(`/v3/cart/${cartId}`)

    return response
  } catch (err) {
    showNotification(ToastVersions.error, 'Failed to remove basket')

    throw new Error('Failed to remove basket')
  }
})

export const updateBasketQuantity = createAsyncThunk(
  'cart/update',
  async ({
    quantity,
    cartOptionId,
    action = 'update',
  }: {
    cartOptionId: number
    quantity?: number
    action?: string
  }) => {
    try {
      let response

      if (action === 'update') {
        response = await api.put(`/v3/cart-option/${cartOptionId}`, {
          quantity: quantity,
        })
      } else if (action === 'delete') {
        response = await api.delete(`/v3/cart-option/${cartOptionId}`)
      }

      return response
    } catch (err) {
      throw new Error('Failed to update basket quantity')
    }
  }
)
