import type { TUser } from 'store/auth/types'

export const getUserData = () => {
  const userDataString = localStorage.getItem('userData')
  if (!userDataString) return null

  const userData: TUser = JSON.parse(userDataString)

  return userData
}

export const logout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('userData')
  window.open('/', '_self')
}

export const setUserDataToLocalStorage = (item: any) => {
  localStorage.setItem('userData', JSON.stringify(item))
}

export const setUserTokenToLocalStorage = (item: string) => {
  localStorage.setItem('token', JSON.stringify(item))
}

export const getUserToken = () => {
  const userTokenString = localStorage.getItem('token')
  if (!userTokenString) return null

  const userToken = JSON.parse(userTokenString)

  return userToken
}

export const getLanguageFromLocalStorage = () => {
  const langString = localStorage.getItem('i18nextLng')
  if (!langString) return null

  return langString
}

export const pagesToHideFooter = [{ isHideOnlyMobile: true, pathname: '/cart' }]
