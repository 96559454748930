import { Fragment, type FC } from 'react'

import { Skeleton } from 'components'

import type { TSkeleton } from '../types'
import styles from './CategoryItem.module.scss'

const CategoryItemSkeleton: FC<TSkeleton> = ({ skeletonCount = 1 }) => (
  <Fragment>
    {Array.from({ length: skeletonCount }).map((_, index) => (
      <div key={index} className={styles.wrapper}>
        <Skeleton width='24px' height='24px' isRounded />

        <Skeleton width='100%' height='24px' />
      </div>
    ))}
  </Fragment>
)

export default CategoryItemSkeleton
