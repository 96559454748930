import { createAsyncThunk } from '@reduxjs/toolkit'

import api from 'libraries/axios'
import { showNotification, ToastVersions } from 'libraries/toastify'

import type { TQrSearchParams, TSubscribeFormData } from './types'

export const fetchNewProducts = createAsyncThunk('product/new', async () => {
  try {
    const response = await api.get(`/v1/products/latest?page=1`)

    return response.data
  } catch (err) {
    throw Error('Failed to get new products')
  }
})

export const fetchProductById = createAsyncThunk('product/fetchProductById', async (productID: number) => {
  try {
    const response = await api.get(`/v1/products/${productID}/details`)

    return response.data
  } catch (err) {
    throw Error('Failed to get  product data')
  }
})

export const fetchBestsellerProducts = createAsyncThunk('product/bestseller', async () => {
  try {
    const response = await api.get(`/v2/products/best-sellers`)

    return response.data
  } catch (err) {
    throw Error('Failed to get  product data')
  }
})

export const fetchSpecialProducts = createAsyncThunk('product/special', async () => {
  try {
    const response = await api.get(`/v1/products/special`)

    return response.data
  } catch (err) {
    throw Error('Failed to get  product data')
  }
})

export const fetchStockProductsData = createAsyncThunk('product/fetchStockProductsData', async (page: number) => {
  try {
    const response = await api.get(`/v1/products/special?page=${page}`)

    return response.data
  } catch (err) {
    throw Error('Failed to get stock products data')
  }
})

export const fetchProductWarehouses = createAsyncThunk('product/fetchProductWarehouses', async (productId: number) => {
  try {
    const response = await api.get(`/v1/products/${productId}/options/combined`)

    return response
  } catch (err) {
    return err
  }
})

export const getWishlistItems = createAsyncThunk('product/fetchProductWarehouses', async (page?: number) => {
  try {
    const response = await api.get(`/v1/products/wishlist?page=${page || 1}`)

    return response
  } catch (error) {
    return error
  }
})

export const addToWishlist = createAsyncThunk('product/wishlist-add', async (productId: any) => {
  try {
    const response = await api.post(`/v1/products/wishlist/${productId}`)

    showNotification(ToastVersions.success, 'successfully_added_to_favorites')

    return response.data
  } catch (err: any) {
    showNotification(ToastVersions.error, err?.response?.message || 'add_error')

    return err
  }
})

export const removeFromWishlist = createAsyncThunk('product/wishlist-delete', async (productId: any) => {
  try {
    const response = await api.delete(`/v1/products/wishlist/${productId}`)

    showNotification(ToastVersions.success, 'successfully_removed_from_favorites')

    return response.data
  } catch (err: any) {
    showNotification(ToastVersions.success, err?.response?.message || 'delete_error')

    return err
  }
})

export const getProductDetails = createAsyncThunk('product/details', async (productId: number, { rejectWithValue }) => {
  try {
    const response = await api.get(`/v1/products/${productId}/details`)

    return response.data
  } catch (error) {
    rejectWithValue(error)

    return error
  }
})

export const getProductStatistics = createAsyncThunk(
  'product/statistics',
  async (productId: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/v1/products/${productId}/statistics`)

      return response
    } catch (error) {
      rejectWithValue(error)

      return error
    }
  }
)

export const getSubscribedOptions = createAsyncThunk(
  'products/subscribe-get',
  async (productId: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/v2/products/subscribe/${productId}/get`)

      return response.data
    } catch (error) {
      rejectWithValue(error)

      return error
    }
  }
)

export const setSubscribeToProduct = createAsyncThunk('products/subscribe-post', async (data: TSubscribeFormData) => {
  try {
    const response = await api.post(`/v2/products/subscribe/${data.product_id}/store`, {
      product_option_value_ids: data.product_option_value_id,
    })

    showNotification(ToastVersions.success, 'notify_when_available')

    return response
  } catch (error) {
    return error
  }
})

export const getProductsByQrSearch = createAsyncThunk('products/qr-search', async (params: TQrSearchParams) => {
  const url = '/v1/products/qr-search'
  const { page = 1, order_id = null, all = false, showSizes = true, search = null } = params

  try {
    let filter = '?page=1'

    if (page) {
      filter = '?page=' + page
    }

    if (search) {
      filter += '&filter_name=' + encodeURI(search)
    }

    if (all) {
      filter += '&all=' + all
    }

    if (showSizes) {
      filter += '&show_sizes=' + showSizes
    }

    if (order_id) {
      filter += '&order_id=' + order_id
    }

    return await api.get(url + filter)
  } catch (error) {
    return error
  }
})
