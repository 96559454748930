import type { FC } from 'react'
import { motion } from 'motion/react'
import { useNavigate } from 'react-router-dom'

import { StarFilledIcon } from 'assets'
import { Button, Text } from 'components'
import { ERoutePaths } from 'libraries/router/types'

import styles from './ReviewBanner.module.scss'

const ReviewBanner: FC = () => {
  const navigate = useNavigate()
  const renderStars = new Array(5).fill('').map((_, index) => <StarFilledIcon key={index} />)

  const handleProductNavigation = () => {
    navigate(ERoutePaths.Products)
  }

  return (
    <div className={styles.wrapper}>
      <motion.div
        initial={{ x: -100 }}
        viewport={{ once: true }}
        whileInView={{ x: 0 }}
        transition={{ duration: 0.6 }}
        className={styles.wrapper_left}
      >
        <Text className={styles.wrapper_left_title} text='updated_collections' />
        <Text className={styles.wrapper_left_subtitle} text='updated_assortment' />
        <Button onClick={handleProductNavigation} className={styles.wrapper_button} size='large'>
          view_new_arrivals
        </Button>
      </motion.div>

      <motion.div
        initial={{ x: 100 }}
        viewport={{ once: true }}
        whileInView={{ x: 0 }}
        transition={{ duration: 0.6 }}
        className={styles.wrapper_right}
      >
        <div className={styles.wrapper_right_container}>
          <Text className={styles.wrapper_right_title} text='great_assortment_review' />

          <div className={styles.wrapper_right_stars}>
            <div className={styles.wrapper_right_stars_container}>{renderStars}</div>

            <Text className={styles.wrapper_right_name} text='reviewer_name' />
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default ReviewBanner
