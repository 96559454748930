import { createSlice } from '@reduxjs/toolkit'

import {
  fetchNewProducts,
  getWishlistItems,
  getProductDetails,
  getProductStatistics,
  fetchSpecialProducts,
  setSubscribeToProduct,
  fetchBestsellerProducts,
  getSubscribedOptions,
  getProductsByQrSearch,
} from './action'
import type { TProductsSlice, TProductStatistics } from './types'

const initialState: TProductsSlice = {
  newProducts: {
    data: null,
    error: null,
    loading: false,
  },

  bestsellerProducts: {
    data: null,
    error: null,
    loading: false,
  },

  specialProducts: {
    data: null,
    error: null,
    loading: false,
  },

  details: {
    data: null,
    error: null,
    loading: false,
  },

  statistics: {
    data: null,
    error: null,
    loading: false,
  },

  wishlist: {
    meta: null,
    data: null,
    error: null,
    loading: false,
  },

  subscribe: {
    data: null,
    error: null,
    loading: false,
  },

  subscribeList: {
    data: null,
    error: null,
    loading: false,
  },

  qrSearch: {
    data: null,
    error: null,
    loading: false,
  },
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchNewProducts.pending, state => {
        state.newProducts.loading = true
        state.newProducts.error = null
      })
      .addCase(fetchNewProducts.fulfilled, (state, action) => {
        state.newProducts.loading = false
        state.newProducts.data = action.payload
      })
      .addCase(fetchNewProducts.rejected, (state, action) => {
        state.newProducts.loading = false
        state.newProducts.error = action.error.message
      })

      //

      .addCase(fetchBestsellerProducts.pending, state => {
        state.bestsellerProducts.loading = true
        state.bestsellerProducts.error = null
      })
      .addCase(fetchBestsellerProducts.fulfilled, (state, action) => {
        state.bestsellerProducts.loading = false
        state.bestsellerProducts.data = action.payload
      })
      .addCase(fetchBestsellerProducts.rejected, (state, action) => {
        state.bestsellerProducts.loading = false
        state.bestsellerProducts.error = action.error.message
      })

      //

      .addCase(fetchSpecialProducts.pending, state => {
        state.specialProducts.loading = true
        state.specialProducts.error = null
      })
      .addCase(fetchSpecialProducts.fulfilled, (state, action) => {
        state.specialProducts.loading = false
        state.specialProducts.data = action.payload
      })
      .addCase(fetchSpecialProducts.rejected, (state, action) => {
        state.specialProducts.loading = false
        state.specialProducts.error = action.error.message
      })

      //

      .addCase(getProductDetails.pending, state => {
        state.details.loading = true
        state.details.error = null
      })
      .addCase(getProductDetails.fulfilled, (state, action) => {
        state.details.loading = false
        state.details.data = action.payload
      })
      .addCase(getProductDetails.rejected, (state, action) => {
        state.details.loading = false
        state.details.error = action.error.message
      })

      //

      .addCase(getProductStatistics.pending, state => {
        state.statistics.loading = true
        state.statistics.error = null
      })
      .addCase(getProductStatistics.fulfilled, (state, action) => {
        state.statistics.loading = false
        state.statistics.data = action.payload as TProductStatistics
      })
      .addCase(getProductStatistics.rejected, (state, action) => {
        state.statistics.loading = false
        state.statistics.error = action.error.message
      })

      //

      .addCase(getWishlistItems.pending, state => {
        state.wishlist.loading = true
        state.wishlist.error = null
      })
      .addCase(getWishlistItems.fulfilled, (state, action: any) => {
        state.wishlist.loading = false
        state.wishlist.meta = action.payload.meta
        state.wishlist.data = action.payload.data
      })
      .addCase(getWishlistItems.rejected, (state, action) => {
        state.wishlist.loading = false
        state.wishlist.error = action.error.message
      })

      //

      .addCase(setSubscribeToProduct.pending, state => {
        state.subscribe.loading = true
        state.subscribe.error = null
      })
      .addCase(setSubscribeToProduct.fulfilled, (state, action: any) => {
        state.subscribe.loading = false
        state.subscribe.data = action.payload
      })
      .addCase(setSubscribeToProduct.rejected, (state, action) => {
        state.subscribe.loading = false
        state.subscribe.error = action.error.message
      })

      //

      .addCase(getSubscribedOptions.pending, state => {
        state.subscribeList.loading = true
        state.subscribeList.error = null
      })
      .addCase(getSubscribedOptions.fulfilled, (state, action: any) => {
        state.subscribeList.loading = false
        state.subscribeList.data = action.payload
      })
      .addCase(getSubscribedOptions.rejected, (state, action) => {
        state.subscribeList.loading = false
        state.subscribeList.error = action.error.message
      })

      //

      .addCase(getProductsByQrSearch.pending, state => {
        state.qrSearch.loading = true
        state.qrSearch.error = null
      })
      .addCase(getProductsByQrSearch.fulfilled, (state, action: any) => {
        state.qrSearch.loading = false
        state.qrSearch.data = action.payload.data
      })
      .addCase(getProductsByQrSearch.rejected, (state, action) => {
        state.qrSearch.loading = false
        state.qrSearch.error = action.error.message
      })
  },
})

export const {} = productsSlice.actions

const productsSliceReducer = productsSlice.reducer

export default productsSliceReducer
