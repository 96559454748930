import { createSlice } from '@reduxjs/toolkit'

import {
  changeCustomerAvatar,
  changeCustomerLanguage,
  fetchCostumerInformation,
  getNotificationSettings,
  savePassword,
  updateCustomerInformation,
  updateNotificationSettings,
} from './actions'
import type { TCustomerState } from './types'

const initialState: TCustomerState = {
  customer: {
    data: null,
    error: null,
    loading: false,
  },

  customerUpdate: {
    data: null,
    error: null,
    loading: false,
  },

  avatarUpdate: {
    data: null,
    error: null,
    loading: false,
  },

  langUpdate: {
    data: null,
    error: null,
    loading: false,
  },

  notificationsUpdate: {
    error: null,
    loading: false,
  },

  notificationsSettings: {
    data: null,
    error: null,
    loading: false,
  },

  savePassword: {
    error: null,
    loading: false,
  },
}

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCostumerInformation.fulfilled, (state, action) => {
        state.customer.loading = false
        state.customer.data = action.payload
      })
      .addCase(fetchCostumerInformation.pending, state => {
        state.customer.loading = true
        state.customer.error = null
      })
      .addCase(fetchCostumerInformation.rejected, (state, action) => {
        state.customer.loading = false
        state.customer.error = action.error.message
      })

      //

      .addCase(getNotificationSettings.fulfilled, (state, action) => {
        state.notificationsSettings.loading = false
        state.notificationsSettings.data = action.payload
      })
      .addCase(getNotificationSettings.pending, state => {
        state.notificationsSettings.loading = true
        state.notificationsSettings.error = null
      })
      .addCase(getNotificationSettings.rejected, (state, action) => {
        state.notificationsSettings.loading = false
        state.notificationsSettings.error = action.error.message
      })

      //

      .addCase(updateCustomerInformation.fulfilled, (state, action) => {
        state.customerUpdate.loading = false
        state.customerUpdate.data = action.payload
      })
      .addCase(updateCustomerInformation.pending, state => {
        state.customerUpdate.loading = true
        state.customerUpdate.error = null
      })
      .addCase(updateCustomerInformation.rejected, (state, action) => {
        state.customerUpdate.loading = false
        state.customerUpdate.error = action.error.message
      })

      //

      .addCase(updateNotificationSettings.fulfilled, state => {
        state.notificationsUpdate.loading = false
      })
      .addCase(updateNotificationSettings.pending, state => {
        state.notificationsUpdate.loading = true
        state.notificationsUpdate.error = null
      })
      .addCase(updateNotificationSettings.rejected, (state, action) => {
        state.notificationsUpdate.loading = false
        state.notificationsUpdate.error = action.error.message
      })

      //

      .addCase(changeCustomerAvatar.fulfilled, (state, action) => {
        state.avatarUpdate.loading = false
        state.avatarUpdate.data = action.payload
      })
      .addCase(changeCustomerAvatar.pending, state => {
        state.avatarUpdate.loading = true
        state.avatarUpdate.error = null
      })
      .addCase(changeCustomerAvatar.rejected, (state, action) => {
        state.avatarUpdate.loading = false
        state.avatarUpdate.error = action.error.message
      })

      //

      .addCase(changeCustomerLanguage.fulfilled, (state, action) => {
        state.langUpdate.loading = false
        state.langUpdate.data = action.payload
      })
      .addCase(changeCustomerLanguage.pending, state => {
        state.langUpdate.loading = true
        state.langUpdate.error = null
      })
      .addCase(changeCustomerLanguage.rejected, (state, action) => {
        state.langUpdate.loading = false
        state.langUpdate.error = action.error.message
      })

      //

      .addCase(savePassword.fulfilled, state => {
        state.savePassword.loading = false
      })
      .addCase(savePassword.pending, state => {
        state.savePassword.loading = true
        state.savePassword.error = null
      })
      .addCase(savePassword.rejected, (state, action) => {
        state.savePassword.loading = false
        state.savePassword.error = action.error.message
      })
  },
})

const customerReducer = customerSlice.reducer

export default customerReducer
