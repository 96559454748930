import type { FC } from 'react'

import { Skeleton } from 'components'

import styles from './ProductSkeleton.module.scss'

const ProductSkeleton: FC = () => (
  <div className={styles.wrapper}>
    <Skeleton className={styles.wrapper_image} width='100%' height='760px' />

    <div className={styles.wrapper__right}>
      <div className={styles.wrapper__container}>
        <Skeleton className={styles.titleSkeleton} width='60%' height='20px' />
        <Skeleton className={styles.codeSkeleton} width='40%' height='16px' />
      </div>

      <div className={styles.wrapper__container}>
        <Skeleton className={styles.priceSkeleton} width='50%' height='20px' />
        <Skeleton className={styles.recommendedPriceSkeleton} width='70%' height='16px' />
      </div>

      <div className={styles.wrapper__container}>
        <Skeleton className={styles.storeTitleSkeleton} width='60%' height='20px' />
        <Skeleton className={styles.storeItemsSkeleton} width='100%' height='80px' />
        <Skeleton className={styles.storeTitleSkeleton} width='60%' height='20px' />
        <Skeleton className={styles.storeItemsSkeleton} width='100%' height='80px' />
      </div>

      <Skeleton className={styles.notifyButtonSkeleton} width='80%' height='40px' />

      <div className={styles.wrapper__button_container}>
        <Skeleton className={styles.addButtonSkeleton} width='45%' height='40px' />
        <Skeleton className={styles.contactButtonSkeleton} width='45%' height='40px' />
      </div>

      <Skeleton className={styles.descriptionTitleSkeleton} width='70%' height='20px' />

      <div className={styles.wrapper__delivery}>
        <Skeleton className={styles.deliveryButtonSkeleton} width='80%' height='40px' />
        <Skeleton className={styles.deliverySubtitleSkeleton} width='50%' height='16px' />
      </div>
    </div>
  </div>
)

export default ProductSkeleton
