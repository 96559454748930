import type { FC } from 'react'
import { motion } from 'motion/react'

import { Text } from 'components'
import { FriendsShoppingImage } from 'assets'

import { buyMeDescription } from './utils'
import styles from './ShoppingBanner.module.scss'

const ShoppingBanner: FC = () => {
  const renderDescription = buyMeDescription.map((el, index) => (
    <Text key={index} className={styles.wrapper_subtitle} text={el} />
  ))

  return (
    <div className={styles.wrapper}>
      <motion.div
        initial={{ x: -100 }}
        viewport={{ once: true }}
        whileInView={{ x: 0 }}
        transition={{ duration: 0.6 }}
        className={styles.wrapper_image_container}
      >
        <img src={FriendsShoppingImage} alt='FriendsShoppingImage' className={styles.wrapper_image} />
      </motion.div>

      <motion.div
        initial={{ x: 100 }}
        viewport={{ once: true }}
        whileInView={{ x: 0 }}
        transition={{ duration: 0.6 }}
        className={styles.wrapper_container}
      >
        <Text className={styles.wrapper_title} text='trusted_partner' />
        <div className={styles.wrapper_description}>
          {renderDescription}
          <Text className={styles.wrapper_text} text='with_respect' />
        </div>
      </motion.div>
    </div>
  )
}

export default ShoppingBanner
