import { AccessTimeIcon, MemoryIcon, PhoneCheckIcon, PowerIcon, WifiStrengthAlertIcon } from 'assets'

export const steps = [
  {
    id: 1,
    Icon: AccessTimeIcon,
    text: 'wait_two_minutes',
  },
  {
    id: 2,
    Icon: PhoneCheckIcon,
    text: 'check_phone_number',
  },

  {
    id: 3,
    Icon: WifiStrengthAlertIcon,
    text: 'check_internet',
  },
  {
    id: 4,
    Icon: MemoryIcon,
    text: 'check_storage',
  },

  {
    id: 5,
    Icon: PowerIcon,
    text: 'restart_phone',
  },
]
