import { useState, type FC } from 'react'
import classNames from 'classnames'
import { Trans } from 'react-i18next'
import { AnimatePresence, motion } from 'motion/react'

import { Text } from 'components'
import { ChevronDownIcon, QuestionMarkIcon } from 'assets'

import { steps } from './utils'
import styles from './CodeNotComing.module.scss'

const CodeNotComing: FC = () => {
  const [showInstructions, setShowInstructions] = useState<boolean>(false)

  const toggleShow = () => {
    setShowInstructions(!showInstructions)
  }

  const renderSteps = steps.map(({ id, Icon, text }, index) => (
    <div key={id} className={styles.wrapper_item}>
      <div className={styles.wrapper_item_container}>
        <div className={styles.wrapper_item_icon}>
          <Icon />
        </div>

        <Text className={styles.wrapper_item_text} text={text} />
      </div>

      {index !== steps.length - 1 && <div className={styles.wrapper_item_line} />}
    </div>
  ))

  return (
    <div className={styles.wrapper}>
      <div role='button' onClick={toggleShow} className={styles.wrapper_header}>
        <Text className={styles.wrapper_title} text='did_not_receive_code' LeftIcon={QuestionMarkIcon} />

        <ChevronDownIcon
          className={classNames(styles.wrapper_icon, { [styles.wrapper_icon_active]: showInstructions })}
        />
      </div>

      <AnimatePresence>
        {showInstructions && (
          <motion.div
            exit={{ opacity: 0, maxHeight: 0 }}
            initial={{ opacity: 0, maxHeight: 0 }}
            animate={{ opacity: 1, maxHeight: 800 }}
            transition={{ duration: 0.3 }}
            className={styles.wrapper_container}
          >
            <Text className={styles.wrapper_item_title} text='try_next_steps' />

            <div className={styles.wrapper_item_box}>{renderSteps}</div>

            <p className={styles.wrapper_agree_terms}>
              <Trans
                i18nKey='still_no_code'
                components={{
                  1: <span className={styles.wrapper_policy} />,
                  2: <span className={styles.wrapper_policy} />,
                }}
              />
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default CodeNotComing
