import { useCallback, useEffect, useState, type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { capitalize } from 'lodash'
import { AnimatePresence, motion } from 'framer-motion'

import { useAppDispatch, useAppSelector } from 'hooks'
import { ChevronRightIcon } from 'assets'
import type { TCategoryData } from 'store/categories/types'
import { CategoriesSelectors } from 'store/categories/selectors'
import { CatalogItemSkeleton, Sidebar, Text } from 'components'

import type { TCatalogMobile } from './types'
import styles from './Catalog.module.scss'
import { toggleCatalogOpen } from 'store/global'

const CatalogMobile: FC<TCatalogMobile> = ({ isCatalogOpen, closeCatalog }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { data: tree, loading } = useAppSelector(CategoriesSelectors.treeData)

  const [isGoingBack, setGoingBack] = useState<boolean>(false)
  const [breadcrumbs, setBreadcrumbs] = useState<TCategoryData[]>([])

  const title = breadcrumbs.length > 0 ? capitalize(breadcrumbs[breadcrumbs.length - 1].name) : 'catalog'
  const currentCategories = breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].childrens : tree
  const currentCategoryId = breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].id : 0

  const onCategoryClick = (category: TCategoryData) => {
    if (category.childrens && category.childrens.length > 0) {
      setGoingBack(false)
      setBreadcrumbs([...breadcrumbs, category])
    } else {
      navigate(`/products?categoryId=${category?.id}`)
      dispatch(toggleCatalogOpen(false))
    }
  }

  const onBackClick = () => {
    setBreadcrumbs(breadcrumbs.slice(0, -1))
    setGoingBack(true)
  }

  const onViewAllClick = useCallback(() => {
    dispatch(toggleCatalogOpen(false))
    navigate(`/products?categoryId=${currentCategoryId}`)
  }, [currentCategoryId, dispatch, navigate])

  useEffect(() => {
    setBreadcrumbs([])
  }, [isCatalogOpen])

  const transitionVariants = {
    enter: (isGoingBack: boolean) => ({
      x: isGoingBack ? -20 : 20,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.3, ease: 'easeInOut' },
    },
    exit: (isGoingBack: boolean) => ({
      x: isGoingBack ? 20 : -20,
      opacity: 0,
      transition: { duration: 0.2, ease: 'easeInOut' },
    }),
  }

  return (
    <Sidebar
      title={title}
      isOpen={isCatalogOpen}
      onClose={closeCatalog}
      onBack={breadcrumbs.length > 0 ? onBackClick : undefined}
    >
      <div className={styles.sidebar}>
        {loading && !tree ? (
          <CatalogItemSkeleton skeletonCount={6} />
        ) : (
          <AnimatePresence mode='wait' custom={isGoingBack}>
            <motion.div
              key={breadcrumbs.length}
              initial='enter'
              animate='center'
              exit='exit'
              variants={transitionVariants}
              custom={isGoingBack}
              className={styles.sidebar_animation_wrapper}
            >
              {breadcrumbs.length > 0 && (
                <Text
                  role='button'
                  text='all_products'
                  onClick={onViewAllClick}
                  className={`${styles.sidebar_item} ${styles.sidebar_item_text} ${styles.sidebar_item_text_all}`}
                />
              )}
              {currentCategories?.map((category: any) => (
                <div
                  key={category.id}
                  role='button'
                  className={styles.sidebar_item}
                  onClick={() => onCategoryClick(category)}
                >
                  <div className={styles.sidebar_item_container}>
                    {category.icon && <img width={24} height={24} src={category.icon} alt={category.name} />}
                    <p className={styles.sidebar_item_text}>{capitalize(category.name)}</p>
                  </div>
                  {category.childrens?.length ? <ChevronRightIcon /> : null}
                </div>
              ))}
            </motion.div>
          </AnimatePresence>
        )}
      </div>
    </Sidebar>
  )
}

export default CatalogMobile
