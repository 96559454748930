import type { FC } from 'react'
import classNames from 'classnames'

import { NotFoundImage, Text } from 'components'

import type { TOrderItem } from './types'
import styles from './OrderItem.module.scss'

const OrderItem: FC<TOrderItem> = ({ image, index, quantity, price, title }) => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper_left}>
      {image ? (
        <img src={image} alt='product-image' width={72} height={72} className={styles.wrapper_image} />
      ) : (
        <NotFoundImage className={styles.wrapper_image} />
      )}

      <span className={styles.wrapper_index}>{index}</span>
    </div>

    <div className={styles.wrapper_right}>
      <div className={styles.wrapper_right_container}>
        <Text text={title ?? ''} className={classNames(styles.wrapper_title, styles.wrapper_title_name)} />

        <Text text={String(price) ?? ''} className={styles.wrapper_title} />
      </div>

      <span className={styles.wrapper_quantity}>{quantity} шт</span>
    </div>
  </div>
)

export default OrderItem
