import { useState, type FC } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { useAppSelector, useResponsive } from 'hooks'
import { ChevronDownIcon } from 'assets'
import { OrderItem, OrderItemSkeleton, Text } from 'components'
import { CartSelectors } from 'store/cart/selectors'

import styles from './OrderTotal.module.scss'

const OrderTotal: FC = () => {
  const { isTabletSmall } = useResponsive()
  const { data: cart, loading } = useAppSelector(CartSelectors.cartList)

  const [isExpanded, setIsExpanded] = useState<boolean>(true)

  const stockMessage = cart?.message_code ? cart?.message : ''

  const toggleExpand = () => {
    setIsExpanded(prev => !prev)
  }

  const renderOrderItem = loading ? (
    <OrderItemSkeleton skeletonCount={3} />
  ) : (
    cart?.data?.map((element, index) => (
      <OrderItem
        key={index}
        index={index + 1}
        quantity={element?.quantity}
        title={element?.product?.name}
        image={element?.product?.thumb_large || element?.product?.thumb}
        price={element?.product?.special || element?.product?.price}
      />
    ))
  )

  return (
    <div className={styles.wrapper}>
      {isTabletSmall && (
        <div role='button' onClick={toggleExpand} className={styles.wrapper_head}>
          <Text
            text='total_price'
            className={classNames(styles.wrapper_head_title, { [styles.wrapper_head_title_active]: isExpanded })}
            RightIcon={ChevronDownIcon}
          />

          <Text text={cart?.total_format || ''} className={styles.wrapper_head_total} />
        </div>
      )}

      <AnimatePresence>
        {(isExpanded || !isTabletSmall) && (
          <motion.div
            className={styles.wrapper_menu}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className={styles.wrapper_container}>{renderOrderItem}</div>

            <div className={styles.wrapper_container}>
              {cart?.gift_package && (
                <div className={styles.wrapper_row_between}>
                  <Text text='gift_package' className={styles.wrapper_text} />
                  <Text text='50 грн.' className={styles.wrapper_price} />
                </div>
              )}

              <div className={styles.wrapper_row_between}>
                <Text text='total_items' variable={{ var: cart?.count }} className={styles.wrapper_text} />
                <Text text={cart?.total_format || ''} className={styles.wrapper_price} />
              </div>

              <div className={styles.wrapper_line} />

              {stockMessage && <p className={styles.wrapper_error}>{stockMessage}</p>}

              <div className={styles.wrapper_row_between}>
                <Text text='total_price' className={styles.wrapper_total} />
                <Text text={cart?.total_format || ''} className={styles.wrapper_total} />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default OrderTotal
