import {
  SHIPPING_METHOD_NOVAPOSHTA_INTERNATIONAL,
  SHIPPING_METHOD_NOVAPOSHTA_WAREHOUSE,
  SHIPPING_METHOD_NOVAPOSHTA_COURIER,
} from 'config/constant/helper'

export const selectDataFormatting = (data: any) => {
  const formattedData = data?.map((element: any) => {
    return {
      _id: element.value,
      name: element.label,
    }
  })

  return formattedData
}

export const SHIPPINGS = [
  SHIPPING_METHOD_NOVAPOSHTA_INTERNATIONAL,
  SHIPPING_METHOD_NOVAPOSHTA_WAREHOUSE,
  SHIPPING_METHOD_NOVAPOSHTA_COURIER,
]

export const shipping_fields = [
  {
    _id: SHIPPING_METHOD_NOVAPOSHTA_INTERNATIONAL,
    name: SHIPPING_METHOD_NOVAPOSHTA_INTERNATIONAL,
  },
  {
    _id: SHIPPING_METHOD_NOVAPOSHTA_WAREHOUSE,
    name: SHIPPING_METHOD_NOVAPOSHTA_WAREHOUSE,
  },
  {
    _id: SHIPPING_METHOD_NOVAPOSHTA_COURIER,
    name: SHIPPING_METHOD_NOVAPOSHTA_COURIER,
  },
]
export const getShippingFields = (shipping_code: string) => {
  switch (shipping_code) {
    case SHIPPING_METHOD_NOVAPOSHTA_COURIER:
      return [
        {
          selectApi: 'cities',
          name: 'city_id',
          label: 'city_id.label',
          placeholder: 'city_id.placeholder',
          rules: {
            required: 'city_id.required',
          },
        },
        {
          selectApi: 'address',
          name: 'shipping_street',
          label: 'address.street_name',
          placeholder: 'address.street_name',
          rules: {
            required: 'address.required',
          },
          inputColumn: [
            {
              name: 'shipping_house',
              label: 'house.title',
              placeholder: 'house.title',
              rules: {
                required: 'house.required',
              },
            },
            {
              isRequired: false,
              name: 'shipping_flat',
              placeholder: 'flat.title',
              label: 'flat.title',
            },
          ],
        },
      ]

    case SHIPPING_METHOD_NOVAPOSHTA_INTERNATIONAL:
      return [
        {
          name: 'country_id',
          selectApi: 'countries',
          placeholder: 'country_id.placeholder',
          label: 'country_id.placeholder',
        },
        {
          name: 'city',
          label: 'city_id.label',
          placeholder: 'city_id.placeholderEnter',
          rules: {
            required: 'city_id.required',
          },
        },
        {
          name: 'address',
          label: 'address.street_name',
          placeholder: 'address.street_name',
          rules: {
            required: 'address.required',
          },
        },
      ]

    case SHIPPING_METHOD_NOVAPOSHTA_WAREHOUSE:
    default:
      return [
        {
          name: 'city_id',
          label: 'city_id.label',
          placeholder: 'city_id.placeholder',
          rules: {
            required: 'city_id.required',
          },
          selectApi: 'cities',
        },
        {
          name: 'warehouse_id',
          label: 'warehouse_id.placeholder',
          placeholder: 'warehouse_id.placeholder',
          rules: {
            required: 'warehouse_id.required',
          },
          selectApi: 'warehouse',
        },
      ]
  }
}
