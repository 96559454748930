export const customFilters = [
  {
    id: 1,
    name: 'sort_by_new',
    method: 'date_added|desc',
  },

  {
    id: 2,
    name: 'sort_by_discount',
    method: 'special|desc',
  },

  {
    id: 3,
    name: 'sort_by_price_asc',
    method: 'price|asc',
  },

  {
    id: 4,
    name: 'sort_by_price_desc',
    method: 'price|desc',
  },
]
